export interface IProject {
  _id: string;
  shortID: string;
  name: string;
  description?: string;
  createdAt: Date;
  creatorID: string;
  drawings: IDrawing[];
  currentDrawingID?: string;
}

export interface IDrawing {
  _id: string;
  projectID: string;
  name: string;
  currentDraftID?: string;
  targetRatio: number;
  gridSize: number;
  zoom: number;
}

export enum LanguageEnum {
  English = 'English',
  German = 'German',
  French = 'French',
  Italian = 'Italian',
}

export interface ISignIn {
  email: string;
  password: string;
}

import { Spin } from 'antd';
import React from 'react';

import logo from '../../logo.png';

export const LandingPage: React.FC = () => {
  return (
    <section className='landing-page'>
      <img alt='' src={logo} width='100px' />
      <Spin style={{ marginTop: '30px' }} />
    </section>
  );
};

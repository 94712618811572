export interface ISlabPreset {
  layers: number[];
  name: string;
  stiffnessRatio: number;
  thickness: number;
}

export const SlabPresets: ISlabPreset[] = [
  { name: 'CLT_100_3', layers: [30, 40, 30], stiffnessRatio: 9.83, thickness: 100 },
  { name: 'CLT_100_5', layers: [20, 20, 20, 20, 20], stiffnessRatio: 3.41, thickness: 100 },
  { name: 'CLT_110_3', layers: [40, 30, 40], stiffnessRatio: 18.41, thickness: 110 },
  { name: 'CLT_110_5', layers: [20, 20, 30, 20, 20], stiffnessRatio: 2.69, thickness: 110 },
  { name: 'CLT_120_3', layers: [40, 40, 40], stiffnessRatio: 13.9, thickness: 120 },
  { name: 'CLT_120_4', layers: [30, 30, 30, 30], stiffnessRatio: 1.0, thickness: 120 },
  { name: 'CLT_120_5', layers: [20, 30, 20, 30, 20], stiffnessRatio: 2.28, thickness: 120 },
  { name: 'CLT_130_5', layers: [30, 20, 30, 20, 30], stiffnessRatio: 4.99, thickness: 130 },
  { name: 'CLT_140_5', layers: [20, 40, 20, 40, 20], stiffnessRatio: 1.7, thickness: 140 },
  { name: 'CLT_140_7', layers: [20, 20, 20, 20, 20, 20, 20], stiffnessRatio: 2.31, thickness: 140 },
  { name: 'CLT_150_5', layers: [30, 30, 30, 30, 30], stiffnessRatio: 3.41, thickness: 150 },
  { name: 'CLT_160_4', layers: [40, 40, 40, 40], stiffnessRatio: 1.0, thickness: 160 },
  { name: 'CLT_170_5', layers: [30, 40, 30, 40, 30], stiffnessRatio: 2.56, thickness: 170 },
  { name: 'CLT_170_7', layers: [20, 30, 20, 30, 20, 30, 20], stiffnessRatio: 1.56, thickness: 170 },
  { name: 'CLT_180_5', layers: [40, 30, 40, 30, 40], stiffnessRatio: 4.48, thickness: 180 },
  { name: 'CLT_180_6', layers: [30, 30, 30, 30, 30, 30], stiffnessRatio: 1.0, thickness: 180 },
  { name: 'CLT_180_7', layers: [30, 20, 30, 20, 30, 20, 30], stiffnessRatio: 3.37, thickness: 180 },
  { name: 'CLT_200_5', layers: [40, 40, 40, 40, 40], stiffnessRatio: 3.41, thickness: 200 },
  { name: 'CLT_200_7', layers: [20, 40, 20, 40, 20, 40, 20], stiffnessRatio: 1.18, thickness: 200 },
  { name: 'CLT_210_7', layers: [30, 30, 30, 30, 30, 30, 30], stiffnessRatio: 2.31, thickness: 210 },
  { name: 'CLT_220_7', layers: [40, 20, 40, 20, 40, 20, 40], stiffnessRatio: 4.37, thickness: 220 },
  { name: 'CLT_240_6', layers: [40, 40, 40, 40, 40, 40], stiffnessRatio: 1.0, thickness: 240 },
  { name: 'CLT_240_7', layers: [30, 40, 30, 40, 30, 40, 30], stiffnessRatio: 1.75, thickness: 240 },
  { name: 'CLT_240_8', layers: [30, 30, 30, 30, 30, 30, 30, 30], stiffnessRatio: 1.75, thickness: 240 },
  { name: 'CLT_250_7', layers: [40, 30, 40, 30, 40, 30, 40], stiffnessRatio: 3.02, thickness: 250 },
  { name: 'CLT_260_9', layers: [20, 40, 20, 40, 20, 40, 20, 40, 20], stiffnessRatio: 0.98, thickness: 260 },
  { name: 'CLT_270_9', layers: [30, 30, 30, 30, 30, 30, 30, 30, 30], stiffnessRatio: 1.89, thickness: 270 },
  { name: 'CLT_280_7', layers: [40, 40, 40, 40, 40, 40, 40], stiffnessRatio: 2.31, thickness: 280 },
  { name: 'CLT_280_8', layers: [30, 30, 40, 40, 40, 40, 30, 30], stiffnessRatio: 1.0, thickness: 280 },
  { name: 'CLT_280_9', layers: [40, 20, 40, 20, 40, 20, 40, 20, 40], stiffnessRatio: 3.59, thickness: 280 },
  { name: 'CLT_300_10', layers: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30], stiffnessRatio: 1.0, thickness: 300 },
  { name: 'CLT_310_9', layers: [30, 40, 30, 40, 30, 40, 30, 40, 30], stiffnessRatio: 1.44, thickness: 310 },
  { name: 'CLT_320_8', layers: [40, 40, 40, 40, 40, 40, 40, 40], stiffnessRatio: 1.0, thickness: 320 },
  { name: 'CLT_320_11', layers: [20, 40, 20, 40, 20, 40, 20, 40, 20, 40, 20], stiffnessRatio: 0.87, thickness: 320 },
  { name: 'CLT_330_11', layers: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30], stiffnessRatio: 1.68, thickness: 330 },
  { name: 'CLT_340_10', layers: [30, 30, 30, 40, 40, 40, 40, 30, 30, 30], stiffnessRatio: 1.0, thickness: 340 },
  { name: 'CLT_360_9', layers: [40, 40, 40, 40, 40, 40, 40, 40, 40], stiffnessRatio: 1.89, thickness: 360 },
  { name: 'CLT_380_11', layers: [30, 40, 30, 40, 30, 40, 30, 40, 30, 40, 30], stiffnessRatio: 1.28, thickness: 380 },
  { name: 'CLT_400_10', layers: [40, 40, 40, 40, 40, 40, 40, 40, 40, 40], stiffnessRatio: 1.0, thickness: 400 },
  { name: 'CLT_440_11', layers: [40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40], stiffnessRatio: 1.68, thickness: 440 },
];

import React, { useState, useEffect } from 'react';
import { Layer, Line, Group, Text, Rect } from 'react-konva';
import { IInteraction } from '.';
import { ICalculation, IDraft, IVector, RFEMNode, TSMode } from '../../Data/draft';
import { IDrawing } from '../../Data/project';
import { t } from '../../localization';
import { ConvertWorldCoordToCanvas } from './helper';
import { SpanConnectionType } from './SpanManager';

export interface ResultLayerProps {
  interaction: IInteraction;
  drawing: IDrawing;
  setInteraction: (interaction: IInteraction) => void;
  draft: IDraft;
  height: number;
  zoom: number;
  resultDataForAllCalculations: IResultDataForAllCalculations;
}

export interface IResultDataForAllCalculations {
  [materialNo: number]: IResultDataPerCalculation;
}

export interface IResultDataPerCalculation {
  faceData: { [faceNo: number]: IFaceInteractionData };
  worstSupportSupportRatio: number;
  worstSupportEdgeRatio: number;
}

export interface IFaceInteractionData {
  location: IVector;
  span: number;
  displacement: number;
  ratio: number;
  columnID?: string;
  type?: SpanConnectionType;
}

export const TSResultLayer: React.FC<ResultLayerProps> = ({
  drawing,
  draft,
  zoom,
  height,
  interaction,
  setInteraction,
  resultDataForAllCalculations,
}) => {
  let calculationsData = draft.result!.calculations.sort((a: ICalculation, b: ICalculation) => a.Case.MaterialNo - b.Case.MaterialNo);

  const [calculation, setCalculation] = useState<ICalculation>(calculationsData[0]);
  const [faceData, setFaceData] = useState<IFaceInteractionData | undefined>(undefined);

  useEffect(() => {
    if (interaction.faceNo === undefined) return;
    if (!calculation) return;
    const calculationData = resultDataForAllCalculations[calculation.Case.MaterialNo];
    if (!calculationData) return;
    const data = calculationData.faceData[interaction.faceNo];
    if (!data) return;
    setFaceData(data);
    if (!data) return;
    if (data.columnID) {
      setInteraction({ ...interaction, selectedIDs: { ...interaction.selectedIDs, nodes: { [data.columnID]: true }, lines: {} } });
    } else {
      setInteraction({ ...interaction, selectedIDs: { ...interaction.selectedIDs, nodes: {}, lines: {} } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interaction.faceNo]);

  useEffect(() => {
    setCalculation(calculationsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  if (!calculation) return null;

  const maxDisp: number = Math.max(...calculationsData.map((r) => r.MaxDisplacement));

  const targetRatio = drawing.targetRatio || 300;

  const RFEMNodes: { [key: number]: RFEMNode } = {};
  calculation.Nodes.forEach((n) => (RFEMNodes[n.LocationNo] = n));

  function onFaceClick(faceNo: number) {
    if (interaction.mode !== TSMode.Select) return;
    setInteraction({ ...interaction, faceNo });
  }

  function getFaceText(data: IFaceInteractionData | undefined) {
    if (!data) return t('NO_DATA');
    const localDisplacement = data.displacement.toFixed(2) + ' mm';
    const span = data.span === 0 ? t('NO_DATA') : data.span.toFixed(2) + ' mm';
    const ratio = data.ratio === Infinity ? '' : `\n${t('RATIO')}: ${data.ratio.toFixed(2)}`;
    const type = data.type ? `\n${t('TYPE')}: ${t(data.type)}` : '';
    return `${t('LOCAL_DISPLACEMENT')}: ${localDisplacement}\n${t('SPAN')}: ${span}${ratio}${type}`;
  }

  function isFaceDataAcceptable(data: IFaceInteractionData | undefined) {
    if (!data) return false;
    if (!data.type) return true;
    if (data.type === SpanConnectionType.ColumnColumn || data.type === SpanConnectionType.ColumnWall) return data.ratio >= targetRatio;
    return data.ratio >= targetRatio * 2;
  }

  return (
    <Layer opacity={0.8} name='calculationlayer'>
      <Group>
        <Line
          points={[10, 10, 40, 10, 40, height - 10, 10, height - 10]}
          closed
          fillLinearGradientStartPoint={{ x: 10, y: 10 }}
          fillLinearGradientEndPoint={{ x: 10, y: height - 10 }}
          fillLinearGradientColorStops={[
            0,
            `hsl(230, 100%,50%)`,
            0.25,
            `hsl(172, 100%,50%)`,
            0.5,
            `hsl(115, 100%,50%)`,
            0.75,
            `hsl(57, 100%,50%)`,
            1,
            `hsl(0, 100%,50%)`,
          ]}
          opacity={0.8}
        />
        <Text text={'0.00 mm'} rotation={-90} x={15} y={70} fill='white' padding={5} fontSize={12} fontStyle='bold' />
        <Text
          text={t('MAX_DISP') + ': ' + (maxDisp * 1000).toFixed(2) + ' mm'}
          rotation={-90}
          x={15}
          y={height - 10}
          fill='white'
          padding={5}
          fontSize={12}
          fontStyle='bold'
        />
      </Group>

      {calculation.Faces.map((face, i) => {
        let points: RFEMNode[] = face.NodeNumbers.map((index) => RFEMNodes[index]).filter((p) => p);

        let startPt = points[0];
        let endPt = points[points.length - 1];

        let c0 = `hsl(${(1 - startPt.TotalDisplacement / maxDisp) * 230},100%,50%)`;
        let c3 = `hsl(${(1 - endPt.TotalDisplacement / maxDisp) * 230},100%,50%)`;

        const startInPixel = ConvertWorldCoordToCanvas({ x: startPt.Coordinates.X * 1000, y: startPt.Coordinates.Y * 1000 }, height, zoom);
        const endInPixel = ConvertWorldCoordToCanvas({ x: endPt.Coordinates.X * 1000, y: endPt.Coordinates.Y * 1000 }, height, zoom);
        const pointsInPixel = points
          .map((p) => {
            const pInPixel = ConvertWorldCoordToCanvas({ x: p.Coordinates.X * 1000, y: p.Coordinates.Y * 1000 }, height, zoom);
            return [pInPixel.x, pInPixel.y];
          })
          .flat();

        const isSelected = face.No === interaction.faceNo;

        return (
          <Group key={i} onClick={() => onFaceClick(face.No)}>
            {isSelected ? (
              <Line points={pointsInPixel} closed fill={'red'} opacity={0.8} />
            ) : (
              <Line
                points={pointsInPixel}
                closed
                fillLinearGradientStartPoint={startInPixel}
                fillLinearGradientEndPoint={endInPixel}
                fillLinearGradientColorStops={[0, c0, 1, c3]}
                opacity={0.8}
              />
            )}
          </Group>
        );
      })}

      <Group>
        {calculationsData.map((data, i) => (
          <ResultOption
            key={i}
            index={i}
            calculation={data}
            selected={calculation.Case.MaterialNo === calculationsData[i].Case.MaterialNo}
            calculations={calculationsData}
            setCalculation={setCalculation}
            resultData={resultDataForAllCalculations[calculationsData[i].Case.MaterialNo]}
          />
        ))}
      </Group>

      {faceData && (
        <Group
          visible={interaction.faceNo !== undefined}
          x={ConvertWorldCoordToCanvas(faceData.location, height, zoom).x + 30}
          y={ConvertWorldCoordToCanvas(faceData.location, height, zoom).y + 30}
        >
          <Rect width={220} height={80} opacity={0.7} fill={'white'} />
          <Text
            lineHeight={1.2}
            text={getFaceText(faceData)}
            fill={isFaceDataAcceptable(faceData) ? 'black' : 'red'}
            fontSize={14}
            align='left'
            verticalAlign='top'
            padding={7}
          ></Text>
        </Group>
      )}
    </Layer>
  );
};

interface ResultOptionProps {
  calculations: ICalculation[];
  calculation: ICalculation;
  setCalculation: React.Dispatch<React.SetStateAction<ICalculation>>;
  index: number;
  selected: boolean;
  resultData: IResultDataPerCalculation;
}

const ResultOption: React.FC<ResultOptionProps> = ({ index, calculation, setCalculation, selected, resultData }) => {
  function getText() {
    return `${t('MATERIAL')}: ${calculation.Case.MaterialDescription}, ${t('DISPLACEMENT')}: ${(calculation.MaxDisplacement * 1000).toFixed(
      2
    )} mm, ${t('WORST_RATIO')}: 1/${resultData.worstSupportSupportRatio.toFixed(2)} (${t('SLAB_EDGE')}: 1/${resultData.worstSupportEdgeRatio.toFixed(
      2
    )})`;
  }
  return (
    <Group onClick={selectResult} x={60} y={10 + index * 34}>
      <Rect width={550} height={26} fill={selected ? 'yellow' : '#ddd'} stroke='#000' cornerRadius={3} />
      <Text padding={9} text={getText()} />
    </Group>
  );

  function selectResult() {
    setCalculation(calculation);
  }
};

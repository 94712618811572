import React, { useEffect } from 'react';
import { Layer } from 'react-konva';
import { TSColumn } from './TSColumn';
import { IDraft, IDraftResult, RFEMNode } from '../../Data/draft';
import { IInteraction } from '.';
import TSPolyline from './TSPolyline';
import { IFaceInteractionData, IResultDataForAllCalculations, IResultDataPerCalculation, TSResultLayer } from './TSResultLayer';
import { ISpan, SpanConnectionType } from './SpanManager';
import { FindMaxDisplacementRegion } from './helper';
import { IDrawing } from '../../Data/project';

export interface CompLayerProps {
  drawing: IDrawing;
  draft: IDraft;
  interaction: IInteraction;
  setInteraction: (interaction: IInteraction) => void;
  height: number;
  zoom: number;
  showResult: boolean;
  span: ISpan | null;
}

const TSCompLayer: React.FC<CompLayerProps> = ({ drawing, draft, interaction, setInteraction, height, zoom, showResult, span }) => {
  const [resultData, setResultData] = React.useState<IResultDataForAllCalculations>({});

  useEffect(() => {
    if (!draft.result || !span) return;
    parseMaxRatio(draft.result, span);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft.result, span]);

  function parseMaxRatio(draftResult: IDraftResult, sp: ISpan) {
    if (!draftResult.isSuccess) return;

    const allData: IResultDataForAllCalculations = {};

    // loop through all calculations and all faces
    for (let calculation of draftResult.calculations) {
      const faceData: { [key: number]: IFaceInteractionData } = {};
      let worstSupportSupportRatio = Infinity;
      let worstSupportEdgeRatio = Infinity;

      const RFEMNodes: { [key: number]: RFEMNode } = {};
      calculation.Nodes.forEach((n) => (RFEMNodes[n.LocationNo] = n));

      for (let face of calculation.Faces) {
        // find the node
        const node = RFEMNodes[face.NodeNumbers[0]];
        // find the max displacement region
        const data = FindMaxDisplacementRegion(sp, node);
        // store this data
        faceData[face.No] = data;
        // update max ratio
        if (data.type === SpanConnectionType.ColumnColumn || data.type === SpanConnectionType.ColumnWall)
          worstSupportSupportRatio = Math.min(worstSupportSupportRatio, data.ratio);
        if (data.type === SpanConnectionType.ColumnEdge) worstSupportEdgeRatio = Math.min(worstSupportEdgeRatio, data.ratio);
      }
      const resultDataPerCalculation: IResultDataPerCalculation = {
        faceData,
        worstSupportSupportRatio,
        worstSupportEdgeRatio,
      };
      allData[calculation.Case.MaterialNo] = resultDataPerCalculation;
    }

    setResultData(allData);
  }

  const columns = draft.nodes ? Object.values(draft.nodes).filter((n) => n.isColumn) : [];
  return (
    <>
      <Layer name='complayer-polylines'>
        {draft.polylines?.map((polyline) => (
          <TSPolyline
            key={polyline._id}
            polyline={polyline}
            setInteraction={setInteraction}
            interaction={interaction}
            draft={draft}
            height={height}
            zoom={zoom}
          />
        ))}
      </Layer>
      {draft.result && draft.result.isSuccess && showResult && (
        <TSResultLayer
          drawing={drawing}
          interaction={interaction}
          setInteraction={setInteraction}
          draft={draft}
          height={height}
          zoom={zoom}
          resultDataForAllCalculations={resultData}
        />
      )}
      <Layer name='complayer-columns'>
        {columns.map((column) => (
          <TSColumn key={column._id} column={column} setInteraction={setInteraction} interaction={interaction} height={height} zoom={zoom} />
        ))}
      </Layer>
    </>
  );
};

export default TSCompLayer;

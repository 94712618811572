/*
  The version file defines all versions
*/

export interface IVersion {
  number: string;
  date: string;
  descriptions: string[];
}

export const versions: IVersion[] = [
  {
    number: '0.3.3',
    date: '2021-12-09',
    descriptions: [
      '- Added user authentication to the tool.',
      '- Changed some strings in UI.',
      '- Allow to try local project before login and log in to save it.',
      '- Updated project/drawing metadata edit UI.',
      '- Added project list.',
      '- Allow to set target ratio.',
      '- Added user guide.',
    ],
  },
  {
    number: '0.3.2',
    date: '2021-11-12',
    descriptions: [
      '- Evaluated displacement in relation to span.',
      '- Allowed to see displacement value when hovering over the mesh.',
      '- Allowed to manually select slab types to calculate.',
      '- Small span calculation fixes and updates.',
    ],
  },
  {
    number: '0.3.1',
    date: '2021-10-15',
    descriptions: [
      '- Added RFEM server status indicator.',
      '- Changed span calculation snap to edge priority problem.',
      '- Allowed to edit suggested slab.',
      '- Small UX update.',
      '- Fixed Y axis in zoon not working bug.',
    ],
  },
  {
    number: '0.3.0',
    date: '2021-10-04',
    descriptions: [
      '- Optimized main UI to allow bigger canvas size.',
      '- Added support to calculate span of column to edge or column to wall.',
      '- Linked RFEM calculation.',
      '- Automatically get best slab sizes for calculation.',
      '- Allow defined number of calculations.',
      '- Display calculation result and error.',
      '- Allow to hide the calcualtion result.',
      '- Added multiple language support.',
      '- Allow to zoom canvas.',
      '- Fixed resizing canvas grid leading to model change bug.',
      '- Updated drawing meta data edit UI.',
    ],
  },
  {
    number: '0.2.0',
    date: '2021-08-10',
    descriptions: [
      '- Reworked the data structure and project structure.',
      '- Added support to calculate span of columns.',
      '- Added span lookup table.',
    ],
  },
  {
    number: '0.1.2',
    date: '2020-08-12',
    descriptions: ['- Added Maximum span calculation.'],
  },
  {
    number: '0.1.1',
    date: '2020-05-26',
    descriptions: ['- Added Redo/Undo.', '- Added ColumnRow option for line.', '- Updated UI/UX', '- Optimized some functions.'],
  },
  {
    number: '0.1.0',
    date: '2020-03-25',
    descriptions: ['- Initial version.'],
  },
];

import { useState } from 'react';
import { Tag } from 'antd';
import { t } from '../../localization';
import { ISlabPreset, SlabPresets } from '../../assets/SlabPresets';

export const SpanTable: React.FC<{
  slabPairs: [ISlabPreset, number][];
  setSlabPairs: (p: [ISlabPreset, number][]) => void;
  bestThickness: number;
  thicknessWeight: number;
  stiffnessRatio: number;
  stiffnessRatioWeight: number;
}> = ({ slabPairs, setSlabPairs, bestThickness, stiffnessRatio, stiffnessRatioWeight, thicknessWeight }) => {
  const [activeThickness, setActiveThickness] = useState(0);

  return (
    <section>
      <Table {...{ activeThickness, setActiveThickness }} />
      <SlabDescription
        {...{ slabPairs, setSlabPairs, bestThickness, stiffnessRatio, stiffnessRatioWeight, thicknessWeight, thickness: activeThickness }}
      />
    </section>
  );
};

interface TableProps {
  activeThickness: number;
  setActiveThickness: (thickness: number) => void;
}

const Table: React.FC<TableProps> = ({ activeThickness, setActiveThickness }) => {
  return (
    <table className='span-table'>
      <thead>
        <tr>
          <td>{t('LIVE_LOAD')}</td>
          <td style={{ textAlign: 'center' }} colSpan={15}>
            {t('SPANS')} [m]
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            q [kN/m<sup>2]</sup>
          </td>
          {['4x4', '5x4', '5x5', '6x4', '6x5', '6x6', '7x5', '7x6', '7x7', '8x6', '8x7', '8x8', '9x7', '9x8', '9x9'].map((val, index) => (
            <td style={{ fontWeight: 'bold' }} key={index}>
              {val}
            </td>
          ))}
        </tr>
        <tr>
          <td>2</td>
          {[180, 200, 220, 240, 240, 270, 270, 270, 300, 310, 340, 350, 380, 400, 420].map((val, index) => (
            <td
              className={val === activeThickness ? 'selected' : ''}
              onClick={(e: any) => setActiveThickness(parseInt(e.target.innerText))}
              key={index}
            >
              {val}
            </td>
          ))}
        </tr>
        <tr>
          <td>3</td>
          {[180, 210, 230, 250, 250, 270, 280, 280, 300, 320, 350, 360, 390, 410, 430].map((val, index) => (
            <td
              className={val === activeThickness ? 'selected' : ''}
              onClick={(e: any) => setActiveThickness(parseInt(e.target.innerText))}
              key={index}
            >
              {val}
            </td>
          ))}
        </tr>
        <tr>
          <td>4</td>
          {[200, 220, 260, 270, 270, 280, 300, 300, 310, 350, 360, 370, 400, 420, 440].map((val, index) => (
            <td
              className={val === activeThickness ? 'selected' : ''}
              onClick={(e: any) => setActiveThickness(parseInt(e.target.innerText))}
              key={index}
            >
              {val}
            </td>
          ))}
        </tr>
        <tr>
          <td>5</td>
          {[220, 230, 270, 270, 270, 280, 320, 320, 330, 360, 370, 380, 410, 420, 440].map((val, index) => (
            <td
              className={val === activeThickness ? 'selected' : ''}
              onClick={(e: any) => setActiveThickness(parseInt(e.target.innerText))}
              key={index}
            >
              {val}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

interface SlabDescriptionProps {
  thickness: number;
  slabPairs: [ISlabPreset, number][];
  setSlabPairs: (p: [ISlabPreset, number][]) => void;
  bestThickness: number;
  thicknessWeight: number;
  stiffnessRatio: number;
  stiffnessRatioWeight: number;
}

export function SlabDescription({
  thickness,
  slabPairs,
  setSlabPairs,
  bestThickness,
  stiffnessRatio,
  stiffnessRatioWeight,
  thicknessWeight,
}: SlabDescriptionProps) {
  const slabs = SlabPresets.filter((s) => s.thickness === thickness);

  if (!slabs || slabs.length === 0) return null;

  return (
    <section className='slab-details'>
      <p>{t('AVAILABLE_CROSS_SECTIONS')}:</p>
      {slabs.map((slab) => (
        <div key={slab.name}>
          <Tag
            className='slab-tag'
            onClick={() => {
              if (slabPairs.find((p) => p[0].name === slab.name)) return;
              setSlabPairs([
                ...slabPairs,
                [
                  slab,
                  Math.abs(slab.thickness - bestThickness) * thicknessWeight * 0.01 +
                    Math.abs(slab.stiffnessRatio - stiffnessRatio) * stiffnessRatioWeight,
                ],
              ]);
            }}
            color={slabPairs.find((p) => p[0].name === slab.name) ? 'green' : 'grey'}
          >
            {slab.name}, {t('LAYERS')}: [{slab.layers.join(', ')}], {t('BEST_STIFFNESS_RATIO')}: {slab.stiffnessRatio}
          </Tag>
        </div>
      ))}
    </section>
  );
}

import { ISpanResult } from '../TSCanvas/SpanManager';
import { Alert } from 'antd';
import { t } from '../../localization';

interface SpanResultProps {
  maxSpan?: ISpanResult;
}

export const SpanResult: React.FC<SpanResultProps> = ({ maxSpan }) => {
  if (!maxSpan) return <Alert type='warning' message={t('MAX_SPAN_NOT_YET_CALCULATED')} />;

  let lx = maxSpan.lx! / 1000;
  let ly = maxSpan.ly! / 1000;
  let [bigger, smaller] = lx > ly ? [lx, ly] : [ly, lx];

  const rotated = ly > lx;

  return (
    <Alert
      type='success'
      message={<strong>{t('MAX_SPAN')}</strong>}
      description={
        <div>
          <p>
            {t('MAXIMUM_SPANS')}: <strong>{`${bigger.toFixed(2)}m x ${smaller.toFixed(2)}m`}</strong>
          </p>
          <p>
            {t('TOP_LAYER')}: <strong>{rotated ? t('IN_Y_DIR') : t('IN_X_DIR')}</strong>
          </p>
          <p>
            {t('AREA')}: <strong>{(lx * ly).toFixed(2)} m²</strong>
          </p>
          <p>
            {t('STIFFNESS_RATIO')}: <strong>{maxSpan.ratio!.toFixed(2)}</strong>
          </p>
        </div>
      }
    ></Alert>
  );
};

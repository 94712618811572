import { Form, Checkbox, InputNumber, Alert } from 'antd';
import { t } from '../../localization';
import { RFEMLoadCaseConfig } from '../../Data/RFEM';

interface LoadFormProps {
  surfaceLoads: RFEMLoadCaseConfig[];
  setSurfaceLoads: (surfaceLoads: RFEMLoadCaseConfig[]) => void;
}

export const LoadForm: React.FC<LoadFormProps> = ({ surfaceLoads, setSurfaceLoads }) => {
  function updateConfig(c: RFEMLoadCaseConfig) {
    const newConfigs = surfaceLoads.map((config) => {
      if (config.No === c.No) {
        return c;
      }
      return config;
    });
    setSurfaceLoads(newConfigs);
  }

  return (
    <Alert
      message={<strong>{t('SET_LOADS')}</strong>}
      type='success'
      description={
        <Form className='load-form'>
          {surfaceLoads.map((loadCase, index) => (
            <Form.Item key={index} label={loadCase.Description}>
              <Checkbox checked={loadCase.Activate} onChange={(e) => updateConfig({ ...loadCase, Activate: e.target.checked })} />
              {loadCase.Loads.map((load, index) => (
                <span key={index}>
                  <InputNumber
                    disabled={!loadCase.Activate}
                    onChange={(e) =>
                      updateConfig({
                        ...loadCase,
                        Loads: loadCase.Loads.map((l) => {
                          if (l.No === load.No) {
                            return { ...l, Magnitude: e * -1 };
                          }
                          return l;
                        }),
                      })
                    }
                    style={{ marginLeft: '10px' }}
                    size='small'
                    key={index}
                    value={load.Magnitude * -1}
                  />{' '}
                  kN/m
                  <sup>2</sup>
                </span>
              ))}
            </Form.Item>
          ))}
        </Form>
      }
    ></Alert>
  );
};

import React, { useState } from 'react';
import { Form, Button, message, Input, Alert } from 'antd';
import { ITask } from '../../store/task';
import { ISignIn } from '../../Data/project';
import { t } from '../../localization';
import { validateEmail } from '../TSCanvas/helper';

const signInUser: ISignIn = {
  email: '',
  password: '',
};

export const SignIn: React.FC<{ task: ITask }> = ({ task }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(signInUser);
  const [error, setError] = useState('');

  return (
    <Form className='login-form' name='SignInForm' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={signIn}>
      <Form.Item label={t('EMAIL')}>
        <Input value={user.email} placeholder={t('PLEASE_INPUT_YOUR_EMAIL')} onChange={(e) => setUser({ ...user, email: e.target.value })} />
      </Form.Item>

      <Form.Item label={t('PASSWORD')}>
        <Input.Password
          value={user.password}
          placeholder={t('PLEASE_INPUT_YOUR_PASSWORD')}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={error} type='error' />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          {t('LOGIN')}
        </Button>
      </Form.Item>
    </Form>
  );

  function invalidate() {
    let error = validateEmail(user.email);
    if (error) {
      setError(error);
      return false;
    }

    return true;
  }

  async function signIn() {
    if (!invalidate()) return;

    setLoading(true);
    const response = await task.Authenticate(user);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
    }
  }
};

import React, { useEffect, useState } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import NotFound from './NotFound';
import { path } from './path';
import { Project } from './Components/Project';
import { ContextProvider, useContext } from './store';
import { LocalProject } from './Components/Project/LocalProject';
import { LandingPage } from './Components/LandingPage';
import { LoginPage } from './Components/LoginPage';
import { Projects } from './Components/Projects';

export default function App() {
  return (
    <ContextProvider>
      <AuthGuard />
    </ContextProvider>
  );
}

const AuthGuard: React.FC = () => {
  const { task } = useContext();
  const [checkingUser, setCheckingUser] = useState(true);

  useEffect(() => {
    validateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function validateUser() {
    setCheckingUser(true);
    await task.ValidateToken();
    setCheckingUser(false);
  }

  if (checkingUser) return <LandingPage />;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={path.localProject} component={LocalProject} />
        <Route exact path={path.login} component={LoginPage} />
        <Route path={path.dashboard} component={Home} />
      </Switch>
    </BrowserRouter>
  );
};

const Home: React.FC = () => {
  const { state } = useContext();
  if (!state.user) return <Redirect to={path.login} />;

  return (
    <Switch>
      <Route exact path={path.dashboard} component={Projects} />
      <Route exact path={path.project} component={Project} />
      <Route component={NotFound} />
    </Switch>
  );
};

export interface IDraft {
  _id: string;
  drawingID: string;
  createdAt: Date;
  nodes: { [key: string]: INode };
  polylines: IPolyline[];
  result?: IDraftResult;
  basedOn?: string;
}

export interface IDraftResult {
  isSuccess: boolean;
  errorInfos: ErrorInfo[];
  calculations: ICalculation[];
}

export interface ICalculation {
  Case: TestCase;
  Faces: RFEMFace[];
  Nodes: RFEMNode[];
  MaxDisplacement: number;
}

export interface ErrorInfo {
  ModuleName: string;
  Load: {
    Type: number;
    No: number;
  };
  ErrorNo: number;
  Type: number;
  Description: string;
}

export interface TestCase {
  MaterialDescription: string;
  MaterialNo: number;
}

export interface RFEMFace {
  No: number;
  NodeNumbers: [number, number, number, number];
  SurfaceNo: number;
}

export interface RFEMNode {
  Coordinates: { X: number; Y: number; Z: number };
  Displacements: { X: number; Y: number; Z: number };
  LocationNo: number;
  Rotations: { X: number; Y: number; Z: number };
  SurfaceNo: number;
  TotalDisplacement: number;
  Type: number;
}

export interface INode {
  _id: string;
  x: number;
  y: number;
  isColumn?: boolean;
  lineID?: string;
}

export enum LineType {
  Edge = 'Edge',
  Wall = 'Wall',
  Columns = 'Columns',
}

export type VariableLineType = LineType | 'Various';

export interface ILine {
  _id: string;
  startID: string;
  endID: string;
  type: LineType;
  columnIDs?: string[]; // only works in lineType is Columns
}

export interface IPolyline {
  _id: string;
  lines: ILine[];
  isClosed?: boolean;
  isCutout?: boolean;
}

export interface IVector {
  x: number;
  y: number;
}

export const ZeroVector: IVector = { x: 0, y: 0 };

export interface IRecRange {
  p1: IVector;
  p2: IVector;
}

export enum TSMode {
  Select,
  MoveStart,
  MoveEnd,
  AddColumn,
  AddLineStart,
  AddLineEnd,
  AddPolyline,
  AddRectStart,
  AddRectEnd,
}

export enum SelectMode {
  Normal,
  Add,
  Remove,
}

import React, { useState } from 'react';
import { Button, Modal, Form, Input, message, Tooltip } from 'antd';
import { ITask } from '../../store/task';
import { EditOutlined } from '@ant-design/icons';
import { IProject } from '../../Data/project';
import { t } from '../../localization';

export const EditProject: React.FC<{
  project: IProject;
  task: ITask;
  projects: IProject[];
  setProjects: (ps: IProject[]) => void;
}> = ({ project, task, projects, setProjects }) => {
  const [editProject, setEditProject] = useState<IProject>(project);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Tooltip title={t('EDIT')}>
        <EditOutlined onClick={() => setVisible(true)} key='edit' />
      </Tooltip>
      <Modal
        title={t('EDIT') + ' ' + project.name}
        visible={visible}
        style={{ minWidth: '500px' }}
        footer={
          <Button type='primary' onClick={() => updateProject()}>
            {t('UPDATE')}
          </Button>
        }
        onCancel={() => setVisible(false)}
      >
        <Form className='login-form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item required label={t('NAME')}>
            <Input value={editProject.name} onChange={(e) => setEditProject({ ...editProject, name: e.target.value })} />
          </Form.Item>

          <Form.Item label={t('DESCRIPTION')}>
            <Input.TextArea
              rows={5}
              value={editProject.description}
              onChange={(e) => setEditProject({ ...editProject, description: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  async function updateProject() {
    const response = await task.UpdateProject(editProject.shortID, editProject);
    if (response.success) {
      const newProject = response.data as IProject;
      setProjects(projects.map((p) => (p._id === newProject._id ? newProject : p)));
      message.success(t('CHANGE_SUCCESS'));
    }
    setVisible(false);
  }
};

import { Alert } from 'antd';
import React from 'react';
import { IDraftResult } from '../../Data/draft';

export interface TSErrorResultProps {
  result: IDraftResult;
}

export const TSErrorResult: React.FC<TSErrorResultProps> = ({ result }) => {
  return (
    <div className='error-container'>
      {result.errorInfos.map((errorInfo, index) => (
        <Alert key={index} style={{ marginBottom: '5px' }} showIcon type='error' message={errorInfo.Description}></Alert>
      ))}
    </div>
  );
};

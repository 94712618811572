import React from 'react';
import { message, Modal, Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ITask } from '../../store/task';
import { IProject } from '../../Data/project';
import { t } from '../../localization';

const { confirm } = Modal;

export const DeleteProject: React.FC<{
  project: IProject;
  task: ITask;
  projects: IProject[];
  setProjects: (ps: IProject[]) => void;
}> = ({ project, task, projects, setProjects }) => {
  async function deleteProject(shortID: string) {
    const response = await task.DeleteProject(shortID);
    if (response.success) {
      setProjects(projects.filter((p) => p.shortID !== shortID));
      message.success(t('PROJECT_DELETED'));
    }
  }

  function showDeleteConfirm() {
    confirm({
      title: t('DELETE') + ': ' + project.name + '?',
      icon: <ExclamationCircleOutlined />,
      content: t('ARE_YOU_SURE_TO_DELETE_THIS_PROJECT'),
      okText: t('DELETE'),
      okType: 'danger',
      cancelText: t('CANCEL'),
      onOk: async () => {
        await deleteProject(project.shortID);
      },
    });
  }

  return (
    <Tooltip title={t('DELETE')}>
      <DeleteOutlined style={{ color: '#ff4d4f' }} onClick={() => showDeleteConfirm()} key='delete' />
    </Tooltip>
  );
};

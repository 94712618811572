import React from 'react';
import { Group, Line } from 'react-konva';
import { TSNode } from './TSNode';
import { TSColumnRow } from './TSColumnRow';
import { KonvaEventObject } from 'konva/lib/Node';
import { IDraft, ILine, IPolyline, LineType, TSMode } from '../../Data/draft';
import { IInteraction } from '.';
import { ConvertWorldCoordToCanvas, IsLineSelected } from './helper';

export interface LineProps {
  line: ILine;
  polyline: IPolyline;
  draft: IDraft;
  interaction: IInteraction;
  setInteraction: (i: IInteraction) => void;
  height: number;
  zoom: number;
}

export const TSLine: React.FC<LineProps> = ({ line, polyline, draft, interaction, setInteraction, height, zoom }) => {
  const hasSiblingSelected = polyline.lines.some((l) => IsLineSelected(interaction, l));
  const isStartSelected = interaction.selectedIDs.nodes[line.startID];
  const isEndSelected = interaction.selectedIDs.nodes[line.endID];
  const isSelected = IsLineSelected(interaction, line);

  const startPt = draft.nodes![line.startID];
  const endPt = draft.nodes![line.endID];

  const startPtInPixel = ConvertWorldCoordToCanvas(startPt, height, zoom);
  const endPtInPixel = ConvertWorldCoordToCanvas(endPt, height, zoom);

  let startX = startPtInPixel.x;
  let startY = startPtInPixel.y;
  let endX = endPtInPixel.x;
  let endY = endPtInPixel.y;

  if (interaction.translation) {
    const deltaX = interaction.translation.p2.x - interaction.translation.p1.x;
    const deltaY = interaction.translation.p2.y - interaction.translation.p1.y;

    if (isStartSelected) {
      startX += deltaX;
      startY += deltaY;
    }

    if (isEndSelected) {
      endX += deltaX;
      endY += deltaY;
    }
  }

  const points = [startX, startY, endX, endY];

  return (
    <Group>
      {line.type === LineType.Wall && <Line points={points} stroke={isSelected ? 'yellow' : '#111111'} strokeWidth={16} opacity={0.5} />}
      <Line onClick={onClick} points={points} stroke={isSelected ? 'yellow' : hasSiblingSelected ? '#ffa500a6' : 'black'} strokeWidth={4} />
      <TSNode key={startPt._id} node={startPt} line={line} setInteraction={setInteraction} interaction={interaction} height={height} zoom={zoom} />
      <TSNode key={endPt._id} node={endPt} line={line} setInteraction={setInteraction} interaction={interaction} height={height} zoom={zoom} />
      {line.type === LineType.Columns && (
        <TSColumnRow draft={draft} line={line} isSelected={isSelected} start={startPt} end={endPt} height={height} zoom={zoom} />
      )}
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (interaction.mode !== TSMode.Select) return;

    if (e.evt.ctrlKey) {
      setInteraction({
        ...interaction,
        selectedIDs: { ...interaction.selectedIDs, lines: { ...interaction.selectedIDs.lines, [line._id]: false } },
      });
    } else if (e.evt.shiftKey) {
      setInteraction({
        ...interaction,
        selectedIDs: { ...interaction.selectedIDs, lines: { ...interaction.selectedIDs.lines, [line._id]: true } },
      });
    } else {
      setInteraction({
        ...interaction,
        selectedIDs: { ...interaction.selectedIDs, lines: { [line._id]: true }, nodes: {} },
      });
    }
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TS3: 'Timber Structures 3.0',
  NEW_PROJECT: 'New Project',
  MY_PROJECTS: 'My Projects',
  PROJECT_ID: 'Project ID',
  YOUR_EMAIL: 'Your Email',
  EMAIL_PLACEHOLDER: 'Your email...',
  UNTITLED: 'Untitled',
  PROJECT_NAME: 'Project Name',
  PROJECT_DESCRIPTION: 'Project Description',
  CREATOR: 'Creator',
  DRAWING_NAME: 'Draft Name',
  DRAFT_DATE: 'Drawn At',
  COPY_DRAFT: 'Copy Draft',
  DELETE_DRAFT: 'Delete Draft',
  EDIT_DRAFT: 'Edit Draft',
  NAME: 'Name',
  CALCULATE: 'Calculate',
  CALCULATING: 'Calculating',
  PROJECT: 'Project',
  DRAFTS: 'Drafts',
  INFO: 'Info',
  DETAILS: 'Details',
  VERSION: 'Version',
  TARGET_RATIO: 'Target Ratio w/L',
  OK: 'OK',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  FIRSTNAME: 'First Name',
  LASTNAME: 'Last Name',
  LANGUAGE: 'Language',
  PLEASE_INPUT_YOUR_EMAIL: 'Please input your email',
  PLEASE_INPUT_YOUR_PASSWORD: 'Please input your password',
  PLEASE_INPUT_YOUR_CONFIRM_PASSWORD: 'Please input your confirm password',
  PLEASE_INPUT_YOUR_FIRSTNAME: 'Please input your firstname',
  PLEASE_INPUT_YOUR_LASTNAME: 'Please input your lastname',
  EDIT_USER: 'Edit User',
  NEW_PASSWORD: 'New Password',
  FORGET_PASSWORD: 'Forgot Password',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  PLEASE_INPUT_YOUR_FIRSTNAME_AND_LASTNAME: 'Please input your firstname and lastname',
  EMAIL_SHOULDNOT_BE_EMPTY: 'Email should not be empty',
  INVALID_EMAIL_ADDRESS: 'Invalid email address',
  PASSWORD_SHOULD_HAVE_LENGTH_8: 'Password should have at least length of 8 letters',
  PASSWORD_SHOULD_CONTAIN_ONE_CAPITALIZED: 'Password should contain at least one capitalized letter',
  PASSWORD_SHOULD_CONTAIN_ONE_DIGIT: 'Password should contain at least one digit',
  NAME_SHOULD_NOT_BE_EMPTY: 'Name should not be empty',
  EMAIL_CANNOT_BE_EMPTY: 'Email cannot be empty.',
  // ============ messages =========
  PROVIDE_EMAIL_TO_LINK_PROJECT: 'Please provide your email address that this project belongs to.',
  PROVIDE_EMAIL_TO_GET_PROJECTS: 'Get all projects that belongs to this email.',
  PROJECT_ID_CANNOT_BE_EMPTY: 'Project ID cannot be empty.',
  INFO_UPDATED: 'Information updated.',
  DELEVEOPED_BY_D2P: 'Developed by Design-to-Production',
  PROJECT_NOT_FOUND: 'Project not found.',
  DOUBLE_CHECK_TEXTID: 'Please check the project ID',
  DELETE_DRAFT_CONFIRM: 'Are you sure to delete this draft?',
  DRAFT_SAVED: 'Draft saved.',
  DRAFT_CALULATED: 'Draft calculated',
  DRAFT_CALCULATING_FAILED: 'Failed to calculate draft',
  RESULTS_CLEARED: 'Results cleared.',
  VERSION_NUMBER: 'Version',
  VERSION_DESCRIPTION: 'Version Info',
  CURRENT: 'Current',
  SHOW_SPAN_TABLE: 'Show Span Table',
  SPAN_LOOK_UP_TABLE: 'Span Lookup Table',
  MAX_SPAN_NOT_YET_CALCULATED: 'Max Span not yet calculated.',
  MAXIMUM_SPANS: 'Maximum Spans',
  MATCHES: 'Matches',
  AREA: 'Area',
  STIFFNESS_RATIO: 'Stiffness Ratio',
  BEST_STIFFNESS_RATIO: 'Best Stiffness Ratio',
  SLAB_EDGE: 'Slab Edge',
  LIVE_LOAD: 'Live Load',
  SPANS: 'Spans',
  AVAILABLE_CROSS_SECTIONS: 'Available Cross Sections',
  LAYERS: 'Layers',
  MORE_THAN_TWO_LINES_CONNECTED_TO_THE_NODE: 'More than two lines connected to the node.',
  CLICK_OR_DRAW_A_WINDOW_TO_SELECT: 'Click or draw a window to select. Use "Shift" to add to selection. Use "Ctrl" to remove from selection.',
  PLACE_A_COLUMN: 'Place a column...',
  PLACE_A_NODE_OF_THE_POLYLINE: 'Place a node of the polyline...',
  PICK_THE_START_POINT_TO_MOVE: 'Pick the start point to move...',
  PICK_THE_END_POINT: 'Pick the end point...',
  PICK_THE_START_POINT_OF_THE_LINE: 'Pick the start point of the line...',
  PICK_THE_END_POINT_OF_THE_LINE: 'Pick the end point of the line...',
  PICK_THE_FIRST_CORNER_OF_THE_RECTANGLE: 'Pick the first corner of the rectangle...',
  PICK_THE_SECOND_CORNER_OF_THE_RECTANGLE: 'Pick the second corner of the rectangle...',
  NODES_CANNOT_BE_ADDED_AT_THE_SAME_POSITION: 'Nodes cannot be added at the same position.',
  THE_START_AND_END_OF_THE_LINE_SHOULD_NOT_OVERLAP: 'The start and end of the line should not overlap.',
  A_POLYLINE_SHOULD_NOT_HAVE_DUPLICATED_NODE_EXCEPT_THE_END_NODE: 'A polyline should not have duplicated node except the end node.',
  CONFIRM_CALCULATION: 'Confirm calculation',
  CONFIRM_CALCULATION_CONTENT: 'The tool failed to calculate the spans of your drawing. Are you sure to calculate via RFEM?',
  // ============ controls =========
  OPEN: 'Open',
  YES: 'Yes',
  NO: 'No',
  SELECT_BUTTON: 'Select (Esc)',
  DELETE_BUTTON: 'Delete (Del)',
  MOVE_BUTTON: 'Move (M)',
  COLUMN_BUTTON: 'Column (C)',
  LINE_BUTTON: 'Line (L)',
  RECTANGLE_BUTTON: 'Rectangle (R)',
  POLYGON_BUTTON: 'Polygon (P)',
  UNDO_BUTTON: 'Undo (Ctrl+Z)',
  REDO_BUTTON: 'Redo (Ctrl+Y)',
  CALCULATE_BUTTON: 'Calculate (Ctrl+K)',
  CALCULATE_SPAN: 'Calculate Span',
  ZOOM_IN: 'Zoom In',
  ZOOM_OUT: 'Zoom Out',
  SETTINGS: 'Settings',
  GRID_SIZE: 'Grid Size',
  SPAN_ANGLE: 'Span Angle',
  CLEAR_RESULTS: 'Clear Results',
  EDGE: 'Edge',
  WALL: 'Wall',
  COLUMNS: 'Columns',
  VARIOUS: 'Various',
  COLUMN_COUNT: 'Column Count',
  FINISH: 'Finish',
  CANCEL: 'Cancel',
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  FORGOT_PASSWORD: 'Forgot Password',
  CHANGE_PASSWORD: 'Change Password',
  PREFERENCE: 'Preference',
  UPDATE: 'Update',
  CHANGE_SUCCESS: 'Change Success',
  DRAWINGS: 'Drawings',
  DESCRIPTION: 'Description',
  FAILED_TO_CREATE_PROJECT: 'Failed to create project',
  DELETE: 'Delete',
  ARE_YOU_SURE_TO_DELETE_THIS_PROJECT: 'Are you sure to delete this project?',
  PROJECT_DELETED: 'Project deleted',
  TRY_WITHOUT_LOGIN: 'Try without login',
  TS3_CONFIGURATOR: 'TS3 Configurator',
  PLEASE_LOGIN_TO_SAVE_YOUR_PROJECT: 'Please login to save your project.',
  NEW_DRAWING: 'New Drawing',
  NO_DESCRIPTION: 'No Description',
  IN_ORDER_TO_CALCULATE_YOUR_DRAWING_PLEASE_LOGIN_TO_SAVE_YOUR_DRAWING_FIRST:
    'In order to calculate your drawing, please login to save your drawing first.',
  EDIT: 'Edit',
  CREATE: 'Create',
  SUBMIT: 'Submit',
  PROFILE: 'Profile',
  THICKNESS: 'Thickness',
  DISPLACEMENT: 'Displacement',
  MAX_DISP: 'Max Displacement',
  SHOW_RESULT: 'Show Result',
  MATERIAL: 'Material',
  SET_LOADS: 'Set Loads',
  DRAFT_CALCULATED: 'Draft Calculated',
  DRAFT_CALCULATE_FAILED: 'Failed to calculate draft',
  CALCULATION_WITH_RFEM: 'Calculation with RFEM',
  TOP_LAYER: 'Top Layer',
  IN_X_DIR: 'In X Direction',
  IN_Y_DIR: 'In Y Direction',
  MAX_SPAN: 'Max Span',
  SUGGESTED_SLABS: 'Suggested Slabs',
  COUNT: 'Count',
  BEST_THICKNESS: 'Optimal Thickness',
  SCORE_FORMULAR: 'Formula to determine appropriate slab types',
  SLAB_THICKNESS: 'Slab Thickness',
  THICKNESS_WEIGHT: 'Thickness Weight',
  SLAB_STIFFNESSRATIO: 'Slab Stiffness Ratio',
  CURRENT_STIFFNESSRATIO: 'Current Stiffness Ratio',
  STIFFNESSRATIO_WEIGHT: 'Stiffness Ratio Weight',
  SCORE: 'Score',
  THE_SMALL_THE_BETTER: 'The smaller the better',
  PLEASE_SELECT_OR_CREATE_A_DRAWING: 'Please select or create a drawing.',
  CREATE_DRAWING: 'Create drawing',
  SNAP: 'Snap',
  RFEM_SERVER_ONLINE: 'RFEM Server Online',
  RFEM_SERVER_OFFLINE: 'RFEM Server Offline',
  CHECKING_RFEM_SERVER: 'Checking RFEM Server...',
  LAST_CHECKED: 'Last Checked',
  SUGGESTED_COUNT: 'Suggested Count',
  STIFFNESS_RATIO_ABBR: 'Stif. ratio',
  NO_DATA: 'No Data',
  NONE: 'None',
  SPAN: 'Span',
  NO_SPAN_FOUND: 'No Span Found',
  RATIO: 'Ratio',
  TYPE: 'Type',
  LOCAL_DISPLACEMENT: 'Local Displacement',
  ColumnColumn: 'Column-Column',
  ColumnEdge: 'Column-Edge',
  ColumnWall: 'Column-Wall',
  WORST_RATIO: 'max w/L',
  LC2: 'Permanent Load without welf-weight of CLT',
  LC3: 'Live Load',
  INSTRUCTION: 'Instruction',
};

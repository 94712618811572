import React, { useEffect, useState } from 'react';
import { t } from '../../localization';
import { StopOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { distanceString } from '../../localization/timing';
import { cloneDeep } from 'lodash';
import { IDrawing, IProject } from '../../Data/project';
import { ITask } from '../../store/task';
import { IDraft } from '../../Data/draft';
import { IUser } from '../../store/state';

const { Text } = Typography;

export interface DraftDetailProps {
  project: IProject;
  task: ITask;
  drawing: IDrawing;
  draft: IDraft;
  user?: IUser | null;
}

export function DraftDetail({ project, task, drawing, draft, user }: DraftDetailProps) {
  const [edit, setEdit] = useState(false);

  const [editProject, setEditProject] = useState(cloneDeep(project));

  useEffect(() => {
    if (edit) {
      setEditProject(cloneDeep(project));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <div className='detail-container'>
      <Text className='detail-header'>
        <UnorderedListOutlined />
        <span className='detail-header-text'>{t('DETAILS')}</span>
        <span style={{ cursor: 'pointer', marginLeft: '1em' }}>
          {edit ? (
            <>
              <CheckOutlined onClick={save} />
              <StopOutlined style={{ marginLeft: '5px' }} onClick={() => setEdit(false)} />
            </>
          ) : (
            <EditOutlined onClick={() => setEdit(true)} />
          )}
        </span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('PROJECT_ID')}:</span>
        <span className='detail-body-text'>{project.shortID}</span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('PROJECT_NAME')}:</span>
        <span className='detail-body-text'>
          {edit ? (
            <input
              className='value-input'
              value={editProject.name}
              onChange={(e) => setEditProject({ ...editProject, name: e.target.value })}
              onKeyUp={onInputKeyUp}
            />
          ) : (
            project.name
          )}
        </span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('PROJECT_DESCRIPTION')}:</span>
        <span className='detail-body-text'>
          {edit ? (
            <input
              className='value-input'
              value={editProject.description || ''}
              onChange={(e) => setEditProject({ ...editProject, description: e.target.value })}
              onKeyUp={onInputKeyUp}
            />
          ) : (
            project.description || ''
          )}
        </span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('CREATOR')}:</span>
        <span className='detail-body-text'>{user ? user.email : ''}</span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('DRAWING_NAME')}:</span>
        <span className='detail-body-text'>{drawing.name}</span>
      </Text>
      <Text className='detail-body'>
        <span className='detail-body-title'>{t('DRAFT_DATE')}:</span>
        <span className='detail-body-text'>{distanceString(draft.createdAt.toString())}</span>
      </Text>
    </div>
  );

  function onInputKeyUp(e: any) {
    if (e.key === 'Enter') {
      save();
    }
    if (e.key === 'Escape') {
      setEdit(false);
    }
  }

  async function save() {
    await task.UpdateProject(editProject.shortID, editProject);
    setEdit(false);
  }
}

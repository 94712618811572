import { Alert, InputNumber, Tag, Select } from 'antd';
import { ISlabPreset, SlabPresets } from '../../assets/SlabPresets';
import { t } from '../../localization';

export const SuggestedSlabs: React.FC<{
  bestThickness: number;
  stiffnessRatio: number;
  slabPairs: [ISlabPreset, number][];
  suggestedCount: number;
  setSuggestedCount: (c: number) => void;
  thicknessWeight: number;
  setThicknessWeight: (w: number) => void;
  stiffnessRatioWeight: number;
  setStiffnessRatioWeight: (w: number) => void;
  setSlabPairs: (p: [ISlabPreset, number][]) => void;
  suggestedPairNames: string[];
}> = ({
  bestThickness,
  slabPairs,
  suggestedCount,
  setSuggestedCount,
  thicknessWeight,
  stiffnessRatioWeight,
  stiffnessRatio,
  setStiffnessRatioWeight,
  setThicknessWeight,
  setSlabPairs,
  suggestedPairNames,
}) => {
  return (
    <Alert
      style={{ marginTop: '1rem' }}
      message={
        <span>
          <strong>{t('SUGGESTED_SLABS')}</strong>
        </span>
      }
      description={
        <section>
          <div className='result-input'>
            <span>{t('STIFFNESSRATIO_WEIGHT')}: </span>
            <InputNumber size='small' min={0} step={0.01} value={stiffnessRatioWeight} onChange={(e) => setStiffnessRatioWeight(e)} />
            <span>{t('THICKNESS_WEIGHT')}: </span>
            <InputNumber size='small' min={0} step={0.01} value={thicknessWeight} onChange={(e) => setThicknessWeight(e)} />
            <span>{t('SUGGESTED_COUNT')}: </span>
            <InputNumber size='small' min={1} max={SlabPresets.length} step={1} value={suggestedCount} onChange={(e) => setSuggestedCount(e)} />
          </div>
          <div>
            {t('BEST_THICKNESS')}: <Tag>{bestThickness} mm</Tag>
          </div>
          <div style={{ marginBottom: '10px' }} title={t('THE_SMALL_THE_BETTER')}>
            {t('SCORE_FORMULAR')}:{' '}
            <Tag>
              | {t('SLAB_THICKNESS')} - {t('BEST_THICKNESS')} | x 0.01 x {t('THICKNESS_WEIGHT')} + | {t('SLAB_STIFFNESSRATIO')} -{' '}
              {t('CURRENT_STIFFNESSRATIO')} | x {t('STIFFNESSRATIO_WEIGHT')}
            </Tag>
          </div>

          <Select mode='multiple' allowClear style={{ width: '100%' }} value={slabPairs.map(([s, _]) => s.name)} onChange={manualSelect}>
            {SlabPresets.map((slab) => {
              const score =
                Math.abs(slab.thickness - bestThickness) * thicknessWeight * 0.01 +
                Math.abs(slab.stiffnessRatio - stiffnessRatio) * stiffnessRatioWeight;

              const isSuggested = suggestedPairNames.includes(slab.name);

              return (
                <Select.Option key={slab.name} value={slab.name}>
                  <span
                    style={{ color: isSuggested ? '#1890ff' : '#000', fontWeight: isSuggested ? 'bold' : 'normal' }}
                    title={`${t('THICKNESS')}: ${slab.thickness} mm\n${t('STIFFNESS_RATIO')}: ${slab.stiffnessRatio}\n${t('SCORE')}: | ${
                      slab.thickness
                    } - ${bestThickness} | x 0.01 x ${thicknessWeight} + | ${slab.stiffnessRatio} - ${stiffnessRatio.toFixed(
                      2
                    )} | x ${stiffnessRatioWeight} = ${score.toFixed(2)}`}
                  >
                    {slab.name} ({score.toFixed(2)})
                  </span>
                </Select.Option>
              );
            })}
          </Select>
        </section>
      }
    />
  );

  function manualSelect(names: string[]) {
    setSlabPairs(
      names.map((name) => {
        const slab = SlabPresets.find((s) => s.name === name)!;
        const score =
          Math.abs(slab.thickness - bestThickness) * thicknessWeight * 0.01 + Math.abs(slab.stiffnessRatio - stiffnessRatio) * stiffnessRatioWeight;

        return [slab, score];
      })
    );
  }
};

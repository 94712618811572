// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TS3: 'Timber Structures 3.0',
  NEW_PROJECT: 'Neues Projekt',
  MY_PROJECTS: 'Meine Projekte',
  PROJECT_ID: 'Projekt-ID',
  YOUR_EMAIL: 'Ihre E-Mail',
  EMAIL_PLACEHOLDER: 'Ihre E-Mail...',
  UNTITLED: 'Unbenannt',
  PROJECT_NAME: 'Projektname',
  PROJECT_DESCRIPTION: 'Projektbeschreibung',
  CREATOR: 'Ersteller',
  DRAWING_NAME: 'Entwurfsname',
  DRAFT_DATE: 'Gezeichnet am',
  COPY_DRAFT: 'Entwurf kopieren',
  DELETE_DRAFT: 'Entwurf löschen',
  EDIT_DRAFT: 'Entwurf bearbeiten',
  NAME: 'Name',
  CALCULATE: 'Berechnen',
  CALCULATING: 'Wird berechnet...',
  PROJECT: 'Projekt',
  DRAFTS: 'Entwürfe',
  INFO: 'Info',
  DETAILS: 'Einzelheiten',
  VERSION: 'Version',
  TARGET_RATIO: 'Zielverhältnis w/L',
  OK: 'OK',
  EMAIL: 'E-Mail',
  PASSWORD: 'Passwort',
  CONFIRM_PASSWORD: 'Bestätige Passwort',
  FIRSTNAME: 'Vorname',
  LASTNAME: 'Nachname',
  LANGUAGE: 'Sprache',
  PLEASE_INPUT_YOUR_EMAIL: 'Bitte Email eingeben',
  PLEASE_INPUT_YOUR_PASSWORD: 'Bitte Passwort eingeben',
  PLEASE_INPUT_YOUR_CONFIRM_PASSWORD: 'Bitte Passwort bestätigen',
  PLEASE_INPUT_YOUR_FIRSTNAME: 'Bitte Vorname eingeben',
  PLEASE_INPUT_YOUR_LASTNAME: 'Bitte Nachname eingeben',
  EDIT_USER: 'Editiere Benutzer',
  NEW_PASSWORD: 'Neues Passwort',
  FORGET_PASSWORD: 'Passwort vergessen',
  PASSWORD_NOT_MATCH: 'Passwort stimmt nicht',
  PLEASE_INPUT_YOUR_FIRSTNAME_AND_LASTNAME: 'Bitte Vor- und Nachname eingeben',
  EMAIL_SHOULDNOT_BE_EMPTY: 'Email kann nicht leer sein',
  INVALID_EMAIL_ADDRESS: 'Ungültige Emailadresse',
  PASSWORD_SHOULD_HAVE_LENGTH_8: 'Das Passwort muss mindestens 8 Zeichen haben.',
  PASSWORD_SHOULD_CONTAIN_ONE_CAPITALIZED: 'Das Passwort muss mindestens einen Grossbuchstaben enthalten.',
  PASSWORD_SHOULD_CONTAIN_ONE_DIGIT: 'Das Passwort muss mindestens eine Zahl enthalten.',
  NAME_SHOULD_NOT_BE_EMPTY: 'Name kann nicht leer sein.',
  EMAIL_CANNOT_BE_EMPTY: 'Email kann nicht leer sein.',
  // ============ Meldungen =========
  PROVIDE_EMAIL_TO_LINK_PROJECT: 'Bitte geben Sie die E-Mail-Adresse an, zu der dieses Projekt gehört.',
  PROVIDE_EMAIL_TO_GET_PROJECTS: 'Hole alle Projekte, die zu dieser E-Mail gehören.',
  PROJECT_ID_CANNOT_BE_EMPTY: 'Die Projekt-ID darf nicht leer sein.',
  INFO_UPDATED: 'Informationen aktualisiert.',
  DELEVEOPED_BY_D2P: 'Entwickelt von Design-to-Production',
  PROJECT_NOT_FOUND: 'Projekt nicht gefunden.',
  DOUBLE_CHECK_TEXTID: 'Bitte überprüfen Sie die Projekt-ID',
  DELETE_DRAFT_CONFIRM: 'Wollen Sie diesen Entwurf wirklich löschen?',
  DRAFT_SAVED: 'Entwurf gespeichert.',
  DRAFT_CALULATED: 'Entwurf berechnet',
  DRAFT_CALCULATING_FAILED: 'Entwurf konnte nicht berechnet werden',
  RESULTS_CLEARED: 'Ergebnisse gelöscht.',
  VERSION_NUMBER: 'Version',
  VERSION_DESCRIPTION: 'Versionsinfo',
  CURRENT: 'Aktuell',
  SHOW_SPAN_TABLE: 'Spaweitenentabelle anzeigen',
  SPAN_LOOK_UP_TABLE: 'Spannweiten-Suchtabelle',
  MAX_SPAN_NOT_YET_CALCULATED: 'Maximale Spannweite noch nicht berechnet.',
  MAXIMUM_SPANS: 'Maximale Spannweiten',
  MATCHES: 'Übereinstimmungen',
  AREA: 'Fläche',
  STIFFNESS_RATIO: 'Steifigkeitsverhältnis',
  BEST_STIFFNESS_RATIO: 'Bestes Steifigkeitsverhältnis',
  SLAB_EDGE: 'Deckenkante',
  LIVE_LOAD: 'Nutzlast',
  SPANS: 'Spannweiten',
  AVAILABLE_CROSS_SECTIONS: 'Verfügbare Plattenaufbauten',
  LAYERS: 'Aufbauten',
  MORE_THAN_TWO_LINES_CONNECTED_TO_THE_NODE: 'Mehr als zwei Linien, die mit dem Knoten verbunden sind.',
  CLICK_OR_DRAW_A_WINDOW_TO_SELECT:
    'Klicken Sie oder zeichnen Sie ein Fenster, um auszuwählen. Verwenden Sie "Shift", um die Auswahl zu erweitern. Verwenden Sie "Strg", um aus der Auswahl zu entfernen.',
  PLACE_A_COLUMN: 'Platzieren Sie eine Stütze...',
  PLACE_A_NODE_OF_THE_POLYLINE: 'Platzieren Sie einen Knoten der Polylinie...',
  PICK_THE_START_POINT_TO_MOVE: 'Wählen Sie den zu verschiebenden Startpunkt...',
  PICK_THE_END_POINT: 'Wählen Sie den Endpunkt...',
  PICK_THE_START_POINT_OF_THE_LINE: 'Wählen Sie den Startpunkt der Linie...',
  PICK_THE_END_POINT_OF_THE_LINE: 'Wählen Sie den Endpunkt der Linie...',
  PICK_THE_FIRST_CORNER_OF_THE_RECTANGLE: 'Wählen Sie die erste Ecke des Rechtecks...',
  PICK_THE_SECOND_CORNER_OF_THE_RECTANGLE: 'Wählen Sie die zweite Ecke des Rechtecks...',
  NODES_CANNOT_BE_ADDED_AT_THE_SAME_POSITION: 'Knoten können nicht an der gleichen Position hinzugefügt werden.',
  THE_START_AND_END_OF_THE_LINE_SHOULD_NOT_OVERLAP: 'Der Anfang und das Ende der Linie sollten sich nicht überlappen.',
  A_POLYLINE_SHOULD_NOT_HAVE_DUPLICATED_NODE_EXCEPT_THE_END_NODE:
    'Eine Polylinie sollte mit Ausnahme des Endknotens keine duplizierten Knoten haben.',
  CONFIRM_CALCULATION: 'Berechnung bestätigen',
  CONFIRM_CALCULATION_CONTENT:
    'Das Tool konnte die Spannweiten Ihrer Zeichnung nicht berechnen. Sind Sie sicher, dass Sie über RFEM berechnen wollen?',
  // ============ steuert =========
  OPEN: 'Öffnen',
  YES: 'Ja',
  NO: 'Nein',
  SELECT_BUTTON: 'Auswählen (Esc)',
  DELETE_BUTTON: 'Löschen (Entf)',
  MOVE_BUTTON: 'Verschieben (M)',
  COLUMN_BUTTON: 'Stütze (C)',
  LINE_BUTTON: 'Linie (L)',
  RECTANGLE_BUTTON: 'Rechteck (R)',
  POLYGON_BUTTON: 'Vieleck (P)',
  UNDO_BUTTON: 'Rückgängig (Strg+Z)',
  REDO_BUTTON: 'Wiederholen (Strg+Y)',
  CALCULATE_BUTTON: 'Berechnen (Strg+K)',
  CALCULATE_SPAN: 'Spannweite berechnen',
  ZOOM_IN: 'Vergrössern',
  ZOOM_OUT: 'Herauszoomen',
  SETTINGS: 'Einstellungen',
  GRID_SIZE: 'Rastergrösse',
  SPAN_ANGLE: 'Spannwinkel',
  CLEAR_RESULTS: 'Ergebnisse löschen',
  EDGE: 'Kante',
  WALL: 'Wand',
  COLUMNS: 'Stützen',
  VARIOUS: 'Verschiedene',
  COLUMN_COUNT: 'Stützenanzahl',
  FINISH: 'Fertigstellung',
  CANCEL: 'Abbrechen',
  LOGIN: 'Einloggen',
  LOGOUT: 'AusLoggen',
  SIGNUP: 'Registrieren',
  FORGOT_PASSWORD: 'Passwort vergessen',
  CHANGE_PASSWORD: 'Passwort ändern',
  PREFERENCE: 'Einstellungen',
  UPDATE: 'Aktualisieren',
  CHANGE_SUCCESS: 'Änderung erfolgreich',
  DRAWINGS: 'Zeichnungen',
  DESCRIPTION: 'Beschreibung',
  FAILED_TO_CREATE_PROJECT: 'Projekt konnte nicht erstellt werden',
  DELETE: 'Löschen',
  ARE_YOU_SURE_TO_DELETE_THIS_PROJECT: 'Sind Sie sicher, dass Sie dieses Projekt löschen möchten?',
  PROJECT_DELETED: 'Projekt gelöscht',
  TRY_WITHOUT_LOGIN: 'Ohne Anmeldung probieren',
  TS3_CONFIGURATOR: 'TS3 Konfigurator',
  PLEASE_LOGIN_TO_SAVE_YOUR_PROJECT: 'Bitte melden Sie sich an, um Ihr Projekt zu speichern.',
  NEW_DRAWING: 'Neue Zeichnung',
  NO_DESCRIPTION: 'Keine Beschreibung',
  IN_ORDER_TO_CALCULATE_YOUR_DRAWING_PLEASE_LOGIN_TO_SAVE_YOUR_DRAWING_FIRST:
    'Um Ihre Zeichnung zu berechnen, melden Sie sich bitte an, um Ihre Zeichnung zu speichern.',
  EDIT: 'Bearbeiten',
  CREATE: 'Erstellen',
  SUBMIT: 'Absenden',
  PROFILE: 'Profil',
  THICKNESS: 'Dicke',
  DISPLACEMENT: 'Durchbiegung',
  MAX_DISP: 'Max Verschiebung',
  SHOW_RESULT: 'Ergebnis anzeigen',
  MATERIAL: 'Material',
  SET_LOADS: 'Lasten setzen',
  DRAFT_CALCULATED: 'Entwurf berechnet',
  DRAFT_CALCULATE_FAILED: 'Entwurf konnte nicht berechnet werden',
  CALCULATION_WITH_RFEM: 'Berechnung mit RFEM',
  TOP_LAYER: 'Decklage',
  IN_X_DIR: 'In X-Richtung',
  IN_Y_DIR: 'In Y-Richtung',
  MAX_SPAN: 'Max Spannweite',
  SUGGESTED_SLABS: 'Vorgeschlagene Plattenaufbauten',
  COUNT: 'Anzahl',
  BEST_THICKNESS: 'Optimale Plattendicke',
  SCORE_FORMULAR: 'Formel zur Ermittlung geeigneter Plattenaufbauten',
  SLAB_THICKNESS: 'Plattendicke',
  THICKNESS_WEIGHT: 'Gewichtung der Dicke',
  SLAB_STIFFNESSRATIO: 'Steifigkeitsverhältnis der Platte',
  CURRENT_STIFFNESSRATIO: 'Aktuelles Steifigkeitsverhältnis',
  STIFFNESSRATIO_WEIGHT: 'Gewichtung des Steifigkeitsverhältnisses',
  SCORE: 'Punktzahl',
  THE_SMALL_THE_BETTER: 'Je kleiner, desto besser',
  PLEASE_SELECT_OR_CREATE_A_DRAWING: 'Bitte wählen oder erstellen Sie einen Entwurf.',
  CREATE_DRAWING: 'Entwurf erstellen',
  SNAP: 'Einrasten',
  RFEM_SERVER_ONLINE: 'RFEM-Server online',
  RFEM_SERVER_OFFLINE: 'RFEM-Server offline',
  CHECKING_RFEM_SERVER: 'RFEM-Server überprüfen...',
  LAST_CHECKED: 'Zuletzt überprüft',
  SUGGESTED_COUNT: 'Vorgeschlagene Anzahl',
  STIFFNESS_RATIO_ABBR: 'Steif. Verh.',
  NO_DATA: 'Keine Daten',
  NONE: 'Keine',
  SPAN: 'Spannweite',
  NO_SPAN_FOUND: 'Keine Spannweite gefunden',
  RATIO: 'Verhältnis',
  TYPE: 'Typ',
  LOCAL_DISPLACEMENT: 'Lokale Verschiebung',
  ColumnColumn: 'Stütze-Stütze',
  ColumnEdge: 'Stütze-Kante',
  ColumnWall: 'Stütze-Wand',
  WORST_RATIO: 'Max w/L',
  LC2: 'Auflast ohne Eigengewicht Brettsperrholz',
  LC3: 'Nutzlast',
  INSTRUCTION: 'Anleitung',
};

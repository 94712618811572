import { formatDistance } from 'date-fns';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';

const dict: { [key: string]: string } = {
  en: 'English',
  de: 'German',
  fr: 'French',
};

const localTimeDict: { [key: string]: string } = {
  English: 'en-US',
  German: 'de-CH',
  French: 'fr-FR',
};

const modules: { [key: string]: Locale } = {
  English: en,
  German: de,
  French: fr,
};

function userLocale() {
  // return user language: English, German, French, Italien
  let language = window.localStorage.getItem('language');
  if (!language) {
    return null;
  }
  return language;
}

function fallbackLocale() {
  // return browser lanague
  const browserLan = window.navigator.language;
  return dict[browserLan.substring(0, 2)] || 'English';
}

// the localization function
export function distanceString(timestamp: string): string {
  const localeLanguage = userLocale() || fallbackLocale();
  const time = formatDistance(new Date(timestamp), new Date(), { locale: modules[localeLanguage] });
  return time;
}

// the local time string
export function localString(time: Date): string {
  const localeLanguage = userLocale() || fallbackLocale();
  return time.toLocaleString(localTimeDict[localeLanguage]);
}

export function toDate(time: Date): string {
  const localeLanguage = userLocale() || fallbackLocale();
  return time.toLocaleDateString(localTimeDict[localeLanguage]);
}

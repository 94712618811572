import React from 'react';
import { Group, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { IInteraction } from '.';
import { ILine, INode, TSMode } from '../../Data/draft';
import { ConvertWorldCoordToCanvas, IsLineSelected, IsNodeSelected } from './helper';

export interface NodeProps {
  node: INode;
  line: ILine;
  interaction: IInteraction;
  setInteraction: (i: IInteraction) => void;
  height: number;
  zoom: number;
}

export const TSNode: React.FC<NodeProps> = ({ node, line, setInteraction, interaction, height, zoom }) => {
  const isSelected = IsNodeSelected(interaction, node);
  const isLineSelected = IsLineSelected(interaction, line);

  let { x, y } = ConvertWorldCoordToCanvas(node, height, zoom);

  if (!isLineSelected && !isSelected) return null;

  return (
    <Group onClick={onClick}>
      <Circle x={x} y={y} radius={isSelected ? 10 : 8} stroke='black' strokeWidth={1} opacity={0.8} fill={isSelected ? 'yellow' : '#d2d2d2'} />
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (interaction.mode !== TSMode.Select) return;
    setInteraction({ ...interaction, selectedIDs: { ...interaction.selectedIDs, nodes: { [node._id]: true }, lines: {} } });
  }
};

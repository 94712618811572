/*
  This module handles higher layer of actions.
  It deals with tasks and dispatch actions to reducers.
*/

import { API, INewDrawingPayload, INewProjectPayload } from './api';
import { IDraft } from '../Data/draft';
import { IDrawing, IProject, ISignIn } from '../Data/project';
import { message } from 'antd';
import { ISpan } from '../Components/TSCanvas/SpanManager';
import { RFEMRequest } from '../Data/RFEM';
import { IUser } from './state';

interface RespondProps {
  success: boolean;
  message?: string;
  data?: any;
}

export interface ITask {
  GetProject: (shortID: string) => Promise<RespondProps>;
  CreateProject: (projectPayload: INewProjectPayload) => Promise<RespondProps>;
  UpdateProject: (shortID: string, projectPayload: IProject) => Promise<RespondProps>;
  DeleteProject: (shortID: string) => Promise<RespondProps>;
  GetDrawing: (drawingID: string) => Promise<RespondProps>;

  GetUser: (userID: string) => Promise<RespondProps>;
  CreateUser: (user: IUser) => Promise<RespondProps>;
  EditUser: (user: IUser) => Promise<RespondProps>;
  ForgetPassword: (email: string) => Promise<RespondProps>;
  Authenticate: (signIn: ISignIn) => Promise<RespondProps>;
  Logout: () => Promise<RespondProps>;
  ValidateToken: () => Promise<RespondProps>;

  CreateDrawing: (drawingPayload: INewDrawingPayload) => Promise<RespondProps>;
  UpdateDrawing: (drawingID: string, drawingPayload: IDrawing) => Promise<RespondProps>;
  SaveDraft: (drawingID: string, draft: IDraft) => Promise<RespondProps>;
  UpdateSpan: (newSpan: ISpan) => Promise<RespondProps>;
  CalculateDraft: (draftID: string, requestBody: RFEMRequest) => Promise<RespondProps>;
  DeleteDrawing: (drawingID: string) => Promise<RespondProps>;
  Undo: (drawingID: string) => Promise<RespondProps>;
  Redo: (drawingID: string) => Promise<RespondProps>;
  InitSandbox: () => void;
}

export function useTask(dispatch: any): ITask {
  // get a project by id
  const GetProject = async (shortID: string) => {
    try {
      let response = await API.getProject(shortID);
      dispatch({ type: 'SET_PROJECT', payload: response.data });
      return { success: true, data: response.data };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // create a project
  const CreateProject = async (projectPayload: INewProjectPayload) => {
    try {
      let response = await API.createProject(projectPayload);
      dispatch({ type: 'SET_PROJECT', payload: response.data });
      return { success: true, data: response.data };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // update a project
  const UpdateProject = async (shortID: string, projectPayload: IProject) => {
    try {
      let response = await API.updateProject(shortID, projectPayload);
      dispatch({ type: 'SET_PROJECT', payload: response.data });
      return { success: true, data: response.data };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // delete a project
  const DeleteProject = async (shortID: string) => {
    try {
      await API.deleteProject(shortID);
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // create a drawing
  const GetDrawing = async (drawingID: string) => {
    try {
      let response = await API.getDrawing(drawingID);
      dispatch({ type: 'GET_DRAWING_DRAFT', payload: response.data });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };
  // get user
  const GetUser = async (userID: string) => {
    try {
      let response = await API.getUser(userID);
      dispatch({ type: 'GET_USER', payload: response.data });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data };
    }
  };

  // set user
  const CreateUser = async (user: IUser) => {
    try {
      let response = await API.createUser(user);
      dispatch({ type: 'GET_USER', payload: response.data });
      return { success: true };
    } catch (err: any) {
      if (err.message) return { success: false, message: err.message };
      return { success: false, message: err.response.data };
    }
  };
  // set user
  const EditUser = async (user: IUser) => {
    try {
      let response = await API.editUser(user);
      dispatch({ type: 'GET_USER', payload: response.data });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data };
    }
  };

  const Authenticate = async (signIn: ISignIn) => {
    try {
      let response = await API.authenticate(signIn);
      dispatch({ type: 'GET_USER', payload: response.data });
      return { success: true };
    } catch (err: any) {
      window.localStorage.removeItem('token');
      if (err.message) return { success: false, message: err.message };
      return { success: false, message: err.response.data };
    }
  };

  const Logout = async () => {
    dispatch({ type: 'REMOVE_USER' });
    return { success: true };
  };

  const ValidateToken = async () => {
    try {
      const token = localStorage.getItem('token') || '';
      let response = await API.validateToken(token);
      dispatch({ type: 'GET_USER', payload: response.data });
      return { success: true };
    } catch (err: any) {
      if (err.message) return { success: false, message: err.message };
      return { success: false, message: err.response.data };
    }
  };

  // forget password
  const ForgetPassword = async (email: string) => {
    try {
      let response = await API.forgetpassword(email);
      return { success: true, data: response.data };
    } catch (err: any) {
      return { success: false, message: err.response.data };
    }
  };

  // create a drawing
  const CreateDrawing = async (newDrawingPayload: INewDrawingPayload) => {
    try {
      let response = await API.createDrawing(newDrawingPayload);
      dispatch({ type: 'CREATE_DRAWING_DRAFT', payload: response.data });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // save draft
  const SaveDraft = async (drawingID: string, draft: IDraft) => {
    try {
      const isLocal = (window as any).isLocal;
      if (!isLocal) {
        let response = await API.saveDraft(drawingID, draft);
        dispatch({ type: 'GET_DRAWING_DRAFT', payload: response.data });
      } else {
        dispatch({ type: 'SAVE_LOCAL_DRAWING_DRAFT', payload: draft });
      }
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // update span
  const UpdateSpan = async (newSpan: ISpan) => {
    try {
      dispatch({ type: 'UPDATE_SPAN', payload: newSpan });
      return { success: true };
    } catch (err: any) {
      return { success: false };
    }
  };

  // update drawing
  const UpdateDrawing = async (drawingID: string, drawingPayload: IDrawing) => {
    try {
      const isLocal = (window as any).isLocal;
      if (!isLocal) {
        let response = await API.updateDrawing(drawingID, drawingPayload);
        dispatch({ type: 'UPDATE_DRAWING', payload: response.data });
      } else {
        dispatch({ type: 'UPDATE_LOCAL_DRAWING', payload: drawingPayload });
      }
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // calculate drawing
  const CalculateDraft = async (draftID: string, requestBody: RFEMRequest) => {
    try {
      let response = await API.calculateDraft(draftID, requestBody);
      dispatch({ type: 'UPDATE_DRAFT_CALCULATION', payload: response.data });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  // delete drawing
  const DeleteDrawing = async (drawingID: string) => {
    try {
      await API.deleteDrawing(drawingID);
      dispatch({ type: 'DELETE_DRAWING', payload: drawingID });
      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  const Undo = async (drawingID: string) => {
    try {
      const isLocal = (window as any).isLocal;
      if (!isLocal) {
        let response = await API.undo(drawingID);
        if (response.status === 204) {
          message.info('Nothing to undo');
        } else {
          dispatch({ type: 'GET_DRAWING_DRAFT', payload: response.data });
        }
      } else {
        dispatch({ type: 'UNDO_LOCAL' });
      }

      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  const Redo = async (drawingID: string) => {
    try {
      const isLocal = (window as any).isLocal;
      if (!isLocal) {
        let response = await API.redo(drawingID);
        if (response.status === 204) {
          message.info('Nothing to redo');
        } else {
          dispatch({ type: 'GET_DRAWING_DRAFT', payload: response.data });
        }
      } else {
        dispatch({ type: 'REDO_LOCAL' });
      }

      return { success: true };
    } catch (err: any) {
      return { success: false, message: err.response.data.message };
    }
  };

  const InitSandbox = () => {
    dispatch({ type: 'INIT_SANDBOX' });
  };

  return {
    GetProject,
    CreateProject,
    UpdateProject,
    DeleteProject,
    GetDrawing,

    GetUser,
    Authenticate,
    ValidateToken,
    CreateUser,
    EditUser,
    Logout,
    ForgetPassword,

    CreateDrawing,
    UpdateDrawing,
    SaveDraft,
    CalculateDraft,
    UpdateSpan,
    DeleteDrawing,
    Undo,
    Redo,
    InitSandbox,
  };
}

import React from 'react';
import { ITask } from '../../store/task';
import { IDrawing, IProject } from '../../Data/project';
import { IDraft } from '../../Data/draft';
import { DraftList } from './DraftList';
import { IUser } from '../../store/state';
import { DraftDetail } from './DraftDetail';

export interface SiderbarProps {
  project: IProject;
  task: ITask;
  drawing: IDrawing | null;
  draft: IDraft | null;
  user?: IUser | null;
}

export function Sidebar({ project, task, drawing, draft, user }: SiderbarProps) {
  return (
    <section className='sidebar-container'>
      <DraftList {...{ project, task, drawing, draft }} />
      {drawing && draft && <DraftDetail {...{ project, task, drawing, draft, user }} />}
    </section>
  );
}

import React from 'react';
import { Card, Modal, Button, Form, Input, message } from 'antd';
import './Projects.scss';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { t } from '../../localization';
import { INewProjectPayload } from '../../store/api';
import { ITask } from '../../store/task';

const newProjectData: INewProjectPayload = {
  name: '',
  description: '',
};

export const NewProjectCard: React.FC<{ task: ITask }> = ({ task }) => {
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newProject, setNewProject] = React.useState(newProjectData);

  function cancel() {
    setVisible(false);
  }

  async function createProject() {
    setLoading(true);
    const response = await task.CreateProject(newProject);
    setLoading(false);
    if (response.success) {
      cancel();
      history.push(`/p/${response.data.shortID}`);
    } else {
      message.error(t('FAILED_TO_CREATE_PROJECT'));
    }
  }

  return (
    <>
      <Card onClick={() => setVisible(true)} className='project-card new'>
        <div className='new-project'>
          <PlusOutlined style={{ transform: 'scale(1.3)' }} className='project-add-icon' />
          <p>{t('NEW_PROJECT')}</p>
        </div>
      </Card>
      <Modal
        style={{ minWidth: '50vw' }}
        visible={visible}
        title={t('NEW_PROJECT')}
        footer={
          <>
            <Button size='small' style={{ marginRight: '10px' }} onClick={cancel}>
              {t('CANCEL')}
            </Button>
            <Button disabled={newProject.name.trim() === ''} size='small' type='primary' loading={loading} onClick={createProject}>
              {t('CREATE')}
            </Button>
          </>
        }
        closable={true}
        onCancel={cancel}
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ height: 'fit-content' }}>
          <Form.Item required label={t('NAME')}>
            <Input value={newProject.name} onChange={(e) => setNewProject({ ...newProject, name: e.target.value })} />
          </Form.Item>

          <Form.Item label={t('DESCRIPTION')}>
            <Input.TextArea value={newProject.description} onChange={(e) => setNewProject({ ...newProject, description: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

import { ISpan } from '../Components/TSCanvas/SpanManager';
import { IDraft } from '../Data/draft';
import { IProject, LanguageEnum } from '../Data/project';

export interface ISandbox {
  project: IProject;
  drafts: IDraft[];
}

export interface IState {
  project: IProject | null;
  draft: IDraft | null;
  span: ISpan | null;
  user: IUser | null;
  isLocal?: boolean;
  sandbox: ISandbox | null;
}

export interface IUser {
  _id?: string;
  email: string;
  password: string;
  confirmPassword?: string;
  firstname: string;
  lastname: string;
  token?: string;
  language: LanguageEnum;
  createdAt?: Date;
}

export const initialState: IState = {
  project: null,
  draft: null,
  span: null,
  user: null,
  sandbox: null,
};

import { ISlabPreset } from '../../assets/SlabPresets';
import { IDraft, ILine, INode, IPolyline, LineType } from '../../Data/draft';
import { RFEMLine, RFEMLoadCaseConfig, RFEMNode, RFEMRequest } from '../../Data/RFEM';
import { t } from '../../localization';
import { PointInsidePolygon } from './helper';

export const CalculationManager = {
  CreateLoadCaseConfigs: (): RFEMLoadCaseConfig[] => {
    return [
      { Description: t('LC2'), No: 2, Loads: [{ No: 1, Magnitude: -1.2 }], Activate: true },
      { Description: t('LC3'), No: 3, Loads: [{ No: 1, Magnitude: -3.0 }], Activate: true },
    ];
  },

  GetClampedNodeIDs: (draft: IDraft, columns: INode[], surfaces: IPolyline[]) => {
    let anchorColumn: { [key: string]: string } = {};
    for (let column of columns) {
      for (let surface of surfaces) {
        const points = surface.lines.map((l) => draft.nodes[l.startID]);
        if (PointInsidePolygon(column, points)) {
          anchorColumn[surface._id] = column._id;
        }
      }
    }
    return Object.values(anchorColumn);
  },

  /**
   *  compose json request from current draft
   **/
  ComposeRequest: (draft: IDraft, surfaceNodes: RFEMLoadCaseConfig[], slabs: ISlabPreset[], rotated: boolean): RFEMRequest => {
    const materialsToCalculate = slabs.map((slab) => slab.name);

    const request: RFEMRequest = {
      Nodes: [],
      Lines: [],
      LineSupports: [],
      NodalSupports: [],
      Surfaces: [],
      Openings: [],
      SurfaceLoads: surfaceNodes,
      MaterialsToCalculate: materialsToCalculate,
      SurfaceRotated: rotated,
    };

    // add nodes
    const nodes: RFEMNode[] = [];
    const nodeNumberDict: { [key: string]: number } = {};
    for (let i = 0; i < Object.values(draft.nodes).length; i++) {
      const node: INode = Object.values(draft.nodes)[i];
      const rfemNode: RFEMNode = {
        No: i,
        X: node.x / 1000,
        Y: node.y / 1000,
      };
      nodes.push(rfemNode);
      nodeNumberDict[node._id] = i;
    }
    request.Nodes = nodes;

    const columns = Object.values(draft.nodes).filter((n) => n.isColumn);
    const polylines = draft.polylines;
    const surfaces = polylines.filter((b) => b.isClosed && !b.isCutout);
    const openings = polylines.filter((b) => b.isClosed && b.isCutout);

    // process supports in surfaces to get the first node in the surface as the clampled node
    const clampledIDs = CalculationManager.GetClampedNodeIDs(draft, columns, surfaces);

    // add nodal support
    request.NodalSupports = columns.map((c, index) => ({ No: index, NodeNo: nodeNumberDict[c._id], Clamped: clampledIDs.includes(c._id) }));

    // add lines
    const lines: RFEMLine[] = [];
    const lineNumberDict: { [key: string]: number } = {};
    const segments = polylines.flatMap((b) => b.lines);
    for (let i = 0; i < segments.length; i++) {
      const seg: ILine = segments[i];
      const line: RFEMLine = {
        No: i,
        FromNo: nodeNumberDict[seg.startID],
        ToNo: nodeNumberDict[seg.endID],
      };
      lines.push(line);
      lineNumberDict[seg._id] = i;
    }
    request.Lines = lines;

    // add line support
    request.LineSupports = segments
      .filter((line) => line.type === LineType.Wall)
      .map((line, index) => ({ No: index, LineNo: lineNumberDict[line._id] }));

    // add surface
    surfaces.forEach((polyline, index) => {
      let points: number[] = [];
      let lines: number[] = [];
      polyline.lines.forEach((line) => {
        points.push(nodeNumberDict[line.startID]);
        lines.push(lineNumberDict[line._id]);
      });
      request.Surfaces.push({ No: index, NodeNos: points, LineNos: lines });
    });

    // add opening
    openings.forEach((polyline, index) => {
      let points: number[] = [];
      let lines: number[] = [];
      polyline.lines.forEach((line) => {
        points.push(nodeNumberDict[line.startID]);
        lines.push(lineNumberDict[line._id]);
      });
      request.Openings.push({ No: index, NodeNos: points, LineNos: lines });
    });

    return request;
  },
};

import { Divider, Modal, Tag } from 'antd';
import { useState } from 'react';
import { t } from '../../localization';
import { versions } from './version';

export function VersionPanel() {
  const [show, setShow] = useState(false);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => setShow(true)} className='version-title'>
        {t('VERSION_NUMBER')} {versions[0].number}
      </a>
      <Modal width={'50vw'} title={t('VERSION_NUMBER')} visible={show} onCancel={() => setShow(false)}>
        <section className='versions-container'>
          {versions.map((v, index) => (
            <span key={v.number}>
              <Tag color='blue'>{v.number}</Tag>
              {index === 0 && (
                <Tag color='green' style={{ marginLeft: '3px' }}>
                  {t('CURRENT')}
                </Tag>
              )}
              <span className='version-number'>{new Date(v.date).toLocaleDateString()}</span>
              <section className='version-descriptions'>
                {v.descriptions.map((d, i) => (
                  <p className='version-description' key={i}>
                    {d}
                  </p>
                ))}
              </section>
              <Divider />
            </span>
          ))}
        </section>
      </Modal>
    </>
  );
}

import React from 'react';
import { Card, Tooltip } from 'antd';
import './Projects.scss';
import { EditProject } from './EditProject';
import { DeleteProject } from './DeleteProject';
import { useHistory } from 'react-router';
import { ITask } from '../../store/task';
import { FolderOpenOutlined } from '@ant-design/icons';
import { IProject } from '../../Data/project';
import { PropertyDisplay } from './PropertyDisplay';
import { t } from '../../localization';

export const ProjectCard: React.FC<{ project: IProject; task: ITask; projects: IProject[]; setProjects: (p: IProject[]) => void }> = ({
  project,
  task,
  projects,
  setProjects,
}) => {
  const history = useHistory();

  function gotoProject(projectShortID: string) {
    history.push(`/p/${projectShortID}`);
  }

  const OpenProject = () => (
    <Tooltip title={t('OPEN')}>
      <FolderOpenOutlined onClick={() => gotoProject(project.shortID)} key='open' />
    </Tooltip>
  );

  return (
    <Card
      className='project-card'
      key={project._id}
      cover={<ProjectLocationCard project={project} gotoProject={gotoProject} />}
      actions={[
        <DeleteProject project={project} task={task} projects={projects} setProjects={setProjects} />,
        <EditProject project={project} task={task} projects={projects} setProjects={setProjects} />,
        <OpenProject />,
      ]}
    >
      <Card.Meta
        description={
          <span className='project-description' title={project.description}>
            {project.description || t('NO_DESCRIPTION')}
          </span>
        }
      ></Card.Meta>
      <section style={{ marginTop: '20px' }}>
        <PropertyDisplay name={t('ID')} value={project.shortID} />
        <PropertyDisplay name={t('DRAWINGS')} value={project.drawings.length.toString()} />
      </section>
    </Card>
  );
};

const ProjectLocationCard: React.FC<{ project: IProject; gotoProject: (id: string) => void }> = ({ project, gotoProject }) => {
  return (
    <div onClick={() => gotoProject(project.shortID)} className='project-cover no-location'>
      <div className='name'>{project.name}</div>
    </div>
  );
};

/*
  The reducers handels all granular actions.
  It changes the state and returns a new one.
*/
import { initialState, IState } from './state';
import { IDraft } from '../Data/draft';
import { IDrawing } from '../Data/project';
import { CreateSandbox, RedoInSandbox, SaveDraftToSandbox, UndoInSandbox, UpdateDrawingToSandbox } from './sandbox';
import axios from 'axios';

export const reducer: React.Reducer<IState, any> = (state: IState = initialState, action: any) => {
  let drawing: IDrawing;
  let draft: IDraft;

  switch (action.type) {
    // get project
    case 'SET_PROJECT':
      return {
        ...state,
        project: action.payload,
      };
    // create drawing
    case 'CREATE_DRAWING_DRAFT':
      if (!state.project) return state;

      drawing = action.payload.drawing;
      draft = action.payload.draft;

      return {
        ...state,
        project: { ...state.project, currentDrawingID: drawing._id, drawings: [...state.project.drawings, drawing] },
        draft,
        span: null,
      };

    // select drawing
    case 'GET_DRAWING_DRAFT':
      if (!state.project) return state;

      drawing = action.payload.drawing;
      draft = action.payload.draft;

      return {
        ...state,
        project: {
          ...state.project,
          currentDrawingID: drawing._id,
          drawings: state.project.drawings.map((d) => (d._id === drawing._id ? drawing : d)),
        },
        drawingID: drawing._id,
        draft,
        span: null, // reset span calculation
      };

    // init sandbox
    case 'INIT_SANDBOX':
      return {
        ...state,
        sandbox: CreateSandbox(),
      };

    // save draft locally
    case 'SAVE_LOCAL_DRAWING_DRAFT':
      draft = action.payload;
      if (!state.sandbox) return state;

      return {
        ...state,
        sandbox: SaveDraftToSandbox(state.sandbox, draft),
      };
    case 'UNDO_LOCAL':
      if (!state.sandbox) return state;
      return {
        ...state,
        sandbox: UndoInSandbox(state.sandbox),
      };
    case 'REDO_LOCAL':
      if (!state.sandbox) return state;
      return {
        ...state,
        sandbox: RedoInSandbox(state.sandbox),
      };
    case 'UPDATE_LOCAL_DRAWING':
      drawing = action.payload;
      if (!state.sandbox) return state;
      return {
        ...state,
        sandbox: UpdateDrawingToSandbox(state.sandbox, drawing),
      };

    // set drawing
    case 'UPDATE_DRAWING':
      if (!state.project) return state;

      drawing = action.payload.drawing;

      return {
        ...state,
        project: { ...state.project, drawings: state.project.drawings.map((d) => (d._id === drawing._id ? drawing : d)) },
      };
    // update calculation
    case 'UPDATE_DRAFT_CALCULATION':
      return {
        ...state,
        draft: action.payload,
      };
    // delete drawing
    case 'DELETE_DRAWING':
      if (!state.project) return state;
      return {
        ...state,
        project: { ...state.project, currentDrawingID: undefined, drawings: state.project.drawings.filter((d) => d._id !== action.payload) },
        drawingID: undefined,
        draft: null,
      };
    // update spane
    case 'UPDATE_SPAN':
      return {
        ...state,
        span: action.payload,
      };
    // get user
    case 'GET_USER':
      axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
      window.localStorage.setItem('token', action.payload.token);
      window.localStorage.setItem('language', action.payload.language);
      (window as any).unit = action.payload.unit;
      return { ...state, user: action.payload };
    case 'REMOVE_USER':
      delete axios.defaults.headers.common['Authorization'];
      window.localStorage.removeItem('token');

      return { ...state, user: null };

    case 'UNDO':
      return state;

    case 'REDO':
      return state;

    default:
      return state;
  }
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { Drawer, Menu, Form, Input, message, Alert, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ITask } from '../../../store/task';
import { IUser } from '../../../store/state';
import { t } from '../../../localization';

export const UserProfile: React.FC<{ user: IUser; task: ITask }> = ({ user, task }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(user);
  const [error, setError] = useState('');

  useEffect(() => {
    if (visible) setEditUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Menu.Item onClick={showDrawer} key={'profile'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <UserOutlined style={{ width: '10px' }} />
          <span>{t('EDIT_USER')}</span>
        </span>
      </Menu.Item>
      <Drawer width={500} title={t('EDIT_USER')} placement='right' closable={true} onClose={onClose} visible={visible}>
        <Form className='login-form' name='SignUpForm' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submitEditUser}>
          <Form.Item label={t('EMAIL')}>
            <Input
              disabled
              value={editUser.email}
              placeholder={t('PLEASE_INPUT_YOUR_EMAIL')}
              onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
            />
          </Form.Item>

          <Form.Item label={t('FIRSTNAME')}>
            <Input
              value={editUser.firstname}
              placeholder={t('PLEASE_INPUT_YOUR_FIRSTNAME')}
              onChange={(e) => setEditUser({ ...editUser, firstname: e.target.value })}
            />
          </Form.Item>

          <Form.Item label={t('LASTNAME')}>
            <Input
              value={editUser.lastname}
              placeholder={t('PLEASE_INPUT_YOUR_LASTNAME')}
              onChange={(e) => setEditUser({ ...editUser, lastname: e.target.value })}
            />
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert message={error} type='error' />
            </Form.Item>
          )}

          <Form.Item style={{ float: 'right' }}>
            <Button type='primary' htmlType='submit' loading={loading}>
              {t('SUBMIT')}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );

  function showDrawer() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  function invalidate() {
    // check names
    if (!editUser.firstname || !editUser.lastname) {
      setError(t('PLEASE_INPUT_YOUR_FIRSTNAME_AND_LASTNAME'));
      return false;
    }

    return true;
  }

  async function submitEditUser() {
    if (!invalidate()) return;

    setLoading(true);
    const response = await task.EditUser(editUser);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
      return;
    }
    message.success(t('CHANGE_SUCCESS'));
    onClose();
  }
};

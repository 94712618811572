import { cloneDeep } from 'lodash';
import { CreateGUID, GenerateShortID } from '../Components/TSCanvas/helper';
import { IDraft } from '../Data/draft';
import { IDrawing, IProject } from '../Data/project';
import { t } from '../localization';
import { ISandbox } from './state';

export function CreateSandbox(): ISandbox {
  const projectID = CreateGUID();
  const drawingID = CreateGUID();
  const draftID = CreateGUID();
  const draft: IDraft = {
    _id: draftID,
    createdAt: new Date(),
    drawingID: drawingID,
    nodes: {},
    polylines: [],
  };

  const drawing: IDrawing = {
    _id: drawingID,
    targetRatio: 300,
    gridSize: 500,
    name: t('NEW_DRAWING'),
    projectID: projectID,
    zoom: 0.1,
    currentDraftID: draftID,
  };

  const project: IProject = {
    _id: projectID,
    createdAt: new Date(),
    creatorID: '',
    drawings: [drawing],
    name: t('NEW_PROJECT'),
    shortID: GenerateShortID(),
    currentDrawingID: drawingID,
  };

  const sandbox: ISandbox = {
    project,
    drafts: [draft],
  };

  localStorage.setItem('sandbox', JSON.stringify(sandbox));

  return sandbox;
}

export function SaveDraftToSandbox(sandbox: ISandbox, draft: IDraft) {
  const newSandbox = cloneDeep(sandbox);
  draft._id = CreateGUID();
  draft.createdAt = new Date();
  draft.basedOn = newSandbox.project.drawings[0].currentDraftID;
  newSandbox.drafts.push(draft);
  newSandbox.project.drawings[0].currentDraftID = draft._id;
  localStorage.setItem('sandbox', JSON.stringify(newSandbox));
  return newSandbox;
}

export function UndoInSandbox(sandbox: ISandbox) {
  const newSandbox = cloneDeep(sandbox);
  const currentDraft = newSandbox.drafts.find((d) => d._id === newSandbox.project.drawings[0].currentDraftID)!;
  const undoDraft = newSandbox.drafts.find((d) => d._id === currentDraft.basedOn);
  if (!undoDraft) return newSandbox;
  newSandbox.project.drawings[0].currentDraftID = undoDraft._id;
  localStorage.setItem('sandbox', JSON.stringify(newSandbox));
  return newSandbox;
}

export function RedoInSandbox(sandbox: ISandbox) {
  const newSandbox = cloneDeep(sandbox);
  const currentDraft = newSandbox.drafts.find((d) => d._id === newSandbox.project.drawings[0].currentDraftID)!;
  const redoDraft = newSandbox.drafts.find((d) => d.basedOn === currentDraft._id)!;
  if (!redoDraft) return newSandbox;
  newSandbox.project.drawings[0].currentDraftID = redoDraft._id;
  localStorage.setItem('sandbox', JSON.stringify(newSandbox));
  return newSandbox;
}

export function UpdateDrawingToSandbox(sandbox: ISandbox, drawing: IDrawing) {
  const newSandbox = cloneDeep(sandbox);
  const newDrawing = newSandbox.project.drawings.find((d) => d._id === drawing._id)!;
  newDrawing.name = drawing.name;
  newDrawing.targetRatio = drawing.targetRatio;
  newDrawing.gridSize = drawing.gridSize;
  newDrawing.zoom = drawing.zoom;
  localStorage.setItem('sandbox', JSON.stringify(newSandbox));
  return newSandbox;
}

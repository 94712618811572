import React, { useState } from 'react';
import { Form, Button, message, Input, Alert } from 'antd';
import { ITask } from '../../store/task';
import { t } from '../../localization';
import { validateEmail } from '../TSCanvas/helper';

export const ForgetPassword: React.FC<{ task: ITask }> = ({ task }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  return (
    <Form className='login-form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submitForgetPass}>
      <Form.Item label={t('EMAIL')}>
        <Input value={email} placeholder={t('PLEASE_INPUT_YOUR_EMAIL')} onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={error} type='error' />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          {t('SUBMIT')}
        </Button>
      </Form.Item>
    </Form>
  );

  function invalidate() {
    let error = validateEmail(email);
    if (error) {
      setError(error);
      return false;
    }

    return true;
  }

  async function submitForgetPass() {
    if (!invalidate()) return;
    setLoading(true);
    const response = await task.ForgetPassword(email);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
    } else {
      message.success(response.data);
    }
  }
};

import { useEffect, useState } from 'react';
import './CalculationModal.scss';
import { Tooltip, Button, Modal, message } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { t } from '../../localization';
import { RFEMLoadCaseConfig } from '../../Data/RFEM';
import { ISpan, SpanManager } from '../TSCanvas/SpanManager';
import { LoadForm } from './LoadForm';
import { IDraft } from '../../Data/draft';
import { ITask } from '../../store/task';
import { SpanResult } from './SpanResult';
import { SpanTable } from './SpanTable';
import { ISlabPreset } from '../../assets/SlabPresets';
import { SuggestedSlabs } from './SuggestedSlabs';

interface CalculationModalProps {
  draft: IDraft;
  span: ISpan | null;
  surfaceLoads: RFEMLoadCaseConfig[];
  setSurfaceLoads: (surfaceLoads: RFEMLoadCaseConfig[]) => void;
  task: ITask;
  thresholdAngle: number;
  calculateRFEM: (slabs: ISlabPreset[]) => Promise<void>;
  isLocal: boolean;
}

export const CalculationModal: React.FC<CalculationModalProps> = ({
  draft,
  span,
  surfaceLoads,
  setSurfaceLoads,
  task,
  thresholdAngle,
  calculateRFEM,
  isLocal,
}) => {
  const [show, setShow] = useState(false);
  const [slabPairs, setSlabPairs] = useState<[ISlabPreset, number][]>([]);
  const [suggestedCount, setSuggestedCount] = useState(3);
  const [suggestedThickness, setSuggestedThickness] = useState(0);
  const [suggestedStiffnessRatio, setSuggestedStiffnessRatio] = useState(0);
  const [suggestedPairNames, setSuggestedPairNames] = useState<string[]>([]);

  const [thicknessWeight, setThicknessWeight] = useState(0.7);
  const [stiffnessRatioWeight, setStiffnessRatioWeight] = useState(1);

  useEffect(() => {
    if (!span) calculateSpan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (span && span.maxSpan) suggestSlabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [span, surfaceLoads, suggestedCount, thicknessWeight, stiffnessRatioWeight]);

  const maxSpan = span && span.maxSpan ? span.maxSpan : undefined;

  function openModal() {
    if (isLocal) {
      message.info(t('IN_ORDER_TO_CALCULATE_YOUR_DRAWING_PLEASE_LOGIN_TO_SAVE_YOUR_DRAWING_FIRST'));
      return;
    }

    setShow(true);
  }

  return (
    <>
      <Tooltip title={t('CALCULATE_BUTTON')}>
        <Button
          style={{ float: 'right' }}
          danger={draft.result === undefined}
          type={'primary'}
          size='small'
          icon={<DashboardOutlined />}
          onClick={openModal}
        ></Button>
      </Tooltip>
      <Modal
        style={{ minWidth: '1100px', width: '90vw' }}
        title={t('CALCULATION_WITH_RFEM')}
        footer={null}
        visible={show}
        onCancel={() => setShow(false)}
      >
        <div className='horizontal-container'>
          <SpanResult maxSpan={maxSpan} />
          <LoadForm {...{ surfaceLoads, setSurfaceLoads }} />
        </div>
        <SpanTable
          {...{
            slabPairs,
            setSlabPairs,
            bestThickness: suggestedThickness,
            stiffnessRatio: suggestedStiffnessRatio,
            stiffnessRatioWeight,
            thicknessWeight,
          }}
        />
        <SuggestedSlabs
          {...{
            bestThickness: suggestedThickness,
            slabPairs,
            suggestedCount,
            setSuggestedCount,
            stiffnessRatio: suggestedStiffnessRatio,
            stiffnessRatioWeight,
            thicknessWeight,
            setStiffnessRatioWeight,
            setThicknessWeight,
            setSlabPairs,
            suggestedPairNames,
          }}
        />
        <Button style={{ marginTop: '1rem' }} block type='primary' onClick={!span?.maxSpan ? confirmCalculation : startCalculation}>
          {t('CALCULATE')}
        </Button>
      </Modal>
    </>
  );

  async function calculateSpan() {
    await SpanManager.CalculateSpan(task, draft, thresholdAngle);
  }

  async function suggestSlabs() {
    if (!span) return;
    const liveLoad = Math.abs(surfaceLoads[1].Loads[0].Magnitude);

    const { bestThickness, bestSlabPairs, stiffnessRatio } = await SpanManager.FindBestNSlabs(
      span,
      liveLoad,
      thicknessWeight,
      stiffnessRatioWeight,
      suggestedCount
    );

    setSlabPairs([...bestSlabPairs, ...slabPairs.filter(([slab]) => !suggestedPairNames.includes(slab.name))]);
    setSuggestedPairNames(bestSlabPairs.map(([slab, _]) => slab.name));
    setSuggestedThickness(bestThickness);
    setSuggestedStiffnessRatio(stiffnessRatio);
  }

  function confirmCalculation() {
    Modal.confirm({
      title: t('CONFIRM_CALCULATION'),
      content: t('CONFIRM_CALCULATION_CONTENT'),
      okText: t('YES'),
      cancelText: t('NO'),
      onOk: startCalculation,
    });
  }

  async function startCalculation() {
    setShow(false);
    await calculateRFEM(slabPairs.map((pair) => pair[0]));
  }
};

import Icon from '@ant-design/icons';
import React from 'react';

const svg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-de' viewBox='0 0 640 480'>
    <path fill='#ffce00' d='M0 320h640v160H0z' />
    <path d='M0 0h640v160H0z' />
    <path fill='#d00' d='M0 160h640v160H0z' />
  </svg>
);

export const FlagGermanyIcon = (props: any) => <Icon style={{ width: '20px' }} component={svg} {...props} />;

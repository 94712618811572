/*
  The path file defines all the path
*/

export const path = {
  dashboard: '/',
  login: '/login',
  project: '/p/:project_textid',
  localProject: '/new',
};

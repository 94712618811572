import React from 'react';
import { Rect, Group, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { IInteraction } from '.';
import { INode, TSMode } from '../../Data/draft';
import { ConvertWorldCoordToCanvas, IsNodeSelected } from './helper';

export interface ColumnProps {
  column: INode;
  interaction: IInteraction;
  setInteraction: (interaction: IInteraction) => void;
  height: number;
  zoom: number;
}

export const TSColumn: React.FC<ColumnProps> = ({ column, setInteraction, interaction, height, zoom }) => {
  const size = 20;

  let { x, y } = ConvertWorldCoordToCanvas(column, height, zoom);

  const isSelected = IsNodeSelected(interaction, column);

  if (isSelected && interaction.translation) {
    const deltaX = interaction.translation.p2.x - interaction.translation.p1.x;
    const deltaY = interaction.translation.p2.y - interaction.translation.p1.y;
    x += deltaX;
    y += deltaY;
  }

  return (
    <Group>
      <Group onClick={onClick}>
        {column.lineID === undefined ? (
          <>
            <Rect
              x={x - size / 2}
              y={y - size / 2}
              width={size}
              height={size}
              stroke='black'
              strokeWidth={2}
              fill={isSelected ? 'yellow' : '#919191'}
              opacity={0.5}
            />
            <Circle x={x} y={y} radius={size / 2 - 4} fillEnabled={false} stroke='919191' strokeWidth={1} />
          </>
        ) : (
          <Circle x={x} y={y} radius={size / 2} fill={isSelected ? 'yellow' : '#919191'} stroke='black' strokeWidth={2} opacity={0.4} />
        )}
      </Group>
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (interaction.mode !== TSMode.Select) return;
    if (e.evt.ctrlKey) {
      setInteraction({
        ...interaction,
        selectedIDs: { ...interaction.selectedIDs, nodes: { ...interaction.selectedIDs.nodes, [column._id]: false } },
      });
    } else if (e.evt.shiftKey) {
      setInteraction({
        ...interaction,
        selectedIDs: { ...interaction.selectedIDs, nodes: { ...interaction.selectedIDs.nodes, [column._id]: true } },
      });
    } else {
      setInteraction({ ...interaction, selectedIDs: { ...interaction.selectedIDs, nodes: { [column._id]: true }, lines: {} } });
    }
  }
};

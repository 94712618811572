import React from 'react';
import { Layer, Line, Text } from 'react-konva';

export interface TSGridLayerProps {
  width: number;
  height: number;
  gridSize: number;
  zoom: number;
}

const TSGridLayer: React.FC<TSGridLayerProps> = ({ width, height, gridSize, zoom }) => {
  // compose points for line
  var lines: number[][] = [];

  const pixelSize = gridSize * zoom;

  for (let i = 1; i < width / pixelSize; i++) {
    lines.push([pixelSize * i, 0, pixelSize * i, height]);
  }

  for (let i = 1; i < height / pixelSize; i++) {
    lines.push([0, pixelSize * i, width, pixelSize * i]);
  }

  const dashLength = pixelSize / 10;

  return (
    <Layer name='gridlayer'>
      {lines.map((line, index) => (
        <Line key={index} points={line} dash={[dashLength, dashLength]} opacity={0.3} stroke='black' strokeWidth={1} />
      ))}
      <Line key={'x'} points={[pixelSize, height - pixelSize, pixelSize * 2, height - pixelSize]} opacity={1} stroke='black' strokeWidth={1} />
      <Line
        key={'xarrow'}
        points={[pixelSize * 2 - 5, height - pixelSize + 5, pixelSize * 2, height - pixelSize, pixelSize * 2 - 5, height - pixelSize - 5]}
        opacity={1}
        stroke='black'
        strokeWidth={1}
      />
      <Text text={`X (${gridSize} mm)`} x={pixelSize * 2 + 5} y={height - pixelSize - 4} align='center' verticalAlign='middle' />
      <Line key={'y'} points={[pixelSize, height - pixelSize, pixelSize, height - pixelSize * 2]} opacity={1} stroke='black' strokeWidth={1} />
      <Line
        key={'yarrow'}
        points={[pixelSize + 5, height - pixelSize * 2 + 5, pixelSize, height - pixelSize * 2, pixelSize - 5, height - pixelSize * 2 + 5]}
        opacity={1}
        stroke='black'
        strokeWidth={1}
      />
      <Text text={'Y'} x={pixelSize - 4} y={height - pixelSize * 2 - 15} align='center' verticalAlign='middle' />
    </Layer>
  );
};

export default TSGridLayer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { Drawer, Menu, Form, Select, message, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { ITask } from '../../../store/task';
import { t } from '../../../localization';
import { LanguageEnum } from '../../../Data/project';
import { IUser } from '../../../store/state';

const { Option } = Select;

export const UserPreference: React.FC<{ user: IUser; task: ITask }> = ({ user, task }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(user);

  useEffect(() => {
    if (visible) setEditUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Menu.Item onClick={showDrawer} key={'preference'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SettingOutlined style={{ width: '10px' }} />
          <span>{t('PREFERENCE')}</span>
        </span>
      </Menu.Item>
      <Drawer width={500} title={t('PREFERENCE')} placement='right' closable={true} onClose={onClose} visible={visible}>
        <Form className='login-form' name='SignUpForm' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submitEditUser}>
          <Form.Item label={t('LANGUAGE')}>
            <Select value={editUser.language} style={{ width: 120 }} onChange={(e) => ChangeLanguage(e)}>
              <Option value='English'>English</Option>
              <Option value='German'>Deutsch</Option>
              <Option value='French'>Francais</Option>
              <Option value='Arabic' disabled>
                Arabic
              </Option>
            </Select>
          </Form.Item>

          <Form.Item style={{ float: 'right' }}>
            <Button type='primary' htmlType='submit' loading={loading}>
              {t('SUBMIT')}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );

  function showDrawer() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  function ChangeLanguage(e: LanguageEnum) {
    setEditUser({ ...editUser, language: e });
  }

  async function submitEditUser() {
    setLoading(true);
    const response = await task.EditUser(editUser);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
      return;
    }
    message.success(t('CHANGE_SUCCESS'));
    onClose();
  }
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './Project.scss';
import { t } from '../../localization';
import { Layout, Spin, Alert } from 'antd';
import { useContext } from '../../store';
import { Draft } from '../Draft';
import { Sidebar } from './Sidebar';
import { IProject } from '../../Data/project';
import { IDraft } from '../../Data/draft';
import { ISpan } from '../TSCanvas/SpanManager';
import { IUser } from '../../store/state';
import { Navbar } from '../Navbar';

export function Project(props: any) {
  const { state, task } = useContext();
  const [loading, setLoading] = useState(false);

  (window as any).isLocal = false;

  useEffect(() => {
    const projectTextID = props.match.params.project_textid;
    fetchProject(projectTextID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.pathname]);

  async function fetchProject(projectTextID: string) {
    setLoading(true);
    const response = await task.GetProject(projectTextID);
    if (response.success) {
      const pd = response.data as IProject;
      if (pd.currentDrawingID) await task.GetDrawing(pd.currentDrawingID);
      await task.GetUser((response.data as IProject).creatorID);
    }
    setLoading(false);
  }

  const project: IProject | null = state.project;

  const draft: IDraft | null = state.draft;
  const span: ISpan | null = state.span;
  const user: IUser | null = state.user;

  if (loading) return <ProjectLoading />;
  if (!project) return <ProjectError {...props} />;

  const drawing = project!.drawings.find((d) => d._id === project.currentDrawingID) || null;

  return (
    <Layout>
      <Navbar project={project} user={user} task={task} />
      <section className='main-container'>
        <Sidebar project={project} task={task} drawing={drawing} draft={draft} user={user} />
        {drawing && draft ? (
          <Draft project={project} drawing={drawing} draft={draft} span={span} task={task} user={user} isLocal={false} />
        ) : (
          <section className='warning-container'>
            <Alert type='warning' message={t('PLEASE_SELECT_OR_CREATE_A_DRAWING')}></Alert>
          </section>
        )}
      </section>
    </Layout>
  );
}

function ProjectLoading() {
  return (
    <section className='empty-container'>
      <Spin size='large' />
    </section>
  );
}

function ProjectError(props: any) {
  return (
    <section className='empty-container'>
      <Alert message={t('PROJECT_NOT_FOUND')} type='error' showIcon />
      <p className='empty-title'>{t('DOUBLE_CHECK_TEXTID')}</p>
      <p className='empty-body'>
        {t('PROJECT_ID')} : <strong>{props.match.params.project_textid}</strong>
      </p>
    </section>
  );
}

interface ISpanPreset {
  spanX: number;
  spanY: number;
  area: number;
  load: number;
  thickness: number;
}

export const SpanPresets: ISpanPreset[] = [
  { spanX: 4, spanY: 4, area: 16, load: 2, thickness: 180 },
  { spanX: 4, spanY: 4, area: 16, load: 3, thickness: 180 },
  { spanX: 4, spanY: 4, area: 16, load: 4, thickness: 200 },
  { spanX: 4, spanY: 4, area: 16, load: 5, thickness: 220 },
  { spanX: 5, spanY: 4, area: 20, load: 2, thickness: 200 },
  { spanX: 5, spanY: 4, area: 20, load: 3, thickness: 220 },
  { spanX: 5, spanY: 4, area: 20, load: 4, thickness: 220 },
  { spanX: 5, spanY: 4, area: 20, load: 5, thickness: 230 },
  { spanX: 5, spanY: 5, area: 25, load: 2, thickness: 220 },
  { spanX: 5, spanY: 5, area: 25, load: 3, thickness: 230 },
  { spanX: 5, spanY: 5, area: 25, load: 4, thickness: 260 },
  { spanX: 5, spanY: 5, area: 25, load: 5, thickness: 270 },
  { spanX: 6, spanY: 4, area: 24, load: 2, thickness: 240 },
  { spanX: 6, spanY: 4, area: 24, load: 3, thickness: 250 },
  { spanX: 6, spanY: 4, area: 24, load: 4, thickness: 270 },
  { spanX: 6, spanY: 4, area: 24, load: 5, thickness: 270 },
  { spanX: 6, spanY: 5, area: 30, load: 2, thickness: 240 },
  { spanX: 6, spanY: 5, area: 30, load: 3, thickness: 250 },
  { spanX: 6, spanY: 5, area: 30, load: 4, thickness: 270 },
  { spanX: 6, spanY: 5, area: 30, load: 5, thickness: 270 },
  { spanX: 6, spanY: 6, area: 36, load: 2, thickness: 270 },
  { spanX: 6, spanY: 6, area: 36, load: 3, thickness: 270 },
  { spanX: 6, spanY: 6, area: 36, load: 4, thickness: 280 },
  { spanX: 6, spanY: 6, area: 36, load: 5, thickness: 280 },
  { spanX: 7, spanY: 5, area: 35, load: 2, thickness: 270 },
  { spanX: 7, spanY: 5, area: 35, load: 3, thickness: 280 },
  { spanX: 7, spanY: 5, area: 35, load: 4, thickness: 300 },
  { spanX: 7, spanY: 5, area: 35, load: 5, thickness: 320 },
  { spanX: 7, spanY: 6, area: 42, load: 2, thickness: 270 },
  { spanX: 7, spanY: 6, area: 42, load: 3, thickness: 280 },
  { spanX: 7, spanY: 6, area: 42, load: 4, thickness: 300 },
  { spanX: 7, spanY: 6, area: 42, load: 5, thickness: 320 },
  { spanX: 7, spanY: 7, area: 49, load: 2, thickness: 300 },
  { spanX: 7, spanY: 7, area: 49, load: 3, thickness: 300 },
  { spanX: 7, spanY: 7, area: 49, load: 4, thickness: 310 },
  { spanX: 7, spanY: 7, area: 49, load: 5, thickness: 330 },
  { spanX: 8, spanY: 6, area: 48, load: 2, thickness: 310 },
  { spanX: 8, spanY: 6, area: 48, load: 3, thickness: 320 },
  { spanX: 8, spanY: 6, area: 48, load: 4, thickness: 350 },
  { spanX: 8, spanY: 6, area: 48, load: 5, thickness: 360 },
  { spanX: 8, spanY: 7, area: 56, load: 2, thickness: 340 },
  { spanX: 8, spanY: 7, area: 56, load: 3, thickness: 350 },
  { spanX: 8, spanY: 7, area: 56, load: 4, thickness: 360 },
  { spanX: 8, spanY: 7, area: 56, load: 5, thickness: 370 },
  { spanX: 8, spanY: 8, area: 64, load: 2, thickness: 350 },
  { spanX: 8, spanY: 8, area: 64, load: 3, thickness: 360 },
  { spanX: 8, spanY: 8, area: 64, load: 4, thickness: 370 },
  { spanX: 8, spanY: 8, area: 64, load: 5, thickness: 380 },
  { spanX: 9, spanY: 7, area: 63, load: 2, thickness: 380 },
  { spanX: 9, spanY: 7, area: 63, load: 3, thickness: 390 },
  { spanX: 9, spanY: 7, area: 63, load: 4, thickness: 400 },
  { spanX: 9, spanY: 7, area: 63, load: 5, thickness: 410 },
  { spanX: 9, spanY: 8, area: 72, load: 2, thickness: 400 },
  { spanX: 9, spanY: 8, area: 72, load: 3, thickness: 410 },
  { spanX: 9, spanY: 8, area: 72, load: 4, thickness: 420 },
  { spanX: 9, spanY: 8, area: 72, load: 5, thickness: 420 },
  { spanX: 9, spanY: 9, area: 81, load: 2, thickness: 420 },
  { spanX: 9, spanY: 9, area: 81, load: 3, thickness: 430 },
  { spanX: 9, spanY: 9, area: 81, load: 4, thickness: 440 },
  { spanX: 9, spanY: 9, area: 81, load: 5, thickness: 440 },
];

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './Project.scss';
import { Layout } from 'antd';
import { useContext } from '../../store';
import { Draft } from '../Draft';
import { IProject } from '../../Data/project';
import { IDraft } from '../../Data/draft';
import { Navbar } from '../Navbar';
import { ISpan } from '../TSCanvas/SpanManager';
import { Redirect } from 'react-router';
import { path } from '../../path';

export function LocalProject(props: any) {
  const { state, task } = useContext();

  const user = state.user;

  useEffect(() => {
    const confirm = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      const sandbox = window.localStorage.getItem('sandbox');
      if (sandbox) {
        return (e.returnValue = 'If you leave this site you will lose your unsaved changes.');
      }
    };

    window.addEventListener('beforeunload', confirm, { capture: true });

    return () => window.removeEventListener('beforeunload', confirm, { capture: true });
  }, []);

  useEffect(() => {
    if (!user) task.InitSandbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (user) return <Redirect to={path.dashboard} />;

  if (!state.sandbox) return null;
  const project: IProject = state.sandbox.project;
  const drawing = project.drawings[0];
  const draft: IDraft = state.sandbox.drafts.find((d) => d._id === drawing.currentDraftID)!;
  const span: ISpan | null = state.span;

  (window as any).isLocal = true;

  return (
    <Layout>
      <Navbar user={null} project={project} task={task} />
      <section className='local-container'>
        <Draft project={project} drawing={drawing} draft={draft} span={span} task={task} user={null} isLocal={true} />
      </section>
    </Layout>
  );
}

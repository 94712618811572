import { cloneDeep } from 'lodash';
import { ISelectionIDs } from '.';
import { IDraft, ILine, INode, IPolyline, IRecRange, LineType } from '../../Data/draft';
import { ITask } from '../../store/task';
import { CreateNodesFromLine, DeleteSelectedFromDraft, SetPolylineOpenings } from './helper';

export const DraftManager = {
  /**
   *  add a polyline to the draft
   **/
  AddPolyline: async (task: ITask, currentDraft: IDraft, polyline: IPolyline, nodes: INode[]) => {
    const draft = cloneDeep(currentDraft);

    draft.polylines.push(polyline);
    for (let node of nodes) draft.nodes[node._id] = node;
    if (polyline.isClosed) SetPolylineOpenings(draft);

    await task.SaveDraft(draft.drawingID, draft);
  },

  /**
   *  add a column to the draft
   **/
  AddColumn: async (task: ITask, currentDraft: IDraft, node: INode) => {
    const draft = cloneDeep(currentDraft);

    draft.nodes[node._id] = node;

    await task.SaveDraft(draft.drawingID, draft);
  },

  /**
   *  commit move in the draft
   **/
  CommitMove: async (task: ITask, currentDraft: IDraft, translation: IRecRange, selectedIDs: ISelectionIDs) => {
    const draft = cloneDeep(currentDraft);

    const deltaX = translation.p2.x - translation.p1.x;
    const deltaY = translation.p2.y - translation.p1.y;

    const nodeIDs = Object.keys(selectedIDs.nodes).filter((i) => selectedIDs.nodes[i]);
    const lineIDs = Object.keys(selectedIDs.lines).filter((i) => selectedIDs.lines[i]);

    // move selected nodes
    for (let id of nodeIDs) {
      if (draft.nodes[id]) {
        draft.nodes[id].x += deltaX;
        draft.nodes[id].y += deltaY;
      }
    }

    // move selected lines
    let lines: { [id: string]: ILine } = {};
    for (let pl of draft.polylines) {
      for (let line of pl.lines) {
        lines[line._id] = line;
      }
    }
    for (let id of lineIDs) {
      if (lines[id]) {
        if (!selectedIDs.nodes[lines[id].startID]) {
          draft.nodes[lines[id].startID].x += deltaX;
          draft.nodes[lines[id].startID].y += deltaY;
        }
        if (!selectedIDs.nodes[lines[id].endID]) {
          draft.nodes[lines[id].endID].x += deltaX;
          draft.nodes[lines[id].endID].y += deltaY;
        }
      }
    }

    await task.SaveDraft(draft.drawingID, draft);
  },

  /**
   *  change line type
   **/
  ChangeLineType: async (task: ITask, currentDraft: IDraft, selectedIDs: ISelectionIDs, newType: LineType) => {
    const draft = cloneDeep(currentDraft);

    const lineIDs = Object.keys(selectedIDs.lines).filter((i) => selectedIDs.lines[i]);

    const lines = draft.polylines.flatMap((pl) => pl.lines);

    for (let id of lineIDs) {
      const line = lines.find((l) => l._id === id);
      if (!line) continue;

      if (line.type === LineType.Columns && line.columnIDs !== undefined && newType !== LineType.Columns) {
        for (let nodeID of line.columnIDs) delete draft.nodes[nodeID];
      }

      line.type = newType;
    }

    await task.SaveDraft(draft.drawingID, draft);
  },

  /**
   *  set grid size in the draft
   **/
  ChangeLineColumnNumber: async (task: ITask, currentDraft: IDraft, selectedIDs: ISelectionIDs, newCount: number) => {
    const draft = cloneDeep(currentDraft);

    const lineIDs = Object.keys(selectedIDs.lines).filter((i) => selectedIDs.lines[i]);

    const lines = draft.polylines.flatMap((pl) => pl.lines);

    for (let id of lineIDs) {
      const line = lines.find((l) => l._id === id);
      if (!line) continue;
      // update selected line type
      line.type = LineType.Columns;
      // remove existing columns from the line
      if (line.columnIDs) for (let nodeID of line.columnIDs) delete draft.nodes[nodeID];
      // create columns from the line based on the new count
      const newNodes = CreateNodesFromLine(draft, line, newCount);
      // assign ids to the line columnsID
      line.columnIDs = newNodes.map((n) => n._id);
    }

    await task.SaveDraft(draft.drawingID, draft);
  },

  /**
   * delete selected in the draft
   **/
  DeleteSelected: async (task: ITask, currentDraft: IDraft, selectedIDs: ISelectionIDs) => {
    const draft = cloneDeep(currentDraft);

    DeleteSelectedFromDraft(draft, selectedIDs);
    SetPolylineOpenings(draft);

    await task.SaveDraft(draft.drawingID, draft);
  },
};

import React from 'react';
import { Group, Circle, Line } from 'react-konva';
import { IDraft, ILine, INode } from '../../Data/draft';
import { ConvertWorldCoordToCanvas } from './helper';

export interface ColumnRowProps {
  draft: IDraft;
  line: ILine;
  start: INode;
  end: INode;
  isSelected: boolean;
  height: number;
  zoom: number;
}

export const TSColumnRow: React.FC<ColumnRowProps> = ({ draft, line, isSelected, height, zoom }) => {
  const size = 20;
  const lineLength = size / 2;

  if (!line.columnIDs) return null;

  const columns = line.columnIDs.map((columnID) => draft.nodes[columnID]).filter((column) => column);

  return (
    <Group>
      {columns.map((node, i) => {
        const nodeInPixels = ConvertWorldCoordToCanvas(node, height, zoom);

        return (
          <Group key={i}>
            <Line
              points={[nodeInPixels.x - lineLength, nodeInPixels.y - lineLength, nodeInPixels.x + lineLength, nodeInPixels.y + lineLength]}
              stroke={isSelected ? 'yellow' : '#515151'}
              strokeWidth={2}
              strokeEnabled
            />
            <Line
              points={[nodeInPixels.x - lineLength, nodeInPixels.y + lineLength, nodeInPixels.x + lineLength, nodeInPixels.y - lineLength]}
              stroke={isSelected ? 'yellow' : '#515151'}
              strokeWidth={2}
              strokeEnabled
            />
            <Circle
              x={nodeInPixels.x}
              y={nodeInPixels.y}
              radius={size / 2 - 4}
              fill={isSelected ? 'yellow' : '#919191'}
              opacity={0.5}
              stroke='919191'
              strokeWidth={1}
            />
          </Group>
        );
      })}
    </Group>
  );
};

import React from 'react';
import { Stage } from 'react-konva';
import TSGridLayer from './TSGridLayer';
import TSCompLayer from './TSCompLayer';
import { message } from 'antd';
import {
  NodeInNodes,
  EqualNode,
  CreatePolylineFromNodes,
  CreateNodeFromPoint,
  getNodeAndLineIDsFromSelection,
  ConvertCanvasToWorldCoord,
} from './helper';
import { ITask } from '../../store/task';
import { TSGhostLayer } from './TSGhostLayer';
import { KonvaEventObject } from 'konva/lib/Node';
import { IDraft, INode, IPolyline, IRecRange, TSMode } from '../../Data/draft';
import { IInteraction } from '.';
import { cloneDeep } from 'lodash';
import { DraftManager } from './DraftManager';
import { ISpan } from './SpanManager';
import { TSSpanLayer } from './TSSpanLayer';
import { IDrawing } from '../../Data/project';
import { t } from '../../localization';

export interface WorkspaceProps {
  width: number;
  height: number;
  drawing: IDrawing;
  interaction: IInteraction;
  setInteraction: (i: IInteraction) => void;
  showResult: boolean;
  draft: IDraft;
  task: ITask;
  span: ISpan | null;
}

const TSWorkspace: React.FC<WorkspaceProps> = ({ width, height, drawing, interaction, setInteraction, draft, task, span, showResult }) => {
  const mode = interaction.mode;
  const mouse = interaction.mouse;

  const { gridSize, zoom } = drawing;

  return (
    <section className='workspace-container'>
      <Stage width={width} height={height} onClick={onClick} onMouseMove={onMouseMove} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
        <TSCompLayer
          drawing={drawing}
          showResult={showResult}
          draft={draft}
          interaction={interaction}
          setInteraction={setInteraction}
          height={height}
          zoom={zoom}
          span={span}
        />
        <TSGridLayer width={width} height={height} gridSize={gridSize} zoom={zoom} />
        {span !== null && <TSSpanLayer draft={draft} span={span} interaction={interaction} height={height} zoom={zoom} showResult={showResult} />}
        <TSGhostLayer interaction={interaction} height={height} zoom={zoom} />
      </Stage>
    </section>
  );

  async function onClick(e: KonvaEventObject<MouseEvent>) {
    const mouseRealCoord = ConvertCanvasToWorldCoord(mouse, height, zoom);
    // deselect all
    if (mode === TSMode.Select) {
      // if (e.target === e.currentTarget) setColumns(columns.map(n => ({ ...n, isSelected: false })));
    }
    // add column mode
    else if (mode === TSMode.AddColumn) {
      let newColumn = CreateNodeFromPoint(mouseRealCoord, true);
      if (draft.nodes && NodeInNodes(newColumn, Object.values(draft.nodes))) {
        message.error(t('NODES_CANNOT_BE_ADDED_AT_THE_SAME_POSITION'));
      }
      setInteraction({ ...interaction, mode: TSMode.Select });
      await DraftManager.AddColumn(task, draft, newColumn);
    }
    // add line mode
    else if (mode === TSMode.AddLineStart) {
      // create new node
      let newNode = CreateNodeFromPoint(mouseRealCoord);
      setInteraction({ ...interaction, mode: TSMode.AddLineEnd, tempNodes: [newNode] });
    } else if (mode === TSMode.AddLineEnd) {
      if (!interaction.tempNodes) return;
      let newNode = CreateNodeFromPoint(mouseRealCoord);
      if (EqualNode(newNode, interaction.tempNodes[0])) {
        message.warn(t('THE_START_AND_END_OF_THE_LINE_SHOULD_NOT_OVERLAP'));
        return;
      } else {
        let n1: INode = interaction.tempNodes[0];
        let n2: INode = newNode;
        let newPolyline = CreatePolylineFromNodes([n1, n2]);
        setInteraction({ ...interaction, mode: TSMode.Select, tempNodes: undefined });
        await DraftManager.AddPolyline(task, draft, newPolyline, [n1, n2]);
      }
    }
    // add rect mode
    else if (mode === TSMode.AddRectStart) {
      // create new node
      let newNode = CreateNodeFromPoint(mouseRealCoord);
      setInteraction({ ...interaction, mode: TSMode.AddRectEnd, tempNodes: [newNode] });
    } else if (mode === TSMode.AddRectEnd) {
      if (!interaction.tempNodes) return;
      let newNode = CreateNodeFromPoint(mouseRealCoord);
      if (NodeInNodes(newNode, interaction.tempNodes)) {
        message.warn(t('A_POLYLINE_SHOULD_NOT_HAVE_DUPLICATED_NODE_EXCEPT_THE_END_NODE'));
        return;
      } else {
        let n1: INode = interaction.tempNodes[0];
        let n2: INode = CreateNodeFromPoint({ x: newNode.x, y: n1.y });
        let n3: INode = newNode;
        let n4: INode = CreateNodeFromPoint({ x: n1.x, y: newNode.y });
        let nodes = [n1, n2, n3, n4];
        let newPolyline: IPolyline = CreatePolylineFromNodes(nodes, true);
        setInteraction({ ...interaction, mode: TSMode.Select, tempNodes: undefined });
        await DraftManager.AddPolyline(task, draft, newPolyline, nodes);
      }
    }
    // add polyline mode
    else if (mode === TSMode.AddPolyline) {
      // create new node
      let newNode = CreateNodeFromPoint(mouseRealCoord);
      let nodes: INode[] = interaction.tempNodes || [];
      if (nodes.length > 2 && EqualNode(newNode, nodes[0])) {
        // check polyline openings
        let newPolyline: IPolyline = CreatePolylineFromNodes(nodes, true);
        setInteraction({ ...interaction, mode: TSMode.Select, tempNodes: undefined });
        await DraftManager.AddPolyline(task, draft, newPolyline, nodes);
      } else if (NodeInNodes(newNode, nodes)) {
        message.warn(t('A_POLYLINE_SHOULD_NOT_HAVE_DUPLICATED_NODE_EXCEPT_THE_END_NODE'));
        return;
      } else {
        setInteraction({ ...interaction, tempNodes: [...nodes, newNode] });
      }
    }
    // move mode
    else if (mode === TSMode.MoveStart) {
      // set the x,y of move
      let newTranslation: IRecRange = { p1: mouse, p2: mouse };
      setInteraction({ ...interaction, mode: TSMode.MoveEnd, translation: newTranslation, selection: undefined });
    } else if (mode === TSMode.MoveEnd) {
      // move selected
      setInteraction({ ...interaction, mode: TSMode.Select, translation: undefined });

      const translation: IRecRange = {
        p1: ConvertCanvasToWorldCoord(interaction.translation!.p1, height, zoom),
        p2: ConvertCanvasToWorldCoord(interaction.translation!.p2, height, zoom),
      };
      await DraftManager.CommitMove(task, draft, translation, interaction.selectedIDs);
    }
  }

  function onMouseDown(e: KonvaEventObject<MouseEvent>) {
    if (mode === TSMode.Select) {
      const pt = { x: e.evt.offsetX, y: e.evt.offsetY };
      let newSelection: IRecRange = { p1: pt, p2: pt };
      setInteraction({ ...interaction, selection: newSelection });
    }
  }

  function onMouseMove(e: KonvaEventObject<MouseEvent>) {
    let x = Math.round(e.evt.offsetX);
    let y = Math.round(e.evt.offsetY);
    const snapPixel = gridSize * zoom;
    if (interaction.settings.isSnap) {
      x = Math.round(x / snapPixel) * snapPixel;
      y = Math.round(y / snapPixel) * snapPixel;
    }

    const currentMouse = { x, y };

    // check selection
    if (mode === TSMode.Select) {
      if (!interaction.selection) {
        setInteraction({ ...interaction, mouse: currentMouse });
        return;
      }
      let newSelection: IRecRange = { ...interaction.selection, p2: { x: e.evt.offsetX, y: e.evt.offsetY } };

      // convert selection to world coordinate
      const selectionRealCoord: IRecRange = {
        p1: ConvertCanvasToWorldCoord(newSelection.p1, height, zoom),
        p2: ConvertCanvasToWorldCoord(newSelection.p2, height, zoom),
      };

      const affectedIDs = getNodeAndLineIDsFromSelection(draft, selectionRealCoord);

      let newSelectedIDs = cloneDeep(interaction.selectedIDs);

      // check which columns are selected
      if (e.evt.ctrlKey) {
        for (let id of affectedIDs.selectedNodeIDs) newSelectedIDs.nodes[id] = false;
        for (let id of affectedIDs.selectedLineIDs) newSelectedIDs.lines[id] = false;
      } else if (e.evt.shiftKey) {
        for (let id of affectedIDs.selectedNodeIDs) newSelectedIDs.nodes[id] = true;
        for (let id of affectedIDs.selectedLineIDs) newSelectedIDs.lines[id] = true;
      } else {
        newSelectedIDs.nodes = {};
        newSelectedIDs.lines = {};
        for (let id of affectedIDs.selectedNodeIDs) newSelectedIDs.nodes[id] = true;
        for (let id of affectedIDs.selectedLineIDs) newSelectedIDs.lines[id] = true;
      }

      setInteraction({ ...interaction, selection: newSelection, selectedIDs: newSelectedIDs, mouse: currentMouse });
    }

    // check move
    else if (mode === TSMode.MoveEnd) {
      if (!interaction.translation) {
        setInteraction({ ...interaction, mouse: currentMouse });
        return;
      }
      // set the x,y of move
      let newTranslation: IRecRange = { ...interaction.translation, p2: currentMouse };
      setInteraction({ ...interaction, translation: newTranslation, mouse: currentMouse });
    } else setInteraction({ ...interaction, mouse: currentMouse });
  }

  function onMouseUp(e: KonvaEventObject<MouseEvent>) {
    if (mode === TSMode.Select) {
      setInteraction({ ...interaction, selection: undefined });
    }
  }
};

export default TSWorkspace;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Checkbox, Radio, Popover, InputNumber, message, Tag } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  RedoOutlined,
  UndoOutlined,
  DeleteOutlined,
  SettingOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import { SelectIcon, MoveIcon, PolygonIcon, ColumnIcon, LineIcon, RectIcon } from './icons';
import { ITask } from '../../store/task';
import { CreatePolylineFromNodes } from './helper';
import { IDraft, IPolyline, LineType, TSMode, VariableLineType } from '../../Data/draft';
import { IInteraction } from '.';
import { IDrawing } from '../../Data/project';
import { DraftManager } from './DraftManager';
import { ISpan, SpanManager } from './SpanManager';
import { t } from '../../localization';
import { CalculationManager } from './CalculationManager';
import { RFEMLoadCaseConfig } from '../../Data/RFEM';
import { CalculationModal } from '../CalculationModal';
import { ISlabPreset } from '../../assets/SlabPresets';

export interface ToolbarProps {
  interaction: IInteraction;
  setInteraction: (i: IInteraction) => void;
  setCalculating: (isCalculating: boolean) => void;
  showResult: boolean;
  setShowResult: (showResult: boolean) => void;
  drawing: IDrawing;
  draft: IDraft;
  task: ITask;
  span: ISpan | null;
  isLocal: boolean;
}

export const TSToolbar: React.FC<ToolbarProps> = ({
  interaction,
  setInteraction,
  setCalculating,
  showResult,
  setShowResult,
  drawing,
  draft,
  task,
  span,
  isLocal,
}) => {
  const lineType = getLinetype();
  const [columnNum, setColumnNum] = useState<number>(3);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const [snap, setSnap] = useState<boolean>(interaction.settings.isSnap);
  const [thresholdAngle, setThresholdAngle] = useState<number>(30);

  const [surfaceLoads, setSurfaceLoads] = useState<RFEMLoadCaseConfig[]>(CalculationManager.CreateLoadCaseConfigs());

  const mode = interaction.mode;

  useEffect(() => {
    setColumnNum(getColumnNumber());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft, interaction.selectedIDs.lines]);

  useEffect(() => {
    if (thresholdAngle) SpanManager.CalculateSpan(task, draft, thresholdAngle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdAngle]);

  function setMode(m: TSMode) {
    setInteraction({ ...interaction, mode: m });
  }

  // const !isEditable = Boolean(draft.result);
  const isEditable = true;

  return (
    <section className='toolbar-container'>
      <Tooltip title={t('SELECT_BUTTON')}>
        <Button
          size='small'
          type={mode === TSMode.Select ? 'primary' : 'default'}
          icon={<SelectIcon />}
          onClick={() => setMode(TSMode.Select)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('DELETE_BUTTON')}>
        <Button
          size='small'
          type={'default'}
          icon={<DeleteOutlined />}
          onClick={async () => await DraftManager.DeleteSelected(task, draft, interaction.selectedIDs)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('MOVE_BUTTON')}>
        <Button
          disabled={!isEditable}
          size='small'
          type={mode === TSMode.MoveStart || mode === TSMode.MoveEnd ? 'primary' : 'default'}
          icon={<MoveIcon />}
          onClick={() => setMode(TSMode.MoveStart)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('COLUMN_BUTTON')}>
        <Button
          disabled={!isEditable}
          size='small'
          type={mode === TSMode.AddColumn ? 'primary' : 'default'}
          icon={<ColumnIcon />}
          onClick={() => setMode(TSMode.AddColumn)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('LINE_BUTTON')}>
        <Button
          disabled={!isEditable}
          size='small'
          type={mode === TSMode.AddLineStart || mode === TSMode.AddLineEnd ? 'primary' : 'default'}
          icon={<LineIcon />}
          onClick={() => setMode(TSMode.AddLineStart)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('RECTANGLE_BUTTON')}>
        <Button
          disabled={!isEditable}
          size='small'
          type={mode === TSMode.AddRectStart || mode === TSMode.AddRectEnd ? 'primary' : 'default'}
          icon={<RectIcon />}
          onClick={() => setMode(TSMode.AddRectStart)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('POLYGON_BUTTON')}>
        <Button
          disabled={!isEditable}
          size='small'
          type={mode === TSMode.AddPolyline ? 'primary' : 'default'}
          icon={<PolygonIcon />}
          onClick={() => setMode(TSMode.AddPolyline)}
        ></Button>
      </Tooltip>

      <Tooltip title={t('UNDO_BUTTON')}>
        <Button disabled={!isEditable} size='small' type={'default'} icon={<UndoOutlined />} onClick={() => task.Undo(drawing._id)}></Button>
      </Tooltip>

      <Tooltip title={t('REDO_BUTTON')}>
        <Button disabled={!isEditable} size='small' type={'default'} icon={<RedoOutlined />} onClick={() => task.Redo(drawing._id)}></Button>
      </Tooltip>

      {isLocal && (
        <Tag color='warning' style={{ marginLeft: '20px' }}>
          {t('PLEASE_LOGIN_TO_SAVE_YOUR_PROJECT')}
        </Tag>
      )}

      <CalculationModal {...{ draft, span, surfaceLoads, setSurfaceLoads, task, thresholdAngle, calculateRFEM, isLocal }} />

      <Tooltip title={t('CALCULATE_SPAN')}>
        <Button style={{ float: 'right' }} size='small' danger={!span} icon={<ProjectOutlined />} onClick={calculateSpan}></Button>
      </Tooltip>

      <Tooltip title={t('ZOOM_IN')}>
        <Button style={{ float: 'right' }} size='small' icon={<ZoomInOutlined />} onClick={zoomIn}></Button>
      </Tooltip>

      <Tooltip title={t('ZOOM_OUT')}>
        <Button style={{ float: 'right' }} size='small' icon={<ZoomOutOutlined />} onClick={zoomOut}></Button>
      </Tooltip>

      {draft.result && (
        <Tooltip title={t('SHOW_RESULT')}>
          <Button
            style={{ float: 'right' }}
            size='small'
            icon={showResult ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setShowResult(!showResult)}
          ></Button>
        </Tooltip>
      )}

      <Tooltip title={t('SETTINGS')}>
        <Popover
          placement='bottomRight'
          content={
            <section style={{ display: 'flex', flexDirection: 'row', width: '300', alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                checked={snap}
                onChange={(e) => {
                  setSnap(interaction.settings.isSnap);
                  setInteraction({ ...interaction, settings: { ...interaction.settings, isSnap: e.target.checked } });
                }}
              >
                {t('SNAP')}
              </Checkbox>

              <span>{t('SPAN_ANGLE')}(°):</span>

              <InputNumber
                step={1}
                min={0}
                max={90}
                style={{ width: '100px', marginLeft: '10px' }}
                size='small'
                value={thresholdAngle}
                onChange={(e) => setThresholdAngle(e || 0)}
              ></InputNumber>

              <span style={{ marginLeft: '10px' }}>{t('GRID_SIZE')}(mm):</span>

              <InputNumber
                step={10}
                min={10}
                max={10000}
                style={{ width: '100px', marginLeft: '10px' }}
                size='small'
                value={drawing.gridSize}
                onChange={updateGridSize}
              ></InputNumber>

              <span style={{ marginLeft: '10px' }}>{t('TARGET_RATIO')}: 1/</span>

              <InputNumber
                step={10}
                min={10}
                max={10000}
                style={{ width: '100px', marginLeft: '10px' }}
                size='small'
                value={drawing.targetRatio || 300}
                onChange={updateTargetRatio}
              ></InputNumber>

              <Button style={{ marginLeft: '10px' }} size='small' icon={<CloseOutlined />} onClick={() => setShowSettings(false)}></Button>
            </section>
          }
          trigger='click'
          visible={showSettings}
        >
          <Button style={{ float: 'right' }} size='small' type='default' icon={<SettingOutlined />} onClick={() => setShowSettings(true)}></Button>
        </Popover>
      </Tooltip>

      {Object.keys(interaction.selectedIDs.lines).filter((k) => interaction.selectedIDs.lines[k]).length > 0 && (
        <>
          <Radio.Group
            style={{ float: 'right', color: 'white', marginRight: '10px' }}
            value={lineType}
            buttonStyle={lineType === 'Various' ? 'outline' : 'solid'}
            size='small'
          >
            <Radio.Button
              onClick={async () => await DraftManager.ChangeLineType(task, draft, interaction.selectedIDs, LineType.Edge)}
              value={LineType.Edge}
            >
              {t('EDGE')}
            </Radio.Button>
            <Radio.Button
              onClick={async () => await DraftManager.ChangeLineType(task, draft, interaction.selectedIDs, LineType.Wall)}
              value={LineType.Wall}
            >
              {t('WALL')}
            </Radio.Button>
            <Radio.Button
              onClick={async () => await DraftManager.ChangeLineType(task, draft, interaction.selectedIDs, LineType.Columns)}
              value={LineType.Columns}
            >
              {t('COLUMNS')}
            </Radio.Button>
            <Radio.Button style={{ cursor: 'not-allowed' }} value={'Various'}>
              {t('VARIOUS')}
            </Radio.Button>
          </Radio.Group>
          {lineType === LineType.Columns && (
            <>
              <span style={{ marginLeft: '10px' }}>{t('COLUMN_COUNT')}:</span>

              <InputNumber
                step={1}
                min={3}
                max={100}
                style={{ width: '180px', marginLeft: '10px' }}
                size='small'
                value={columnNum}
                onChange={(e) => setColumnNumber(e)}
              />
            </>
          )}
        </>
      )}

      {mode === TSMode.AddPolyline && (
        <div style={{ float: 'right' }}>
          <Button size='small' icon={<CheckOutlined />} onClick={confirmAddPolyline} danger>
            {t('FINISH')}
          </Button>
          <Button size='small' icon={<CloseOutlined />} onClick={cancelAddPolyline} type='default'>
            {t('CANCEL')}
          </Button>
        </div>
      )}
    </section>
  );

  async function confirmAddPolyline() {
    if (!interaction.tempNodes) {
      setMode(TSMode.Select);
      return;
    }
    let newPolyline: IPolyline = CreatePolylineFromNodes(interaction.tempNodes, false);
    await DraftManager.AddPolyline(task, draft, newPolyline, interaction.tempNodes);
    setInteraction({ ...interaction, tempNodes: undefined });
  }

  function cancelAddPolyline() {
    setInteraction({ ...interaction, tempNodes: undefined });
    setMode(TSMode.Select);
  }

  async function updateGridSize(size: number) {
    if (!size) return;
    await task.UpdateDrawing(drawing._id, { ...drawing, gridSize: size });
  }

  async function updateTargetRatio(ratio: number) {
    if (!ratio) return;
    await task.UpdateDrawing(drawing._id, { ...drawing, targetRatio: ratio });
  }

  async function calculateSpan() {
    await SpanManager.CalculateSpan(task, draft, thresholdAngle);
  }

  async function calculateRFEM(slabs: ISlabPreset[]) {
    setMode(TSMode.Select);
    if (!span || !span.maxSpan) return;
    setCalculating(true);
    const rotated = span.maxSpan.ly! > span.maxSpan.lx!;
    const requestBody = CalculationManager.ComposeRequest(draft, surfaceLoads, slabs, rotated);
    let response = await task.CalculateDraft(draft._id, requestBody);

    setCalculating(false);
    setShowResult(true);
    if (response.success) {
      message.success(t('DRAFT_CALCULATED'));
    } else {
      message.error(t('DRAFT_CALCULATING_FAILED'));
    }
  }

  async function zoomIn() {
    await task.UpdateDrawing(drawing._id, { ...drawing, zoom: Math.min(drawing.zoom + 0.01, 1) });
  }

  async function zoomOut() {
    await task.UpdateDrawing(drawing._id, { ...drawing, zoom: Math.max(drawing.zoom - 0.01, 0.01) });
  }

  function getLinetype(): VariableLineType {
    const lines = draft.polylines.flatMap((p) => p.lines);
    const selectedLines = Object.keys(interaction.selectedIDs.lines)
      .filter((k) => interaction.selectedIDs.lines[k])
      .map((key) => lines.find((l) => l._id === key))
      .filter((l) => l !== undefined);

    const types = Array.from(new Set(selectedLines.map((l) => l!.type)));
    return types.length === 1 ? types[0] : 'Various';
  }

  function getColumnNumber(): number {
    const lines = draft.polylines.flatMap((p) => p.lines);
    const selectedLines = Object.keys(interaction.selectedIDs.lines)
      .filter((k) => interaction.selectedIDs.lines[k])
      .map((key) => lines.find((l) => l._id === key))
      .filter((l) => l !== undefined);

    const numbers = Array.from(new Set(selectedLines.map((l) => (l!.columnIDs ? l!.columnIDs.length : undefined))));
    return numbers.length === 1 ? (numbers[0] === undefined ? NaN : numbers[0]!) : NaN;
  }

  async function setColumnNumber(newValue: number) {
    setColumnNum(newValue);
    await DraftManager.ChangeLineColumnNumber(task, draft, interaction.selectedIDs, newValue);
  }
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { Drawer, Menu, Form, Input, message, Alert, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { ITask } from '../../../store/task';
import { IUser } from '../../../store/state';
import { t } from '../../../localization';
import { validatePassword } from '../../TSCanvas/helper';

export const ChangePassword: React.FC<{ user: IUser; task: ITask }> = ({ user, task }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(user);
  const [error, setError] = useState('');

  useEffect(() => {
    if (visible) setEditUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Menu.Item onClick={showDrawer} key={'password'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <LockOutlined style={{ width: '10px' }} />
          <span>{t('CHANGE_PASSWORD')}</span>
        </span>
      </Menu.Item>
      <Drawer width={500} title={t('CHANGE_PASSWORD')} placement='right' closable={true} onClose={onClose} visible={visible}>
        <Form className='login-form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submitEditUser}>
          <Form.Item required label={t('NEW_PASSWORD')}>
            <Input.Password
              value={editUser.password}
              placeholder={t('PLEASE_INPUT_YOUR_PASSWORD')}
              onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
            />
          </Form.Item>

          <Form.Item required label={t('CONFIRM_PASSWORD')}>
            <Input.Password
              value={editUser.confirmPassword}
              placeholder={t('PLEASE_INPUT_YOUR_CONFIRM_PASSWORD')}
              onChange={(e) => setEditUser({ ...editUser, confirmPassword: e.target.value })}
            />
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert message={error} type='error' />
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit' loading={loading}>
              {t('UPDATE')}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );

  function showDrawer() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  function invalidate() {
    let error = validatePassword(editUser.password);
    if (error) {
      setError(error);
      return false;
    }

    // confirm password
    if (editUser.password !== editUser.confirmPassword) {
      setError(t('PASSWORD_NOT_MATCH'));
      return false;
    }

    return true;
  }

  async function submitEditUser() {
    if (!invalidate()) return;

    setLoading(true);
    const response = await task.EditUser(editUser);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
      return;
    }
    message.success(t('CHANGE_SUCCESS'));
    onClose();
  }
};

import React, { useEffect } from 'react';
import { Typography, Tabs, Dropdown, Menu } from 'antd';
import { Redirect } from 'react-router-dom';
import logo from '../../logo.png';
import './Login.scss';
import { useContext } from '../../store';

import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { ForgetPassword } from './ForgetPassword';
import { DownOutlined } from '@ant-design/icons';
import { LanguageEnum } from '../../Data/project';
import { t } from '../../localization';
import { LanguageItem } from './LanguageItem';
import { useHistory } from 'react-router-dom';

// ============== Login ===========================
export const LoginPage: React.FC = () => {
  // get state
  const { state, task } = useContext();
  const [language, setLanguage] = React.useState<LanguageEnum>(LanguageEnum.English);
  const lang = (window.localStorage.getItem('language') as LanguageEnum) || LanguageEnum.English;
  const history = useHistory();

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  if (state.user) return <Redirect to='/' />;

  async function changeLanguage(language: LanguageEnum) {
    window.localStorage.setItem('language', language);
    setLanguage(language);
  }

  function gotoNewProject() {
    history.push('/new');
  }

  return (
    <section className='login-section'>
      <div className='login-container'>
        <img className='logo' alt='' src={logo} />

        <Typography.Title level={3} style={{ marginTop: '30px' }}>
          {t('TS3_CONFIGURATOR')}
        </Typography.Title>

        <div onClick={gotoNewProject} className='tryout'>
          {t('TRY_WITHOUT_LOGIN')}
        </div>

        <Tabs className='login-card' defaultActiveKey='1'>
          <Tabs.TabPane tab={t('LOGIN')} key='1'>
            <SignIn task={task} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('SIGNUP')} key='2'>
            <SignUp task={task} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('FORGOT_PASSWORD')} key='3'>
            <ForgetPassword task={task} />
          </Tabs.TabPane>
        </Tabs>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={'English'} onClick={() => changeLanguage(LanguageEnum.English)}>
                <LanguageItem language={LanguageEnum.English} />
              </Menu.Item>
              <Menu.Item key={'German'} onClick={() => changeLanguage(LanguageEnum.German)}>
                <LanguageItem language={LanguageEnum.German} />
              </Menu.Item>
              <Menu.Item disabled key={'Francais'} onClick={() => changeLanguage(LanguageEnum.French)}>
                <LanguageItem language={LanguageEnum.French} />
              </Menu.Item>
            </Menu>
          }
          placement='bottomCenter'
          trigger={['click']}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a style={{ marginTop: '1em' }} onClick={(e) => e.preventDefault()}>
            <LanguageItem language={language} /> <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </section>
  );
};

import React from 'react';
import { Group, Layer } from 'react-konva';
import { getDistAndAngleFrom2Points, ISpan } from './SpanManager';
import { IDraft } from '../../Data/draft';
import { PreviewSpanLineAndText, TSColumnSpan } from './TSColumnSpan';
import { IInteraction } from '.';

export interface SpanLayerProps {
  span: ISpan;
  draft: IDraft;
  interaction: IInteraction;
  height: number;
  zoom: number;
  showResult: boolean;
}

export const TSSpanLayer: React.FC<SpanLayerProps> = ({ span, draft, interaction, height, zoom, showResult }) => {
  const selectedColumns = Object.values(draft.nodes).filter((n) => interaction.selectedIDs.nodes[n._id] && n.isColumn && span.columns[n._id]);

  return (
    <Layer name='spanlayer'>
      {selectedColumns.map((column) => (
        <TSColumnSpan key={column._id} column={column} columnSpan={span.columns[column._id]} height={height} zoom={zoom} showResult={showResult} />
      ))}

      {selectedColumns.length === 0 && <TSMaxSpan {...{ span, draft, height, zoom, showResult }} />}
    </Layer>
  );
};

export const TSMaxSpan: React.FC<{ span: ISpan; draft: IDraft; height: number; zoom: number; showResult: boolean }> = ({
  span,
  draft,
  height,
  zoom,
  showResult,
}) => {
  if (!span.maxSpan) return null;

  const node = draft.nodes[span.maxSpan._id];
  const xPos = span.maxSpan.xPos!;
  const yPos = span.maxSpan.yPos!;

  const distanceAndAngleX = getDistAndAngleFrom2Points(node, xPos);
  const distanceAndAngleY = getDistAndAngleFrom2Points(node, yPos);

  return (
    <Group>
      <PreviewSpanLineAndText
        start={node}
        end={xPos}
        distance={span.maxSpan.lx!}
        angle={distanceAndAngleX.angle}
        emphasize
        height={height}
        zoom={zoom}
        showResult={showResult}
      />
      <PreviewSpanLineAndText
        start={node}
        end={yPos}
        distance={span.maxSpan.ly!}
        angle={distanceAndAngleY.angle}
        emphasize
        height={height}
        zoom={zoom}
        showResult={showResult}
      />
    </Group>
  );
};

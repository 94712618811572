import React, { useState, useEffect } from 'react';
import { TSToolbar } from './TSToolbar';
import TSLog from './TSLog';
import TSWorkspace from './TSWorkspace';
import { IVector, TSMode, INode, IRecRange } from '../../Data/draft';
import { Spin } from 'antd';
import { t } from '../../localization';
import { ITask } from '../../store/task';
import { IDraft } from '../../Data/draft';
import { IDrawing } from '../../Data/project';
import { DraftManager } from './DraftManager';
import { ISpan } from './SpanManager';
import { TSErrorResult } from './TSErrorResult';

export interface ISelectionIDs {
  nodes: { [id: string]: boolean };
  lines: { [id: string]: boolean };
}

export interface IInteraction {
  mode: TSMode;
  mouse: IVector;
  settings: {
    isSnap: boolean;
  };
  translation?: IRecRange;
  selection?: IRecRange;
  tempNodes?: INode[];
  selectedIDs: ISelectionIDs;
  faceNo?: number;
}

const defaultInteraction: IInteraction = {
  mode: TSMode.Select,
  mouse: { x: 0, y: 0 },
  settings: { isSnap: true },
  selectedIDs: { nodes: {}, lines: {} },
};

export interface CanvasProps {
  width: number;
  height: number;
  drawing: IDrawing;
  draft: IDraft;
  task: ITask;
  span: ISpan | null;
  isLocal: boolean;
}

const TSCanvas: React.FC<CanvasProps> = ({ width, height, drawing, draft, task, span, isLocal }) => {
  const [calculating, setCalculating] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [interaction, setInteraction] = useState<IInteraction>(defaultInteraction);

  useEffect(() => {
    window.addEventListener('keydown', onkeydown);

    return () => {
      window.removeEventListener('keydown', onkeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft, interaction.selectedIDs]);

  function setMode(m: TSMode) {
    setInteraction({ ...interaction, mode: m });
  }

  return (
    <Spin spinning={calculating} tip={t('CALCULATING')}>
      <section className='canvas-container' style={{ width: `${width}px`, height: `${height + 72}px` }}>
        <TSToolbar
          interaction={interaction}
          setInteraction={setInteraction}
          setCalculating={setCalculating}
          showResult={showResult}
          setShowResult={setShowResult}
          drawing={drawing}
          draft={draft}
          task={task}
          span={span}
          isLocal={isLocal}
        />
        <TSWorkspace
          width={width}
          height={height}
          drawing={drawing}
          showResult={showResult}
          interaction={interaction}
          setInteraction={setInteraction}
          draft={draft}
          task={task}
          span={span}
        />
        <TSLog draft={draft} interaction={interaction} height={height} zoom={drawing.zoom} />
        {draft.result && draft.result.isSuccess === false && showResult && <TSErrorResult result={draft.result} />}
      </section>
    </Spin>
  );

  async function onkeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      Escape();
    } else if (e.key === 'm') {
      setMode(TSMode.MoveStart);
    } else if (e.key === 'Delete') {
      if (interaction.mode !== TSMode.Select) return;
      await DraftManager.DeleteSelected(task, draft, interaction.selectedIDs);
    } else if (e.key === 'c') {
      setMode(TSMode.AddColumn);
    } else if (e.key === 'r') {
      setMode(TSMode.AddRectStart);
    } else if (e.key === 'p') {
      setMode(TSMode.AddPolyline);
    } else if (e.key === 'l') {
      setMode(TSMode.AddLineStart);
    } else if (e.key === 'z' && e.ctrlKey) {
      task.Undo(drawing._id);
    } else if (e.key === 'y' && e.ctrlKey) {
      task.Redo(drawing._id);
    }
  }

  function Escape() {
    setMode(TSMode.Select);
    // clear selection and move
    setInteraction({ ...interaction, selection: undefined, tempNodes: [], translation: undefined, selectedIDs: { nodes: {}, lines: {} } });
  }
};

export default TSCanvas;

import React, { useEffect } from 'react';
import './Projects.scss';
import { useContext } from '../../store';
import { API } from '../../store/api';
import { NewProjectCard } from './NewProjectCard';
import { ProjectCard } from './ProjectCard';
import { IProject } from '../../Data/project';
import { Layout } from 'antd';
import { Navbar } from '../Navbar';
import { ISandbox } from '../../store/state';

export const Projects: React.FC = () => {
  // get state
  const { state, task } = useContext();
  const [projects, setProjects] = React.useState<IProject[]>([]);
  const user = state.user;

  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadProjects() {
    await saveLocalProject();
    await getProjects();
  }

  async function getProjects() {
    const response = await API.getProjects();
    if (response.status === 200) {
      setProjects(response.data);
    }
  }

  async function saveLocalProject() {
    // get data from localhost, if yes, use it
    const localSandbox = localStorage.getItem('sandbox');
    if (localSandbox) {
      const sandbox = JSON.parse(localSandbox) as ISandbox;
      const response = await API.saveLocalProject(sandbox);
      if (response.status === 200) {
        localStorage.removeItem('sandbox');
      }
    }
  }

  return (
    <Layout>
      <Navbar project={null} user={user} task={task} />
      <section className='project-list'>
        {projects.map((project) => (
          <ProjectCard key={project._id} {...{ project, projects, setProjects, task }} />
        ))}
        <NewProjectCard task={task} />
      </section>
    </Layout>
  );
};

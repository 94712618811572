/*
  api that relates to this module
  that connects to the backend
*/

import axios from 'axios';
import { IDraft } from '../Data/draft';
import { IDrawing, IProject, ISignIn } from '../Data/project';
import { RFEMRequest } from '../Data/RFEM';
import { ISandbox, IUser } from './state';

const url = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5000/' : 'https://ts3-api.d2p.ch/';
axios.defaults.baseURL = url;

export interface INewProjectPayload {
  name: string;
  description?: string;
}

export interface IEditProjectPayload {
  name?: string;
  description?: string;
}

export interface INewDrawingPayload {
  projectID: string;
  name: string;
  currentDraftID?: string;
}

export const API = {
  //  ====================================GET REQUEST====================================
  getProject(projectShortID: string) {
    return axios.get(`/projects/${projectShortID}`);
  },

  getProjects() {
    return axios.get(`/projects`);
  },

  getDrawing(drawingID: string) {
    return axios.get(`/drawings/${drawingID}`);
  },

  getUser(id: string) {
    return axios.get(`/users/${id}`);
  },

  authenticate(signIn: ISignIn) {
    return axios.post(`/authenticate`, signIn);
  },

  forgetpassword(email: string) {
    return axios.post(`/forgetpassword`, { email });
  },

  validateToken(token: string) {
    return axios.post(`/validatetoken`, { token });
  },

  createUser(user: IUser) {
    return axios.post(`/users`, user);
  },

  editUser(user: IUser) {
    return axios.put(`/users/${user._id}`, user);
  },

  saveLocalProject(sandbox: ISandbox) {
    return axios.post(`/sandbox`, sandbox);
  },

  //  ====================================POST REQUEST====================================
  createProject(projectPayload: INewProjectPayload) {
    return axios.post(`/projects`, projectPayload);
  },

  createDrawing(newDrawing: INewDrawingPayload) {
    return axios.post(`/drawings`, newDrawing);
  },

  saveDraft(drawingID: string, draft: IDraft) {
    return axios.post(`/drawings/${drawingID}/drafts`, draft);
  },

  undo(drawingID: string) {
    return axios.patch(`/drawings/${drawingID}/undo`);
  },

  redo(drawingID: string) {
    return axios.patch(`/drawings/${drawingID}/redo`);
  },

  calculateDraft(draftID: string, requestBody: RFEMRequest) {
    return axios.put(`/drafts/${draftID}/calculation`, requestBody);
  },

  echoRFEMServer() {
    return axios.get(`/rfemserver`);
  },

  //  ====================================PUT REQUEST====================================
  updateProject(projectShortID: string, projectPayload: IProject) {
    return axios.put(`/projects/${projectShortID}`, projectPayload);
  },

  updateDrawing(drawingID: string, drawingPayload: IDrawing) {
    return axios.put(`/drawings/${drawingID}`, drawingPayload);
  },

  //  ====================================DELETE REQUEST====================================
  deleteProject(projectShortID: string) {
    return axios.delete(`/projects/${projectShortID}`);
  },

  deleteDrawing(drawingID: string) {
    return axios.delete(`/drawings/${drawingID}`);
  },
};

import React from 'react';
import { Line, Group, Text, Rect } from 'react-konva';
import { INode, IVector } from '../../Data/draft';
import { ConvertWorldCoordToCanvas } from './helper';
import { IColumnSpans, NodeDirection } from './SpanManager';
import { t } from '../../localization';

export interface ColumnSpanProps {
  column: INode;
  columnSpan: IColumnSpans;
  height: number;
  zoom: number;
  showResult: boolean;
}

export const TSColumnSpan: React.FC<ColumnSpanProps> = ({ columnSpan, column, height, zoom, showResult }) => {
  function getText() {
    if (!columnSpan.result) return t('NO_DATA');
    const xp = columnSpan.E && columnSpan.E.distance !== 0 ? columnSpan.E.distance.toFixed(1) + ' mm' : t('NONE');
    const xn = columnSpan.W && columnSpan.W.distance !== 0 ? columnSpan.W.distance.toFixed(1) + ' mm' : t('NONE');
    const yp = columnSpan.N && columnSpan.N.distance !== 0 ? columnSpan.N.distance.toFixed(1) + ' mm' : t('NONE');
    const yn = columnSpan.S && columnSpan.S.distance !== 0 ? columnSpan.S.distance.toFixed(1) + ' mm' : t('NONE');

    const lx = columnSpan.result.lx ? columnSpan.result.lx.toFixed(1) + ' mm' : t('NONE');
    const ly = columnSpan.result.ly ? columnSpan.result.ly.toFixed(1) + ' mm' : t('NONE');
    const area = columnSpan.result.area ? (columnSpan.result.area / 1000000).toFixed(2) + ' m²' : t('NONE');
    const ratio = columnSpan.result.ratio ? columnSpan.result.ratio.toFixed(2) : t('NONE');
    return `x+: ${xp}\nx-: ${xn}\ny+: ${yp}\ny-: ${yn}\n\nlx: ${lx}\nly: ${ly}\n${t('AREA')}: ${area}\n${t('STIFFNESS_RATIO_ABBR')}: ${ratio}`;
  }

  const columnInPixels = ConvertWorldCoordToCanvas(column, height, zoom);

  return (
    <Group>
      {Object.values(NodeDirection).map((dir) => {
        if (!columnSpan[dir]) return null;
        if (columnSpan[dir]!.distance === 0) return null;
        return (
          <PreviewSpanLineAndText
            key={dir}
            selected={true}
            distance={columnSpan[dir]!.distance}
            angle={columnSpan[dir]!.angle}
            start={column}
            end={columnSpan[dir]!.otherPos}
            region={columnSpan[dir]!.region}
            height={height}
            zoom={zoom}
            showResult={showResult}
          />
        );
      })}
      {columnSpan.result && !showResult && (
        <Group x={columnInPixels.x + 20} y={columnInPixels.y + 20}>
          <Rect width={130} height={165} opacity={0.7} fill={'white'} />
          <Text lineHeight={1.2} text={getText()} fill={'black'} fontSize={14} align='left' verticalAlign='top' padding={7}></Text>
        </Group>
      )}
    </Group>
  );
};

export interface PreviewSpanLineAndTextProps {
  selected?: boolean;
  distance: number;
  angle: number;
  start: IVector;
  end: IVector;
  height: number;
  zoom: number;
  region?: IVector[];
  emphasize?: boolean;
  showResult: boolean;
}

export const PreviewSpanLineAndText: React.FC<PreviewSpanLineAndTextProps> = ({
  distance,
  angle,
  start,
  end,
  selected,
  emphasize,
  height,
  zoom,
  region,
  showResult,
}) => {
  const startInPixels = ConvertWorldCoordToCanvas(start, height, zoom);
  const endInPixels = ConvertWorldCoordToCanvas(end, height, zoom);
  const regionInPixels = region && region.map((r) => ConvertWorldCoordToCanvas(r, height, zoom)).flatMap((r) => [r.x, r.y]);

  return (
    <Group preventDefault={true}>
      <Text
        text={distance.toFixed(0)}
        fill={selected ? 'blue' : '#d73a49'}
        x={(startInPixels.x + endInPixels.x) / 2}
        y={(startInPixels.y + endInPixels.y) / 2}
        fontSize={emphasize ? 18 : 16}
        rotation={-angle % 180}
        align='center'
        verticalAlign='center'
        padding={5}
      />
      <Line
        dash={[5, 5]}
        points={[startInPixels.x, startInPixels.y, endInPixels.x, endInPixels.y]}
        strokeWidth={emphasize ? 4 : 2}
        stroke={selected ? 'blue' : 'steelblue'}
      />
      {regionInPixels && (
        <Line
          points={regionInPixels}
          closed
          strokeWidth={emphasize ? 4 : 2}
          stroke={'purple'}
          opacity={0.3}
          fillEnabled={!showResult}
          fill={'#b964e3'}
        />
      )}
    </Group>
  );
};

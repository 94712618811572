import React from 'react';
import { Group, Line } from 'react-konva';
import { TSLine } from './TSLine';
import { IInteraction } from '.';
import { IDraft, IPolyline, IVector } from '../../Data/draft';
import { ConvertWorldCoordToCanvas } from './helper';

export interface PolylineProps {
  draft: IDraft;
  polyline: IPolyline;
  interaction: IInteraction;
  setInteraction: (i: IInteraction) => void;
  height: number;
  zoom: number;
}

const TSPolyline: React.FC<PolylineProps> = ({ polyline, interaction, draft, setInteraction, height, zoom }) => {
  return (
    <Group>
      <Line points={getPolylinePoints()} fill={polyline.isCutout ? '#e8e8e8' : 'grey'} closed={polyline.isClosed} />
      {polyline.lines.map((line) => (
        <TSLine
          key={line._id}
          line={line}
          polyline={polyline}
          draft={draft}
          interaction={interaction}
          setInteraction={setInteraction}
          height={height}
          zoom={zoom}
        />
      ))}
    </Group>
  );

  function getPolylinePoints(): number[] {
    var points: IVector[] = [];
    if (!polyline.isClosed) {
      var startPtID = polyline.lines[0].startID;
      var startPt = draft.nodes![startPtID];
      points.push({ x: startPt.x, y: startPt.y });
    }
    polyline.lines.forEach((line) => {
      const endPt = draft.nodes![line.endID];
      points.push({ x: endPt.x, y: endPt.y });
    });
    return points.map((p) => ConvertWorldCoordToCanvas(p, height, zoom)).flatMap((v) => [v.x, v.y]);
  }
};

export default TSPolyline;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Navbar.scss';
import { Menu, Modal, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { t } from '../../localization';

export const HelpDoc: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Menu.Item onClick={() => setVisible(true)} style={{ float: 'right' }} key='help'>
        <QuestionCircleOutlined /> {t('INSTRUCTION')}
      </Menu.Item>
      <Modal
        style={{ minWidth: '1100px', width: '90vw' }}
        title={t('INSTRUCTION')}
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <Typography.Title level={4}>Anleitung:</Typography.Title>
        <Typography.Paragraph>
          <p>
            <strong>Rastergrösse:</strong> Ist standardmässig auf 500 mm eingestellt und kann oben rechts geändert werden. Durch Zoom kann die
            gesamte, dargestellte Grösse eingestellt werden, dadurch kann die erwünschte Grösse für das Projekt justiert werden.
          </p>
          <p>
            <strong>Zeichnen von Flächen:</strong> Am besten beim Rasterpunkt x: 1000 mm, y: 1000 mm mit zeichnen beginnen. So können durch die
            Koordinaten unten links die Länge der einzelnen Seitenlängen bestimmt werden. Die Fläche kann entweder mittels der Funktion «Rechteck»
            oder der Funktion «Vieleck» erstellt werden. Aus einzelnen Linien können keine Flächen erzeugt werden.
          </p>
          <p>
            <strong>Zeichnen von Stützen:</strong> Können frei platziert werden.
          </p>
          <p>
            <strong>Zeichnen von Wänden:</strong> Wände werden zuerst als Linien gezeichnet und anschliessend durch anklicken und auswählen von «Wand»
            oben rechts zu einer Wand. Dies ist gekennzeichnet dadurch, dass die Linie eine graue Umrandung erhält.
          </p>
          <p>
            <strong>Spannweite berechnen:</strong> Vor der eigentlichen Berechnung müssen die Spannweiten berechnet werden. Dies wird gemacht, um eine
            Vorauswahl an möglichen Plattenaufbauten zu bestimmen. Durch Klicken auf die Stützen können die Spannweiten dann angezeigt werden. Dies
            ist auch ein guter Zeitpunkt für eine Plausibilitätskontrolle: Sind die Spannweiten richtig erkannt worden?
          </p>
          <p>
            Falls von einer Wand zu einer Stütze die Spannweite nicht richtig erkannt wird, kann neben der Wand eine Stütze eingefügt werden, um
            dieses Problem zu lösen. Werden nur Wände und keine Stützen gezeichnet, funktioniert die Spannweitenberechnung zurzeit noch nicht.
          </p>
          <p>
            <strong>Berechnung:</strong> Nachdem die Spannweiten berechnet und plausibilisiert wurden kann die eigentliche Berechnung gestartet
            werden. In diesem Fenster können die gewünschten Auflast und Nutzlast eingegeben werden. Es werden standardmässig 3 Aufbauten
            vorgeschlagen, welche sich nach dem dahinterliegenden Algorithmus am besten eignen. Weitere Aufbauten können durch Auswählen von
            Plattendicken in der Tabelle und durch Klicken auf die unten aufgeführten Aufbauten hinzugefügt werden.
          </p>
        </Typography.Paragraph>

        <Typography.Title level={4}>Ergebnisse:</Typography.Title>
        <Typography.Paragraph>
          <p>
            Ein einmal berechneter Entwurf, kann nicht mehr verändert werden, dafür muss eine Kopie des Entwurfes erstellt werden. Diese kann dann
            angepasst werden.
          </p>
          <p>
            Neben den verschiedenen Plattenaufbauten werden die minimalen Verhältnisse von Spannweite (L) zu Verformung (w) gezeigt. Das Verhältnis
            L/w soll grösser als 300, damit der Nachweis der Gebrauchstauglichkeit im Lastfall quasi-ständig erfüllt ist (wzul = L / 300). In der
            Grafik kann auf die entsprechenden Stellen geklickt werden, um sich die Verformung anzeigen zu lassen. Es kann zu Abweichenden
            L/w-Verhältnissen kommen, deshalb ist die kurze, manuelle Plausibilisierung wichtig.
          </p>
          <p>
            Im Berechnungsfenster können dann weitere Plattenaufbauten ausgewählt werden, falls man mit den bisherigen Ergebnissen nicht zufrieden
            ist. Ansonsten kann durch Klicken in der Tabelle die am besten geeignete Plattendicke ausgewählt werden und der Aufbau wird unterhalb der
            Tabelle angezeigt. Wichtig: Je nach Stützenraster wird die Richtung der Decklagen gedreht. Für eine erste Platteneinteilung ist es wichtig
            diese Orientierung der Decklagen zu berücksichtigen.
          </p>
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

import React from 'react';
import { IInteraction } from '.';
import { IDraft, TSMode } from '../../Data/draft';
import { t } from '../../localization';
import { ConvertCanvasToWorldCoord } from './helper';

export interface ILogProps {
  interaction: IInteraction;
  draft: IDraft;
  height: number;
  zoom: number;
}

const TSLog: React.FC<ILogProps> = ({ interaction, draft, height, zoom }) => {
  const mode = interaction.mode;
  const mouse = interaction.mouse;

  const mouseRealCoord = ConvertCanvasToWorldCoord(mouse, height, zoom);

  const message = getModeMessage();

  return (
    <section className='log-container'>
      <span>{`x: ${mouseRealCoord.x.toFixed(0)} mm, y: ${mouseRealCoord.y.toFixed(0)} mm | ${message}`}</span>
    </section>
  );

  function getModeMessage() {
    if (mode === TSMode.Select) return t('CLICK_OR_DRAW_A_WINDOW_TO_SELECT');
    if (mode === TSMode.AddColumn) return t('PLACE_A_COLUMN');
    if (mode === TSMode.AddPolyline) return t('PLACE_A_NODE_OF_THE_POLYLINE');
    if (mode === TSMode.MoveStart) return t('PICK_THE_START_POINT_TO_MOVE');
    if (mode === TSMode.MoveEnd) return t('PICK_THE_END_POINT');
    if (mode === TSMode.AddLineStart) return t('PICK_THE_START_POINT_OF_THE_LINE');
    if (mode === TSMode.AddLineEnd) return t('PICK_THE_END_POINT_OF_THE_LINE');
    if (mode === TSMode.AddRectStart) return t('PICK_THE_FIRST_CORNER_OF_THE_RECTANGLE');
    if (mode === TSMode.AddRectEnd) return t('PICK_THE_SECOND_CORNER_OF_THE_RECTANGLE');
  }
};

export default TSLog;

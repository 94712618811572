import React, { useEffect, useState } from 'react';
import './Draft.scss';
import TSCanvas from '../TSCanvas';
import { ITask } from '../../store/task';
import { IDrawing, IProject } from '../../Data/project';
import { IDraft } from '../../Data/draft';
import { ISpan } from '../TSCanvas/SpanManager';
import { IUser } from '../../store/state';

interface DraftProps {
  project: IProject;
  drawing: IDrawing;
  draft: IDraft;
  span: ISpan | null;
  user: IUser | null;
  task: ITask;
  isLocal: boolean;
}

export function Draft({ project, drawing, draft, task, span, user, isLocal }: DraftProps) {
  const [width, setWidth] = useState(1000);
  const [height, setHeight] = useState(600);

  useEffect(() => {
    const resize = () => {
      const padding = (window.innerHeight - 120) % (drawing.gridSize * drawing.zoom);
      setWidth(isLocal ? window.innerWidth - padding : window.innerWidth - 260 - padding);
      setHeight(window.innerHeight - 120 - padding);
    };
    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing.zoom]);

  return (
    <section className='project-container'>
      <TSCanvas width={width} height={height} drawing={drawing} draft={draft} task={task} span={span} isLocal={isLocal} />
    </section>
  );
}

import { Intersection, Line, Open3d, Transform, Vector3d } from 'open3d';
import { ISlabPreset, SlabPresets } from '../../assets/SlabPresets';
import { SpanPresets } from '../../assets/SpanPresets';
import { IDraft, ILine, INode, IVector, LineType } from '../../Data/draft';
import { ITask } from '../../store/task';
import { MakeRegionFromNodeAndLine, MakeRegionFromTwoNodes, ToIVector, ToOpen3dVector } from './helper';

export const MaxLength = 8 * 1000;

export enum SpanConnectionType {
  ColumnColumn = 'ColumnColumn',
  ColumnEdge = 'ColumnEdge',
  ColumnWall = 'ColumnWall',
}

export enum NodeDirection {
  N = 'N',
  W = 'W',
  S = 'S',
  E = 'E',
}

export const InverseDirections = {
  [NodeDirection.N]: NodeDirection.S,
  [NodeDirection.S]: NodeDirection.N,
  [NodeDirection.W]: NodeDirection.E,
  [NodeDirection.E]: NodeDirection.W,
};

export interface ISpan {
  columns: { [id: string]: IColumnSpans };
  maxSpan?: ISpanResult;
}

export interface ISpanResult {
  _id: string;
  lx?: number;
  ly?: number;
  xPos?: IVector;
  yPos?: IVector;
  area?: number;
  ratio?: number;
}

export interface IColumnSpan {
  type: SpanConnectionType;
  otherPartID: string;
  otherPos: IVector;
  distance: number;
  angle: number;
  region?: IVector[];
}

export interface IColumnSpans {
  _id: string;
  N?: IColumnSpan;
  S?: IColumnSpan;
  W?: IColumnSpan;
  E?: IColumnSpan;
  result?: ISpanResult;
}

// calculate column to column span and store the result
export function GetSpansOfColumnToColumn(draft: IDraft, span: ISpan, thresholdAngle: number) {
  const columns = Object.values(draft.nodes).filter((n) => n.isColumn);

  const length = columns.length;
  if (length <= 1) return;

  for (let i = 0; i < length - 1; i++) {
    for (let j = i + 1; j < length; j++) {
      const nodeA = columns[i];
      const nodeB = columns[j];
      const { distance, angle } = getDistAndAngleFrom2Points(nodeA, nodeB);
      if (distance === 0) continue;

      // compare with existing span
      const dir = getDirectionFromAngle(angle, thresholdAngle);
      if (!dir) continue;

      UpdateColumnColumnSpan(span, nodeA, nodeB, dir, angle, distance);
    }
  }
}

// update column to column span
export function UpdateColumnColumnSpan(span: ISpan, nodeA: INode, nodeB: INode, direction: NodeDirection, angle: number, distance: number) {
  const otherDirection = InverseDirections[direction];

  if (!span.columns[nodeA._id])
    span.columns[nodeA._id] = {
      _id: nodeA._id,
      [direction]: { angle, distance, otherPartID: nodeB._id, type: SpanConnectionType.ColumnColumn, otherPos: { x: nodeB.x, y: nodeB.y } },
    };
  else if (
    span.columns[nodeA._id][direction] === undefined ||
    (span.columns[nodeA._id][direction] &&
      (span.columns[nodeA._id][direction]!.type === SpanConnectionType.ColumnEdge || span.columns[nodeA._id][direction]!.distance > distance))
  )
    span.columns[nodeA._id] = {
      ...span.columns[nodeA._id],
      [direction]: { angle, distance, otherPartID: nodeB._id, type: SpanConnectionType.ColumnColumn, otherPos: { x: nodeB.x, y: nodeB.y } },
    };

  if (!span.columns[nodeB._id])
    span.columns[nodeB._id] = {
      _id: nodeB._id,
      [otherDirection]: {
        angle: (180 + angle) % 360,
        distance,
        otherPartID: nodeA._id,
        type: SpanConnectionType.ColumnColumn,
        otherPos: { x: nodeA.x, y: nodeA.y },
      },
    };
  else if (
    span.columns[nodeB._id][otherDirection] === undefined ||
    (span.columns[nodeB._id][otherDirection] &&
      (span.columns[nodeB._id][otherDirection]!.type === SpanConnectionType.ColumnEdge ||
        span.columns[nodeB._id][otherDirection]!.distance > distance))
  )
    span.columns[nodeB._id] = {
      ...span.columns[nodeB._id],
      [otherDirection]: {
        angle: (180 + angle) % 360,
        distance,
        otherPartID: nodeA._id,
        type: SpanConnectionType.ColumnColumn,
        otherPos: { x: nodeA.x, y: nodeA.y },
      },
    };
}

// calculate column to edge span and store the result
export function GetSpansOfColumnToEdgeOrWall(draft: IDraft, span: ISpan, thresholdAngle: number) {
  const columns = Object.values(draft.nodes).filter((n) => n.isColumn);
  const edges = draft.polylines.flatMap((p) => p.lines).filter((l) => l.type === LineType.Edge || l.type === LineType.Wall);

  // calculate distance and angle between column and edge
  for (let column of columns) {
    for (let edge of edges) {
      getClostestSpanToEdgeOnADirection(draft, span, column, edge, NodeDirection.S, thresholdAngle);
      getClostestSpanToEdgeOnADirection(draft, span, column, edge, NodeDirection.N, thresholdAngle);
      getClostestSpanToEdgeOnADirection(draft, span, column, edge, NodeDirection.W, thresholdAngle);
      getClostestSpanToEdgeOnADirection(draft, span, column, edge, NodeDirection.E, thresholdAngle);
    }
  }
}

export function getClostestSpanToEdgeOnADirection(
  draft: IDraft,
  span: ISpan,
  node: INode,
  edge: ILine,
  direction: NodeDirection,
  thresholdAngle: number
) {
  let line: Line;
  const lineLength = 20 * 1000;
  const nodePt = new Vector3d(node.x, node.y, 0);
  const edgeLine = new Line(ToOpen3dVector(draft.nodes[edge.startID]), ToOpen3dVector(draft.nodes[edge.endID]));
  const rotateLeft = Transform.Rotation((thresholdAngle / 360) * Math.PI, Vector3d.ZAxis, nodePt);
  const rotateRight = Transform.Rotation((-thresholdAngle / 360) * Math.PI, Vector3d.ZAxis, nodePt);

  if (direction === NodeDirection.N) line = new Line(nodePt, new Vector3d(node.x, node.y + lineLength, 0));
  else if (direction === NodeDirection.S) line = new Line(nodePt, new Vector3d(node.x, node.y - lineLength, 0));
  else if (direction === NodeDirection.E) line = new Line(nodePt, new Vector3d(node.x + lineLength, node.y, 0));
  else line = new Line(nodePt, new Vector3d(node.x - lineLength, node.y, 0));

  const leftLine = line.Transform(rotateLeft);
  const rightLine = line.Transform(rotateRight);

  const intersect1 = Intersection.LineLine(leftLine, edgeLine, true);
  const intersect2 = Intersection.LineLine(rightLine, edgeLine, true);

  let closestPt: IVector;
  // if bothsides are intersected, we need to make a new line from two intersection points
  if (intersect1 && intersect2) {
    const newline = new Line(intersect1, intersect2);
    if (!newline.IsValid) return;
    closestPt = ToIVector(newline.ClosestPoint(nodePt, true));
  }
  // if there is one intersection, we find the other endpoint that is in the region. make a new line from that point to intersection point
  else if (intersect1 || intersect2) {
    const pt1 = intersect1 ? intersect1 : intersect2!;
    let pt2: Vector3d = new Vector3d(0, 0, 0);
    if (isPointBetweenTwoLines(edgeLine.From, rightLine, leftLine)) pt2 = edgeLine.From;
    else if (isPointBetweenTwoLines(edgeLine.To, rightLine, leftLine)) pt2 = edgeLine.To;
    else throw new Error('cannot find the other point');

    const newline = new Line(pt1, pt2);
    closestPt = ToIVector(newline.ClosestPoint(nodePt, true));
  }
  // if there is no intersection, we check if start point is in the region. If yes, then take original edge. If no, continue.
  else {
    if (!isPointBetweenTwoLines(edgeLine.From, rightLine, leftLine)) return;
    closestPt = ToIVector(edgeLine.ClosestPoint(nodePt, true));
  }

  const { distance, angle } = getDistAndAngleFrom2Points(node, closestPt);
  if (distance === 0 || distance > MaxLength) return;

  if (edge.type === LineType.Edge) UpdateColumnEdgeSpan(span, node, edge, closestPt, direction, angle, distance);
  else if (edge.type === LineType.Wall) UpdateColumnWallSpan(span, node, edge, closestPt, direction, angle, distance);
}

// update column to edge span
export function UpdateColumnEdgeSpan(
  span: ISpan,
  node: INode,
  edge: ILine,
  edgePos: IVector,
  direction: NodeDirection,
  angle: number,
  distance: number
) {
  if (!span.columns[node._id])
    span.columns[node._id] = {
      _id: node._id,
      [direction]: { angle, distance, otherPartID: edge._id, type: SpanConnectionType.ColumnEdge, otherPos: edgePos },
    };
  else if (span.columns[node._id][direction] === undefined)
    span.columns[node._id] = {
      ...span.columns[node._id],
      [direction]: { angle, distance, otherPartID: edge._id, type: SpanConnectionType.ColumnEdge, otherPos: edgePos },
    };
  else if (
    span.columns[node._id][direction] &&
    span.columns[node._id][direction]!.type === SpanConnectionType.ColumnEdge &&
    span.columns[node._id][direction]!.distance > distance
  )
    span.columns[node._id] = {
      ...span.columns[node._id],
      [direction]: { angle, distance, otherPartID: edge._id, type: SpanConnectionType.ColumnEdge, otherPos: edgePos },
    };
}

// update column to wall span
export function UpdateColumnWallSpan(
  span: ISpan,
  node: INode,
  wall: ILine,
  wallPos: IVector,
  direction: NodeDirection,
  angle: number,
  distance: number
) {
  if (!span.columns[node._id])
    span.columns[node._id] = {
      _id: node._id,
      [direction]: { angle, distance, otherPartID: wall._id, type: SpanConnectionType.ColumnWall, otherPos: wallPos },
    };
  else if (span.columns[node._id][direction] === undefined)
    span.columns[node._id] = {
      ...span.columns[node._id],
      [direction]: { angle, distance, otherPartID: wall._id, type: SpanConnectionType.ColumnWall, otherPos: wallPos },
    };
  else if (
    span.columns[node._id][direction] &&
    (span.columns[node._id][direction]!.type === SpanConnectionType.ColumnEdge || span.columns[node._id][direction]!.distance > distance)
  )
    span.columns[node._id] = {
      ...span.columns[node._id],
      [direction]: { angle, distance, otherPartID: wall._id, type: SpanConnectionType.ColumnWall, otherPos: wallPos },
    };
}

export function isPointBetweenTwoLines(point: Vector3d, rightLine: Line, leftLine: Line) {
  const start = leftLine.From;
  const vec = point.Subtract(start);
  const isAtLeftofRightLine = rightLine.Direction.CrossProduct(vec).Z > 0;
  const isAtRightofLeftLine = leftLine.Direction.CrossProduct(vec).Z < 0;
  return isAtLeftofRightLine && isAtRightofLeftLine;
}

export function CalculateSpanRegions(draft: IDraft, span: ISpan, thresholdAngle: number) {
  for (let columnSpans of Object.values(span.columns)) {
    const node = draft.nodes[columnSpans._id];
    CalculateColumnSpanRegion(draft, node, thresholdAngle, columnSpans.W);
    CalculateColumnSpanRegion(draft, node, thresholdAngle, columnSpans.S);
    CalculateColumnSpanRegion(draft, node, thresholdAngle, columnSpans.E);
    CalculateColumnSpanRegion(draft, node, thresholdAngle, columnSpans.N);
  }
}

export function CalculateColumnSpanRegion(draft: IDraft, node: INode, thresholdAngle: number, columnSpan?: IColumnSpan) {
  if (!columnSpan) return;

  switch (columnSpan.type) {
    case SpanConnectionType.ColumnColumn:
      columnSpan.region = MakeRegionFromTwoNodes(node, columnSpan.otherPos, thresholdAngle);
      break;
    case SpanConnectionType.ColumnEdge:
    case SpanConnectionType.ColumnWall:
      const line = draft.polylines.flatMap((p) => p.lines).find((l) => l._id === columnSpan.otherPartID);
      if (!line) break;
      const lineAsNodes: [IVector, IVector] = [draft.nodes[line.startID], draft.nodes[line.endID]];
      columnSpan.region = MakeRegionFromNodeAndLine(node, columnSpan.otherPos, lineAsNodes, thresholdAngle);
  }
}

// calcu;ate the max span of column spans
export function CalculateMaxSpanAndRatio(draft: IDraft, span: ISpan) {
  for (let ctc of Object.values(span.columns)) {
    ctc.result = { _id: ctc._id };

    // get y-axis max ctc
    if (ctc.N && ctc.S) {
      if (ctc.N.distance > ctc.S.distance) {
        ctc.result.ly = ctc.N.distance;
        ctc.result.yPos = ctc.N.otherPos;
      } else {
        ctc.result.ly = ctc.S.distance;
        ctc.result.yPos = ctc.S.otherPos;
      }
    } else if (ctc.N) {
      ctc.result.ly = ctc.N.distance;
      ctc.result.yPos = ctc.N.otherPos;
    } else if (ctc.S) {
      ctc.result.ly = ctc.S.distance;
      ctc.result.yPos = ctc.S.otherPos;
    }

    // get x-axis max ctc
    if (ctc.W && ctc.E) {
      if (ctc.W.distance > ctc.E.distance) {
        ctc.result.lx = ctc.W.distance;
        ctc.result.xPos = ctc.W.otherPos;
      } else {
        ctc.result.lx = ctc.E.distance;
        ctc.result.xPos = ctc.E.otherPos;
      }
    } else if (ctc.W) {
      ctc.result.lx = ctc.W.distance;
      ctc.result.xPos = ctc.W.otherPos;
    } else if (ctc.E) {
      ctc.result.lx = ctc.E.distance;
      ctc.result.xPos = ctc.E.otherPos;
    }

    // get area
    if (ctc.result.ly && ctc.result.lx) {
      ctc.result.area = ctc.result.ly * ctc.result.lx;
      ctc.result.ratio = ctc.result.ly > ctc.result.lx ? Math.pow(ctc.result.ly / ctc.result.lx, 3.5) : Math.pow(ctc.result.lx / ctc.result.ly, 3.5);
    }
  }

  // get max area and its ratio
  let area = 0;
  let spanResult: IColumnSpans | undefined = undefined;
  for (let ctc of Object.values(span.columns)) {
    if (ctc.result && ctc.result.area && ctc.result.ratio && ctc.result.area > area) {
      area = ctc.result.area;
      spanResult = ctc;
    }
  }

  // set max span
  span.maxSpan = spanResult?.result;
}

// a helper function to calculate distance and angle between two points
export function getDistAndAngleFrom2Points(vec1: IVector, vec2: IVector) {
  const v1 = ToOpen3dVector(vec1);
  const v2 = ToOpen3dVector(vec2);
  const distance = v1.DistanceTo(v2);
  if (distance < Open3d.EPSILON) return { distance: 0, angle: 0 };
  const v = v2.Subtract(v1);
  // angle is from 0 to 360 counterclockwise
  let angle = (Vector3d.XAxis.VectorAngle(v) * 180) / Math.PI;

  if (Vector3d.XAxis.CrossProduct(v).Z < 0) angle = 360 - angle;

  return { distance, angle };
}

// check direction from 0-360 angle and distribute into four zones, the angle uses 30 degree at each direction
function getDirectionFromAngle(angle: number, thresholdAngle: number = 30) {
  const delta = thresholdAngle / 2;
  if (angle >= 90 - delta && angle <= 90 + delta) return NodeDirection.N;
  if (angle >= 180 - delta && angle <= 180 + delta) return NodeDirection.W;
  if (angle >= 270 - delta && angle <= 270 + delta) return NodeDirection.S;
  if (angle >= 360 - delta || angle <= delta) return NodeDirection.E;
  return null;
}

export const SpanManager = {
  /**
   *  calculate span
   **/
  CalculateSpan: async (task: ITask, currentDraft: IDraft, thresholdAngle: number) => {
    const span = { columns: {} };

    GetSpansOfColumnToColumn(currentDraft, span, thresholdAngle);
    GetSpansOfColumnToEdgeOrWall(currentDraft, span, thresholdAngle);
    CalculateSpanRegions(currentDraft, span, thresholdAngle);
    CalculateMaxSpanAndRatio(currentDraft, span);

    task.UpdateSpan(span);
  },

  FindBestNSlabs: (span: ISpan, liveLoad: number, thicknessWeight: number, stiffnessRatioWeight: number, countOfSlabs: number = 6) => {
    const maxSpan = span.maxSpan!;

    const area = maxSpan.area! / (1000 * 1000);
    const stiffnessRatio = maxSpan.ratio!;

    // find best thickness from span table
    const closestLoad = SpanPresets.sort((a, b) => Math.abs(a.load - liveLoad) - Math.abs(b.load - liveLoad))[0].load;

    const spansByArea = SpanPresets.filter((s) => s.load === closestLoad).sort((a, b) => Math.abs(a.area - area) - Math.abs(b.area - area));
    const bestThickness = spansByArea[0].thickness;

    // calculate scores according to thickness and stiffness ratio
    const pairs: [ISlabPreset, number][] = [];

    for (let slab of SlabPresets) {
      const score =
        Math.abs(slab.thickness - bestThickness) * thicknessWeight * 0.01 + Math.abs(slab.stiffnessRatio - stiffnessRatio) * stiffnessRatioWeight;
      pairs.push([slab, score]);
    }

    const sortedSlabPairs = pairs.sort((a, b) => a[1] - b[1]);

    const bestSlabPairs = sortedSlabPairs.slice(0, countOfSlabs);

    return { bestThickness, bestSlabPairs, stiffnessRatio };
  },
};

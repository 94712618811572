// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TS3: 'Timber Structures 3.0',
  NEW_PROJECT: 'New Project',
  MY_PROJECTS: 'My Projects',
  PROJECT_ID: 'Project ID',
  YOUR_EMAIL: 'Your Email',
  EMAIL_PLACEHOLDER: 'Your email...',
  UNTITLED: 'Untitled',
  PROJECT_NAME: 'Project Name',
  PROJECT_DESCRIPTION: 'Project Description',
  CREATOR: 'Creator',
  DRAWING_NAME: 'Draft Name',
  DRAFT_DATE: 'Drawn At',
  COPY_DRAFT: 'Copy Draft',
  CALCULATING: 'Calculating',
  // ============ messages =========
  PROVIDE_EMAIL_TO_LINK_PROJECT: 'Please provide your email address that this project belongs to.',
  PROVIDE_EMAIL_TO_GET_PROJECTS: 'Get all projects that belongs to this email.',
  EMAIL_CANNOT_BE_EMPTY: 'Email cannot be empty.',
  PROJECT_ID_CANNOT_BE_EMPTY: 'Project ID cannot be empty.',
  INFO_UPDATED: 'Information updated.',
  DELEVEOPED_BY_D2P: 'Developed by Design-to-Production',
  PROJECT_NOT_FOUND: 'Project not found.',
  DOUBLE_CHECK_TEXTID: 'Please check the project ID',
  DELETE_DRAFT_CONFIRM: 'Are you sure to delete this draft?',
  DRAFT_SAVED: 'Draft saved.',
  DRAFT_CALULATED: 'Draft calculated',
  DRAFT_CALCULATING_FAILED: 'Failed to calculate draft',
  RESULTS_CLEARED: 'Results cleared.',
  VERSION_NUMBER: 'Version',
  VERSION_DESCRIPTION: 'Version Info',
  CURRENT: 'Current',
  // ============ controls =========
  OPEN: 'Open',
  YES: 'Yes',
  NO: 'No',
};

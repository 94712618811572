/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Menu, Modal, Tabs } from 'antd';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

import { ITask } from '../../../store/task';
import { UserProfile } from './UserProfile';
import { ChangePassword } from './ChangePassword';
import { UserPreference } from './UserPreference';
import { IUser } from '../../../store/state';
import { t } from '../../../localization';
import { SignIn } from '../../LoginPage/SignIn';
import { SignUp } from '../../LoginPage/SignUp';

const { SubMenu } = Menu;

export const UserMenu: React.FC<{ user: IUser | null; task: ITask }> = ({ user, task }) => {
  const [visible, setVisible] = React.useState(false);

  if (user) {
    return (
      <SubMenu
        key='user-menu'
        style={{ float: 'right' }}
        title={
          <span>
            {user.firstname} {user.lastname}
          </span>
        }
      >
        <UserPreference user={user} task={task} />
        <UserProfile user={user} task={task} />
        <ChangePassword user={user} task={task} />

        <Menu.Item onClick={task.Logout} key={'logout'}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <LogoutOutlined style={{ width: '10px' }} />
            <span>{t('LOGOUT')}</span>
          </span>
        </Menu.Item>
      </SubMenu>
    );
  }
  return (
    <>
      <Menu.Item onClick={() => setVisible(true)} style={{ float: 'right' }} key={'login'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <LoginOutlined style={{ width: '10px' }} />
          <span>{t('LOGIN')}</span>
        </span>
      </Menu.Item>
      <SignInModal {...{ task, visible, setVisible }} />
    </>
  );
};

export const SignInModal: React.FC<{ task: ITask; visible: boolean; setVisible: (v: boolean) => void }> = ({ task, visible, setVisible }) => {
  return (
    <Modal footer={null} visible={visible} closable onCancel={() => setVisible(false)}>
      <Tabs className='login-card' defaultActiveKey='1'>
        <Tabs.TabPane tab={t('LOGIN')} key='1'>
          <SignIn task={task} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('SIGNUP')} key='2'>
          <SignUp task={task} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

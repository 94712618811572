import { message } from 'antd';
import { Intersection, Line, Transform, Vector3d } from 'open3d';
import { IInteraction, ISelectionIDs } from '.';
import { IDraft, ILine, INode, IPolyline, IRecRange, IVector, LineType, RFEMNode } from '../../Data/draft';
import { t } from '../../localization';
import { v4 as guid } from 'uuid';
import { IColumnSpan, ISpan, isPointBetweenTwoLines, MaxLength } from './SpanManager';
import { IFaceInteractionData } from './TSResultLayer';

/**
 * Helper function to create a GUID
 */
export function CreateGUID(): string {
  return guid();
}

export function GenerateShortID(): string {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  for (let i = 0; i < 6; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function validateEmail(email: string): string | undefined {
  const RE_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email) {
    return t('EMAIL_SHOULDNOT_BE_EMPTY');
  } else if (!RE_EMAIL.test(email)) {
    return t('INVALID_EMAIL_ADDRESS');
  }
}

export function validatePassword(password: string): string | undefined {
  const RE_PASS_ONE_CAPITALIZED = new RegExp('.*[A-Z].*');
  const RE_PASS_ONE_DIGIT = new RegExp('.*[0-9].*');
  const RE_PASS_LENGTH = new RegExp('.{8}');
  if (!password.match(RE_PASS_LENGTH)) {
    return t('PASSWORD_SHOULD_HAVE_LENGTH_8');
  } else if (!password.match(RE_PASS_ONE_CAPITALIZED)) {
    return t('PASSWORD_SHOULD_CONTAIN_ONE_CAPITALIZED');
  } else if (!password.match(RE_PASS_ONE_DIGIT)) {
    return t('PASSWORD_SHOULD_CONTAIN_ONE_DIGIT');
  }
}

export function ConvertPixelToMeter(pixel: number, zoom: number): number {
  return pixel / zoom;
}

export function ConvertMeterToPixel(meter: number, zoom: number): number {
  return meter * zoom;
}

export function ConvertCanvasToWorldCoord(canvas: IVector, height: number, zoom: number): IVector {
  return {
    x: ConvertPixelToMeter(canvas.x, zoom),
    y: ConvertPixelToMeter(height - canvas.y, zoom),
  };
}

export function ConvertWorldCoordToCanvas(world: IVector, height: number, zoom: number): IVector {
  return {
    x: ConvertMeterToPixel(world.x, zoom),
    y: height - ConvertMeterToPixel(world.y, zoom),
  };
}

export function CreateNodeFromPoint(point: IVector, isColumn?: boolean, lineID?: string): INode {
  return { _id: CreateGUID(), x: point.x, y: point.y, isColumn, lineID } as INode;
}

export function CreateLineFromNodes(start: INode, end: INode, type: LineType = LineType.Edge, columnsCount?: number): ILine {
  return { _id: CreateGUID(), startID: start._id, endID: end._id, type, columnsCount } as ILine;
}

export function CreatePolylineFromLines(lines: ILine[], isClosed?: boolean, isCutout?: boolean): IPolyline {
  return { _id: CreateGUID(), lines, isClosed, isCutout } as IPolyline;
}

export function NodeInSelection(node: INode, selection: IRecRange): boolean {
  let nodeX = node.x;
  let nodeY = node.y;

  let xRange = [selection.p1.x, selection.p2.x].sort((a, b) => a - b);
  let yRange = [selection.p1.y, selection.p2.y].sort((a, b) => a - b);

  return nodeX >= xRange[0] && nodeX <= xRange[1] && nodeY >= yRange[0] && nodeY <= yRange[1];
}

export function ToOpen3dVector(vector: IVector): Vector3d {
  return new Vector3d(vector.x, vector.y, 0);
}

export function ToIVector(vector: Vector3d): IVector {
  return { x: vector.X, y: vector.Y };
}

export function NodeInNodes(node: INode, nodes: INode[]): boolean {
  for (let n of nodes) {
    if (EqualNode(node, n)) return true;
  }
  return false;
}

export function PointInPoints(pt: IVector, points: IVector[]): boolean {
  for (let p of points) {
    if (EqualPoint(pt, p)) return true;
  }
  return false;
}

export function EqualNode(node1: INode, node2: INode): boolean {
  return node1.x === node2.x && node1.y === node2.y;
}

export function EqualPoint(pt1: IVector, pt2: IVector) {
  return pt1.x === pt2.x && pt1.y === pt2.y;
}

export function CreatePolylineFromNodes(nodes: INode[], isClosed?: boolean, isCutout?: boolean): IPolyline {
  var lines: ILine[] = [];
  for (let i = 0; i < nodes.length - 1; i++) {
    let line = CreateLineFromNodes(nodes[i], nodes[i + 1]);
    lines.push(line);
  }
  if (isClosed) {
    let line = CreateLineFromNodes(nodes[nodes.length - 1], nodes[0]);
    lines.push(line);
  }
  let polyline = CreatePolylineFromLines(lines, isClosed, isCutout);
  return polyline;
}

export function LineInSelection(nodeStart: INode, nodeEnd: INode, selection: IRecRange): boolean {
  let vx = nodeEnd.x - nodeStart.x;
  let vy = nodeEnd.y - nodeStart.y;
  let xRange = [selection.p1.x, selection.p2.x].sort((a, b) => a - b);
  let yRange = [selection.p1.y, selection.p2.y].sort((a, b) => a - b);
  let left = xRange[0];
  let right = xRange[1];
  let top = yRange[0];
  let bottom = yRange[1];
  let x = nodeStart.x;
  let y = nodeStart.y;

  var p = [-vx, vx, -vy, vy];
  var q = [x - left, right - x, y - top, bottom - y];
  var u1 = Number.NEGATIVE_INFINITY;
  var u2 = Number.POSITIVE_INFINITY;

  // if one end of the line is in the rectangle, return true
  if (NodeInSelection(nodeStart, selection)) return true;
  if (NodeInSelection(nodeEnd, selection)) return true;

  for (let i of [0, 1, 2, 3]) {
    if (p[i] === 0) {
      if (q[i] < 0) return false;
    } else {
      var t = q[i] / p[i];
      if (p[i] < 0 && u1 < t) u1 = t;
      else if (p[i] > 0 && u2 > t) u2 = t;
    }
  }

  if (u1 > u2 || u1 > 1 || u1 < 0) return false;

  // collision.x = x + u1*vx;
  // collision.y = y + u1*vy;

  return true;
}

export function NodeDistance(node1: INode, node2: INode) {
  return Math.sqrt(Math.pow(node1.x - node2.x, 2) + Math.pow(node1.y - node2.y, 2));
}

export function Distance(position1: IVector, position2: IVector) {
  return Math.sqrt(Math.pow(position1.x - position2.x, 2) + Math.pow(position1.y - position2.y, 2));
}

export function FindClosestNode(node: INode, columns: INode[]) {
  if (columns.length === 0) return undefined;
  return columns.sort((a, b) => NodeDistance(node, a) - NodeDistance(node, b))[0];
}

export function PolygonArea(nodes: { [id: string]: INode }, polygon: IPolyline) {
  const vertices = GetPointsFromPolyline(nodes, polygon);

  var total = 0;

  for (var i = 0, l = vertices.length; i < l; i++) {
    var addX = vertices[i].x;
    var addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
    var subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
    var subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}

export function GetPointsFromPolyline(nodes: { [id: string]: INode }, polyline: IPolyline): IVector[] {
  var points: IVector[] = [];
  polyline.lines.forEach((line) => points.push(nodes[line.startID]));
  if (!polyline.isClosed) points.push(nodes[polyline.lines[polyline.lines!.length - 1].endID]);
  return points;
}

export function PolygonInPolygon(nodes: { [id: string]: INode }, polygon1: IPolyline, polygon2: IPolyline) {
  const points1 = GetPointsFromPolyline(nodes, polygon1);
  const points2 = GetPointsFromPolyline(nodes, polygon2);
  for (let p of points1) {
    if (!PointInsidePolygon(p, points2)) return false;
  }
  return true;
}

export function PointInsidePolygon(point: IVector, polygon: IVector[]): boolean {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var x = point.x,
    y = point.y;

  var inside = false;
  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = polygon[i].x,
      yi = polygon[i].y;
    var xj = polygon[j].x,
      yj = polygon[j].y;

    // eslint-disable-next-line no-mixed-operators
    var intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function SetPolylineOpenings(draft: IDraft) {
  // sort polylines from big to small
  const newPolylines = draft.polylines
    .sort((a, b) => PolygonArea(draft.nodes, b) - PolygonArea(draft.nodes, a))
    .map((b) => ({ ...b, isCutout: false }));
  const count = newPolylines.length;
  if (count < 2) return newPolylines;
  for (let i = 0; i < count - 1; i++) {
    for (let j = i + 1; j < count; j++) {
      let bigPolyline = newPolylines[i];
      let smallPolyline = newPolylines[j];
      if (bigPolyline.isCutout || smallPolyline.isCutout) continue;
      if (PolygonInPolygon(draft.nodes, smallPolyline, bigPolyline)) smallPolyline.isCutout = true;
    }
  }
  draft.polylines = newPolylines;
}

export function getNodeAndLineIDsFromSelection(draft: IDraft, selection: IRecRange) {
  const selectedNodeIDs: string[] = [];
  const selectedLineIDs: string[] = [];
  // check if node is in the selection
  for (let node of Object.values(draft.nodes)) {
    if (NodeInSelection(node, selection)) selectedNodeIDs.push(node._id);
  }

  // check if line is in the selection
  for (let polyline of draft.polylines) {
    for (let line of polyline.lines) {
      const start = draft.nodes[line.startID];
      const end = draft.nodes[line.endID];
      if (LineInSelection(start, end, selection)) {
        selectedLineIDs.push(line._id);
      }
    }
  }

  return { selectedLineIDs, selectedNodeIDs };
}

export function IsLineSelected(interaction: IInteraction, line: ILine) {
  return interaction.selectedIDs.lines[line._id];
}

export function IsNodeSelected(interaction: IInteraction, node: INode) {
  return interaction.selectedIDs.nodes[node._id];
}

export function DeleteNodeFromDraft(objectsToProcess: IDeleteObjects, draft: IDraft, lines: ILine[], nodeID: string) {
  if (!draft.nodes[nodeID]) return;

  function deleteNodeWithLine(l: ILine, node_id: string) {
    const otherNodeID = l.startID === node_id ? l.endID : l.startID;

    // check how many lines are related to the other node
    const otherNodeRelatedLines = lines.filter((l) => l.startID === otherNodeID || l.endID === otherNodeID);

    // if the length is 1, then we can delete the line and the node
    if (otherNodeRelatedLines.length === 1) {
      objectsToProcess.deleteNodes.push(node_id);
      objectsToProcess.deleteNodes.push(otherNodeID);
      objectsToProcess.deleteLines.push(otherNodeRelatedLines[0]._id);
    }
    // if the length is 2, we just delete the original node, and remove the related line from the polyline
    else if (otherNodeRelatedLines.length === 2) {
      objectsToProcess.deleteNodes.push(node_id);
      objectsToProcess.deleteLines.push(l._id);
    }
    // if it's more than 2, we throw an error
    else if (otherNodeRelatedLines.length > 2) {
      message.error(t('MORE_THAN_TWO_LINES_CONNECTED_TO_THE_NODE'));
    }
  }

  // check if there're lines connected to the node
  const connectedLines = lines.filter((l) => l.startID === nodeID || l.endID === nodeID);

  // if there is no related lines, we just delete the node
  if (connectedLines.length === 0) {
    objectsToProcess.deleteNodes.push(nodeID);
  } else {
    for (let line of connectedLines) {
      deleteNodeWithLine(line, nodeID);
    }
  }
}

interface IDeleteObjects {
  deleteNodes: string[];
  deleteLines: string[];
  deletePolylines: string[];
  cutPolylines: string[];
}

export function DeleteSelectedFromDraft(draft: IDraft, selectedIDs: ISelectionIDs) {
  const nodeIDs = Object.keys(selectedIDs.nodes).filter((i) => selectedIDs.nodes[i]);
  const lineIDs = Object.keys(selectedIDs.lines).filter((i) => selectedIDs.lines[i]);

  const objectsToProcess: IDeleteObjects = {
    deleteNodes: [],
    deleteLines: [],
    deletePolylines: [],
    cutPolylines: [],
  };

  // get lines from polylines
  let lines: ILine[] = draft.polylines.flatMap((b) => b.lines);

  // generate objects to delete
  for (let id of lineIDs) DeleteLineFromDraft(objectsToProcess, lines, id);
  for (let id of nodeIDs) DeleteNodeFromDraft(objectsToProcess, draft, lines, id);
  ProcessDeletePolylines(objectsToProcess, draft);
  for (let lineID of objectsToProcess.deleteLines) DeleteLineColumns(objectsToProcess, lines, lineID);

  // delete nodes
  for (let nodeID of objectsToProcess.deleteNodes) delete draft.nodes[nodeID];
  // delete polylines
  for (let polylineID of objectsToProcess.deletePolylines) {
    const index = draft.polylines.findIndex((b) => b._id === polylineID);
    if (index > -1) draft.polylines.splice(index, 1);
  }
  // set closed polylines
  for (let polylineID of objectsToProcess.cutPolylines) {
    const index = draft.polylines.findIndex((b) => b._id === polylineID);
    if (index > -1) draft.polylines[index].isClosed = false;
  }
  // delete lines
  for (let lineID of objectsToProcess.deleteLines) {
    for (let polyline of draft.polylines) {
      const index = polyline.lines.findIndex((l) => l._id === lineID);
      if (index > -1) polyline.lines.splice(index, 1);
    }
  }
}

function ProcessDeletePolylines(objectsToProcess: IDeleteObjects, draft: IDraft) {
  // go through the lines and delete the polylines that are empty and set the closed flag
  for (let polyline of draft.polylines) {
    if (polyline.lines.every((l) => objectsToProcess.deleteLines.includes(l._id))) {
      objectsToProcess.deletePolylines.push(polyline._id);
    }
    if (polyline.lines.some((l) => objectsToProcess.deleteLines.includes(l._id))) {
      objectsToProcess.cutPolylines.push(polyline._id);
    }
  }
}

export function DeleteLineFromDraft(objectsToProcess: IDeleteObjects, lines: ILine[], lineID: string) {
  objectsToProcess.deleteLines.push(lineID);
  const line = lines.find((l) => l._id === lineID);
  if (!line) return;

  function deleteNode(node_id: string) {
    // check how many lines are related to the other node
    const nodeRelatedLines = lines.filter((l) => l.startID === node_id || l.endID === node_id);
    // if the length is 1, then we can delete the node
    if (nodeRelatedLines.length === 1) {
      objectsToProcess.deleteNodes.push(node_id);
    }
  }

  deleteNode(line.startID);
  deleteNode(line.endID);
}

export function DeleteLineColumns(objectsToProcess: IDeleteObjects, lines: ILine[], lineID: string) {
  const line = lines.find((l) => l._id === lineID);
  if (!line || line.type !== LineType.Columns || line.columnIDs === undefined) return;
  for (let nodeID of line.columnIDs) objectsToProcess.deleteNodes.push(nodeID);
}

export function CreateNodesFromLine(draft: IDraft, line: ILine, newCount: number) {
  const start = draft.nodes[line.startID];
  const end = draft.nodes[line.endID];

  let newNodes: INode[] = [];
  let startX = start.x;
  let startY = start.y;
  let endX = end.x;
  let endY = end.y;
  let division = newCount;

  let deltaX = (endX - startX) / (division - 1);
  let deltaY = (endY - startY) / (division - 1);

  for (let i = 0; i < division; i++) {
    let x = startX + deltaX * i;
    let y = startY + deltaY * i;
    const newNode = CreateNodeFromPoint({ x, y }, true, line._id);
    draft.nodes[newNode._id] = newNode;
    newNodes.push(newNode);
  }

  return newNodes;
}

export function MakeRegionFromTwoNodes(p1: IVector, p2: IVector, angle: number): IVector[] {
  // convert ivector to vector
  const v1 = ToOpen3dVector(p1);
  const v2 = ToOpen3dVector(p2);

  function makeTwoLinesFromStartAndEnd(start: Vector3d, end: Vector3d) {
    const line = new Line(v1, v2);
    const rotateLeft = Transform.Rotation((angle / 360) * Math.PI, Vector3d.ZAxis, start);
    const rotateRight = Transform.Rotation((-angle / 360) * Math.PI, Vector3d.ZAxis, start);
    const leftLine = line.Transform(rotateLeft);
    const rightLine = line.Transform(rotateRight);
    return [leftLine, rightLine];
  }

  // get four lines
  const [leftLine1, rightLine1] = makeTwoLinesFromStartAndEnd(v1, v2);
  const [leftLine2, rightLine2] = makeTwoLinesFromStartAndEnd(v2, v1);

  // find intersection between leftLine1 and rightLine2
  const intersect1 = Intersection.LineLine(leftLine1, rightLine2, true);
  const intersect2 = Intersection.LineLine(rightLine1, leftLine2, true);

  if (!intersect1 || !intersect2) return [];

  return [v1, intersect1, v2, intersect2].map(ToIVector);
}

export function MakeRegionFromNodeAndLine(p1: IVector, p2: IVector, edge: [IVector, IVector], angle: number): IVector[] {
  // get intersections from the current lines to the other line
  const nodePt = ToOpen3dVector(p1);
  const edgeLine = new Line(ToOpen3dVector(edge[0]), ToOpen3dVector(edge[1]));
  const rotateLeft = Transform.Rotation((angle / 360) * Math.PI, Vector3d.ZAxis, nodePt);
  const rotateRight = Transform.Rotation((-angle / 360) * Math.PI, Vector3d.ZAxis, nodePt);

  let line = new Line(nodePt, ToOpen3dVector(p2));
  line.Length = MaxLength;

  const leftLine = line.Transform(rotateLeft);
  const rightLine = line.Transform(rotateRight);

  const intersect1 = Intersection.LineLine(leftLine, edgeLine, true);
  const intersect2 = Intersection.LineLine(rightLine, edgeLine, true);

  let newRegion: IVector[] = [];
  // if bothsides are intersected, we need to make a new line from two intersection points
  if (intersect1 && intersect2) {
    const newline = new Line(intersect1, intersect2);
    if (!newline.IsValid) return [];
    newRegion = [nodePt, intersect1, intersect2].map(ToIVector);
  }
  // if there is one intersection, we find the other endpoint that is in the region. make a new line from that point to intersection point
  else if (intersect1 || intersect2) {
    const pt1 = intersect1 ? intersect1 : intersect2!;
    let pt2: Vector3d = new Vector3d(0, 0, 0);
    if (isPointBetweenTwoLines(edgeLine.From, rightLine, leftLine)) pt2 = edgeLine.From;
    else if (isPointBetweenTwoLines(edgeLine.To, rightLine, leftLine)) pt2 = edgeLine.To;
    else throw new Error('cannot find the other point');

    newRegion = [nodePt, pt1, pt2].map(ToIVector);
  }
  // if there is no intersection, we check if start point is in the region. If yes, then take original edge. If no, continue.
  else {
    if (!isPointBetweenTwoLines(edgeLine.From, rightLine, leftLine)) return [];
    newRegion = [nodePt, edgeLine.From, edgeLine.To].map(ToIVector);
  }

  if (newRegion.length === 0) return [];

  return newRegion;
}

export function FindMaxDisplacementRegion(span: ISpan, node: RFEMNode): IFaceInteractionData {
  const point = { x: node.Coordinates.X * 1000, y: node.Coordinates.Y * 1000 };
  const displacement = node.TotalDisplacement * 1000;
  let ratio = Infinity;
  let spanDistance = 0;
  let columnID = undefined;
  let type = undefined;

  function getDisplacementOfSpan(column_id: string, point: IVector, columnSpan?: IColumnSpan) {
    if (!columnSpan || !columnSpan.region) return null;
    if (!PointInsidePolygon(point, columnSpan.region)) return null;
    const calRatio = columnSpan.distance / displacement;
    if (calRatio < ratio) {
      ratio = calRatio;
      spanDistance = columnSpan.distance;
      columnID = column_id;
      type = columnSpan.type;
    }
  }

  for (let columnSpans of Object.values(span.columns)) {
    getDisplacementOfSpan(columnSpans._id, point, columnSpans.W);
    getDisplacementOfSpan(columnSpans._id, point, columnSpans.S);
    getDisplacementOfSpan(columnSpans._id, point, columnSpans.E);
    getDisplacementOfSpan(columnSpans._id, point, columnSpans.N);
  }

  return {
    location: point,
    displacement,
    ratio,
    columnID,
    span: spanDistance,
    type,
  };
}

import { FlagFranceIcon } from '../../assets/Icons/FlagFrance';
import { FlagGermanyIcon } from '../../assets/Icons/FlagGermany';
import { FlagUKIcon } from '../../assets/Icons/FlagUK';
import { LanguageEnum } from '../../Data/project';

export const LanguageFlag = ({ language }: { language: LanguageEnum }) => {
  switch (language) {
    case LanguageEnum.English:
      return <FlagUKIcon />;
    case LanguageEnum.German:
      return <FlagGermanyIcon />;
    case LanguageEnum.French:
      return <FlagFranceIcon />;
    default:
      return <FlagUKIcon />;
  }
};

const LanguageText = (language: LanguageEnum) => {
  switch (language) {
    case LanguageEnum.English:
      return 'English';
    case LanguageEnum.German:
      return 'Deutsch';
    case LanguageEnum.French:
      return 'Francais';
    default:
      return 'English';
  }
};

export const LanguageItem = ({ language }: { language: LanguageEnum }) => (
  <span style={{ display: 'inline-flex', alignItems: 'center', gap: '0.6em' }}>
    <LanguageFlag language={language} /> {LanguageText(language)}
  </span>
);

import React, { useState, useEffect } from 'react';
import { Form, Button, message, Input, Alert } from 'antd';
import { ITask } from '../../store/task';
import { IUser } from '../../store/state';
import { LanguageEnum } from '../../Data/project';
import { t } from '../../localization';
import { validateEmail, validatePassword } from '../TSCanvas/helper';

const edituser: IUser = {
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  language: LanguageEnum.English,
};

export const SignUp: React.FC<{ task: ITask }> = ({ task }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(edituser);
  const [error, setError] = useState('');
  const lang = (window.localStorage.getItem('language') as LanguageEnum) || LanguageEnum.English;

  useEffect(() => {
    setUser({ ...user, language: lang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const passwordTooltip = (
    <ul style={{ width: 'max-content' }}>
      <li>{t('PASSWORD_SHOULD_HAVE_LENGTH_8')}</li>
      <li>{t('PASSWORD_SHOULD_CONTAIN_ONE_CAPITALIZED')}</li>
      <li>{t('PASSWORD_SHOULD_CONTAIN_ONE_DIGIT')}</li>
    </ul>
  );

  return (
    <Form className='login-form' name='SignUpForm' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={createUser}>
      <Form.Item required label={t('EMAIL')}>
        <Input value={user.email} placeholder={t('PLEASE_INPUT_YOUR_EMAIL')} onChange={(e) => setUser({ ...user, email: e.target.value })} />
      </Form.Item>

      <Form.Item tooltip={passwordTooltip} required label={t('PASSWORD')}>
        <Input.Password
          value={user.password}
          placeholder={t('PLEASE_INPUT_YOUR_PASSWORD')}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
      </Form.Item>

      <Form.Item required label={t('CONFIRM_PASSWORD')}>
        <Input.Password
          value={user.confirmPassword}
          placeholder={t('PLEASE_INPUT_YOUR_CONFIRM_PASSWORD')}
          onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
        />
      </Form.Item>

      <Form.Item required label={t('FIRSTNAME')}>
        <Input
          value={user.firstname}
          placeholder={t('PLEASE_INPUT_YOUR_FIRSTNAME')}
          onChange={(e) => setUser({ ...user, firstname: e.target.value })}
        />
      </Form.Item>

      <Form.Item required label={t('LASTNAME')}>
        <Input value={user.lastname} placeholder={t('PLEASE_INPUT_YOUR_LASTNAME')} onChange={(e) => setUser({ ...user, lastname: e.target.value })} />
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={error} type='error' />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          {t('SIGNUP')}
        </Button>
      </Form.Item>
    </Form>
  );

  function invalidate() {
    let error = validateEmail(user.email);
    if (error) {
      setError(error);
      return false;
    }

    error = validatePassword(user.password);
    if (error) {
      setError(error);
      return false;
    }

    // confirm password
    if (user.password !== user.confirmPassword) {
      setError(t('PASSWORD_NOT_MATCH'));
      return false;
    }

    // check names
    if (!user.firstname.trim() || !user.lastname.trim()) {
      setError(t('PLEASE_INPUT_YOUR_FIRSTNAME_AND_LASTNAME'));
      return false;
    }

    return true;
  }

  async function createUser() {
    if (!invalidate()) return;

    setLoading(true);
    const response = await task.CreateUser(user);
    setLoading(false);
    if (!response.success) {
      message.error(response.message);
    }
  }
};

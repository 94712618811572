import React from 'react';
import { Layer, Circle, Rect, Group, Line } from 'react-konva';
import { IInteraction } from '.';
import { TSMode } from '../../Data/draft';
import { ConvertWorldCoordToCanvas } from './helper';

export interface GhostLayerProps {
  interaction: IInteraction;
  height: number;
  zoom: number;
}

// the ghost layer has a pointer showing where the regulated are
export const TSGhostLayer: React.FC<GhostLayerProps> = ({ interaction, height, zoom }) => {
  const { mode, mouse, selection, tempNodes, translation } = interaction;

  return (
    <Layer name='ghostlayer'>
      {mode === TSMode.Select && selection && (
        <Rect
          stroke='black'
          strokeWidth={1}
          fill='#fff8b9'
          opacity={0.3}
          x={selection.p1.x}
          y={selection.p1.y}
          width={selection.p2.x - selection.p1.x}
          height={selection.p2.y - selection.p1.y}
        />
      )}
      {mode === TSMode.AddColumn && ColumnGhost()}
      {mode === TSMode.AddPolyline && PolylineGhost()}
      {mode === TSMode.MoveStart && MoveStartGhost()}
      {mode === TSMode.MoveEnd && MoveEndGhost()}
      {mode === TSMode.AddLineStart && LineStartGhost()}
      {mode === TSMode.AddLineEnd && LineEndGhost()}
      {mode === TSMode.AddRectStart && RectStartGhost()}
      {mode === TSMode.AddRectEnd && RectEndGhost()}
    </Layer>
  );

  function ColumnGhost() {
    return <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />;
  }

  function MoveStartGhost() {
    return <Circle radius={5} x={mouse.x} y={mouse.y} fill='red' opacity={0.6} />;
  }

  function LineStartGhost() {
    return (
      <Group>
        <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function RectStartGhost() {
    return (
      <Group>
        <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function LineEndGhost() {
    let dashedPoints: number[] = [];
    if (!tempNodes) return null;
    const nodeInPixel = ConvertWorldCoordToCanvas(tempNodes[0], height, zoom);
    dashedPoints = [nodeInPixel.x, nodeInPixel.y, mouse.x, mouse.y];

    return (
      <Group>
        <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} closed />
        <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function RectEndGhost() {
    let dashedPoints: number[] = [];
    if (!tempNodes) return null;
    let firstNode = tempNodes[0];
    const nodeInPixel = ConvertWorldCoordToCanvas(firstNode, height, zoom);
    dashedPoints = [nodeInPixel.x, nodeInPixel.y, mouse.x, nodeInPixel.y, mouse.x, mouse.y, nodeInPixel.x, mouse.y];

    return (
      <Group>
        <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} closed />
        <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function MoveEndGhost() {
    if (!translation) return null;
    return (
      <Group>
        <Line points={[translation.p1.x, translation.p1.y, mouse.x, mouse.y]} stroke='red' strokeWidth={3} dash={[5]} opacity={0.6} />
        <Circle radius={5} x={translation.p1.x} y={translation.p1.y} fill='red' opacity={0.6} />
        <Circle radius={5} x={mouse.x} y={mouse.y} fill='red' opacity={0.6} />
      </Group>
    );
  }

  function PolylineGhost() {
    if (!tempNodes)
      return (
        <Group>
          <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
        </Group>
      );

    let length = tempNodes.length;
    let solidPoints: number[] = [];
    let dashedPoints: number[] = [];

    if (length > 0) {
      let lastNode = tempNodes[length - 1];

      const lastNodeInPixel = ConvertWorldCoordToCanvas(lastNode, height, zoom);

      dashedPoints = [lastNodeInPixel.x, lastNodeInPixel.y, mouse.x, mouse.y];
    }

    if (length > 1) {
      for (let node of tempNodes) {
        const nodeInPixel = ConvertWorldCoordToCanvas(node, height, zoom);
        solidPoints.push(nodeInPixel.x);
        solidPoints.push(nodeInPixel.y);
      }
    }

    return (
      <Group>
        {length > 0 && <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} />}
        {length > 1 && <Line points={solidPoints} stroke='black' strokeWidth={2} />}
        <Circle radius={8} x={mouse.x} y={mouse.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }
};

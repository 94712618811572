/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './Navbar.scss';
import { Layout, Menu, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined, CloudSyncOutlined, FireOutlined } from '@ant-design/icons';
import { VersionPanel } from '../Version';
import { IUser } from '../../store/state';
import { IProject } from '../../Data/project';
import { API } from '../../store/api';
import { t } from '../../localization';
import { UserMenu } from './UserMenu';
import { ITask } from '../../store/task';
import { HelpDoc } from './HelpDoc';

const { Header } = Layout;

export interface INavbarProps {
  user: IUser | null;
  project: IProject | null;
  task: ITask;
}

export const Navbar: React.FC<INavbarProps> = ({ user, project, task }) => {
  const [isServerRunning, setIsServerRunning] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [lastChecked, setLastChecked] = React.useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLastChecked(new Date());
      try {
        const response = await API.echoRFEMServer();
        setIsServerRunning(response.status === 200);
      } catch (error) {
        setIsServerRunning(false);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Header>
      <div className='logo' />
      <Menu
        triggerSubMenuAction='click'
        selectable={false}
        style={{ lineHeight: '45px', background: 'none', display: 'block' }}
        theme='dark'
        mode='horizontal'
      >
        <Menu.Item key='home'>
          <Link to='/'>
            <Typography.Title level={4} style={{ color: 'white', marginTop: '6px' }}>
              <HomeOutlined />
              <span>TS3</span>
            </Typography.Title>
          </Link>
        </Menu.Item>

        {project && <Menu.Item key='project'>{project.name}</Menu.Item>}

        <UserMenu user={user} task={task} />

        <Menu.Item style={{ float: 'right' }} key='3'>
          <VersionPanel />
        </Menu.Item>

        <HelpDoc />

        <Menu.Item style={{ float: 'right' }} key='server-status'>
          {loading ? (
            <span style={{ color: 'yellow' }}>
              <CloudSyncOutlined /> <span>{t('CHECKING_RFEM_SERVER')}</span>
            </span>
          ) : (
            <Tooltip title={`${t('LAST_CHECKED')}: ${lastChecked.toLocaleTimeString()}`}>
              {isServerRunning ? (
                <span style={{ color: 'green' }}>
                  <CloudSyncOutlined /> <span>{t('RFEM_SERVER_ONLINE')}</span>
                </span>
              ) : (
                <span style={{ color: 'red' }}>
                  <FireOutlined /> <span>{t('RFEM_SERVER_OFFLINE')}</span>
                </span>
              )}
            </Tooltip>
          )}
        </Menu.Item>
      </Menu>
    </Header>
  );
};

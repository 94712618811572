import React, { useState } from 'react';
import { t } from '../../localization';
import { FileTextOutlined, DeleteOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Typography, Tooltip, Modal, Form, Input, Button } from 'antd';
import { IDrawing } from '../../Data/project';
import { UnorderedListOutlined } from '@ant-design/icons';
import { SiderbarProps } from './Sidebar';
import { cloneDeep } from 'lodash';

const { Text } = Typography;

export function DraftList({ project, task, drawing }: SiderbarProps) {
  const [edit, setEdit] = useState(false);

  const [editDrawing, setEditDrawing] = useState<IDrawing>(cloneDeep(drawing)!);

  return (
    <div className='detail-container'>
      <Text className='detail-header'>
        <UnorderedListOutlined />
        <span className='detail-header-text'>{t('DRAFTS')}</span>
      </Text>
      {project.drawings.map((d) => (
        <Text key={d._id} className='detail-body'>
          <div className={d._id === drawing?._id ? 'draft-item selected' : 'draft-item'}>
            <FileTextOutlined className='icon' />
            <span onClick={() => selectDraft(d)}>{d.name}</span>
            <Tooltip title={t('EDIT_DRAFT')}>
              <EditOutlined
                onClick={() => {
                  setEditDrawing(cloneDeep(d));
                  setEdit(true);
                }}
              />
            </Tooltip>
            <Tooltip title={t('COPY_DRAFT')}>
              <CopyOutlined onClick={() => copyDrawing(d)} />
            </Tooltip>
            <Popconfirm title={t('DELETE_DRAFT_CONFIRM')} onConfirm={() => deleteDrawing(d)} okText={t('YES')} cancelText={t('NO')}>
              <Tooltip title={t('DELETE_DRAFT')}>
                <DeleteOutlined className='delete' />
              </Tooltip>
            </Popconfirm>
          </div>
        </Text>
      ))}

      <Text className='detail-body'>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={createDrawing}>{t('CREATE_DRAWING')}</a>
        </div>
      </Text>

      <Modal
        onCancel={() => setEdit(false)}
        visible={edit}
        title={t('EDIT_DRAFT')}
        footer={<Button onClick={() => confirmEditName(editDrawing)}>{t('OK')}</Button>}
      >
        <Form>
          <Form.Item label={t('NAME')}>
            <Input value={editDrawing.name} onChange={(e) => setEditDrawing({ ...editDrawing, name: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );

  async function selectDraft(newDrawing: IDrawing) {
    await task.GetDrawing(newDrawing._id);
  }

  async function createDrawing() {
    await task.CreateDrawing({ name: t('UNTITLED'), projectID: project._id });
  }

  async function copyDrawing(d: IDrawing) {
    await task.CreateDrawing(d);
  }

  async function deleteDrawing(drawing: IDrawing) {
    await task.DeleteDrawing(drawing._id);
  }

  async function confirmEditName(d: IDrawing) {
    await task.UpdateDrawing(d._id, d);
    setEdit(false);
  }
}
